import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {Checkbox} from 'primereact/checkbox'
import React, {useEffect, useState} from 'react'
import {Dropdown} from 'primereact/dropdown'
import CalculatorPopup from './components/CalculatorPopup'
import {DialogComponent} from '../../shared/DialogComponent'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import {
  createOrUpdateTarif,
  fetchEtat,
  fetchMatrices,
  fetchNiveauSrcData,
  fetchParamsNiveau,
  fetchPrestation,
  getDataTarif,
  getEtatList,
  getFormuleCalcul,
  getFormuleCondition,
  getMatrices,
  getNiveauSrcData,
  getParamsNiveau,
  getPrestationList,
  getSelectedEtat,
  getSelectedPrestation,
  getSelectedSrcData,
  getVisibleCalcul,
  setDataTarif,
  setFormuleCalcul,
  setFormuleCondition,
  setSelectedEtat,
  setSelectedPrestation,
  setSelectedSrcData,
  setVisibleCalcul,
  setVisibleNew,
} from '../slice/facturation.slice'
import DialogContent from './DialogContent'
import ButtonComponent from '../../shared/ButtonComponent'
import {
  ColumnsPrestation,
  ColumnsNiveauSrcDataDossier,
  ColumnsNiveauSrcDataClient,
  ColumnsEtat,
} from './columns'
import _ from 'lodash'

const NewTarif = () => {
  const [checkedNiveau, setCheckedNiveau] = useState(false)
  const [checkedFormule, setCheckedFormule] = useState(false)
  const [selectedApplication, setSelectedApplication] = useState(null)
  // const [visibleCalcul , setVisibleCalcul] = useState(false)
  const [visibleSrcData, setVisibleSrcData] = useState(false)
  const [visiblePrestation, setVisiblePrestation] = useState(false)
  const [visibleEtat, setVisibleEtat] = useState(false)
  // const [selectedSrcData, setSelectedSrcData] = useState(null)
  const [prestationChange, setPrestationChange] = useState(null)
  const [etatChange, setEtatChange] = useState(null)
  const [srcDataChange, setSrcDataChange] = useState(null)
  const [typeFormule, setTypeFormule] = useState(null)
  const applications = ['Dossier', 'Client']
  const dispatch = useAppDispatch()
  let selectedPrestation = useAppSelector(getSelectedPrestation)
  let selectedEtat = useAppSelector(getSelectedEtat)
  let selectedSrcData = useAppSelector(getSelectedSrcData)

  let formuleCondition = useAppSelector(getFormuleCondition)
  let formuleCalcul = useAppSelector(getFormuleCalcul)
  let visibleCalcul = useAppSelector(getVisibleCalcul)
  const dataTarif = useAppSelector(getDataTarif)

  useEffect(() => {
    dispatch(fetchParamsNiveau())
    dispatch(fetchPrestation())
    dispatch(fetchEtat())
  }, [])
  let ParamsNiveau = useAppSelector(getParamsNiveau)
  let PrestationList = useAppSelector(getPrestationList)

  let EtatList = useAppSelector(getEtatList)

  const filteredOptions = ParamsNiveau.filter((item) => parseInt(item.id) > 0)
  const dropdownOptions = filteredOptions.map((item) => ({
    value: item.Code,
    label: item.Code,
  }))

  useEffect(() => {
    dispatch(fetchNiveauSrcData(selectedApplication))
  }, [selectedApplication])

  let NiveauSrcData = useAppSelector(getNiveauSrcData)

  const hideCalcul = () => {
    dispatch(setVisibleCalcul(false))
  }

  const handleSelection = (selectedInv) => {
    setSrcDataChange(selectedInv)
  }
  console.log('srcDataChange', srcDataChange)

  const handleSelectionPrestation = (selectedPrestation) => {
    setPrestationChange(selectedPrestation)
  }

  const handleSelectionEtat = (selectedEtat) => {
    setEtatChange(selectedEtat)
  }
  console.log('etatchange', etatChange)

  const savePrestation = () => {
    dispatch(setSelectedPrestation(prestationChange))
    setVisiblePrestation(false)
  }

  const saveEtat = () => {
    dispatch(setSelectedEtat(etatChange))
    setVisibleEtat(false)
  }

  const saveSrcData = () => {
    dispatch(setSelectedSrcData(srcDataChange))
    dispatch(setVisibleSrcData(false))
  }

  const handleClickCalc = (type) => {
    setTypeFormule(type)
    dispatch(setVisibleCalcul(true))
  }

  const onInputChange = (e) => {
    let old = _.cloneDeep(dataTarif)
    console.log('old', old)
    old = {...old, [e.target.name]: e.target.value}
    dispatch(setDataTarif(old))
    // setIsValid(true)
  }

  const handleSaveTarif = () => {
    let data = {
      prestation: selectedPrestation?.Produit,
      etat: selectedEtat?.Etat,
      id_tarif_filter_selection: selectedApplication === 'Dossier' ? 1 : 2,
      valeur_filter_selection: selectedSrcData?.ClientID || selectedSrcData?.OTID,
      formule_condition: formuleCondition,
      formule_calcule: formuleCalcul,
    }
    dispatch(createOrUpdateTarif(data)).then((res) => {
      if (res.payload) {
        dispatch(setVisibleNew(false))
        dispatch(setSelectedPrestation(null))
        dispatch(setSelectedEtat(null))
        dispatch(setSelectedSrcData(null))
        dispatch(setFormuleCondition(null))
        dispatch(setFormuleCalcul(null))
        dispatch(setDataTarif(null))
      }
    })
  }

  return (
    <div className='flex flex-column align-items-center w-full'>
      <div className='infos card  border-300 border-1 w-full p-3'>
        <p className='text-xl font-normal'>Identifiants</p>
        <div className='flex'>
          <div className='code-tarif'>
            <label className='w-7rem'>Code</label>
            <InputText
              name='codeTarif'
              className='w-20rem h-2rem'
              required
              onChange={onInputChange}
            />
          </div>
          <div className='flex description ml-6'>
            <label className='w-7rem'>Description</label>
            <InputTextarea rows={3} cols={37} name='desc' required onChange={onInputChange} />
            {/* <InputText name='description' className='w-20rem h-2rem' /> */}
          </div>
        </div>
        <div className='flex'>
          <div className='code mt-4'>
            <label className='w-7rem '>Prestation</label>
            <InputText
              name='prestation'
              value={selectedPrestation?.Produit}
              className='w-20rem h-3rem'
              disabled={true}
              required
            />
            <DialogComponent
              header='Selected Item'
              visible={visiblePrestation}
              // footer={footer}
              onHide={() => setVisiblePrestation(false)}
              className='md:w-7 md:h-auto right-0'
              position='right-20 '
            >
              <DialogContent
                tableId='table prestation'
                columns={ColumnsPrestation}
                data={PrestationList}
                onSelections={handleSelectionPrestation}
                selectionMode='single'
                onAddButtonClick={savePrestation}
              />
            </DialogComponent>
          </div>
          <div className='mt-4 ml-3 h-3rem flex align-items-center '>
            <ButtonComponent
              icon='pi pi-pencil'
              className='h-2rem w-2rem'
              onClick={() => setVisiblePrestation(true)}
            />
          </div>
          <div className='description ml-6 mt-4'>
            <label className='w-7rem '>Etat</label>
            <InputText
              name='etat'
              value={selectedEtat?.Etat}
              className='w-20rem h-3rem'
              disabled={true}
              required
            />
            <DialogComponent
              header='Selected Item'
              visible={visibleEtat}
              // footer={footer}
              onHide={() => setVisibleEtat(false)}
              className='md:w-7 right-0'
              position='right-20'
            >
              <DialogContent
                tableId='table etat'
                columns={ColumnsEtat}
                data={EtatList}
                onSelections={handleSelectionEtat}
                selectionMode='single'
                onAddButtonClick={saveEtat}
              />
            </DialogComponent>
          </div>
          <div className='mt-4 ml-3 h-3rem flex align-items-center '>
            <ButtonComponent
              icon='pi pi-pencil'
              className='h-2rem w-2rem'
              onClick={() => setVisibleEtat(true)}
            />
          </div>
        </div>
      </div>
      <div className='application card border-300 border-1 w-full p-3 mt-3'>
        <div className='flex'>
          <p className='text-xl mr-2 font-normal'>Niveau d'application</p>
          <Checkbox
            className='mt-1'
            onChange={(e) => setCheckedNiveau(e.checked)}
            checked={checkedNiveau}
          />
        </div>
        <div className='flex'>
          <div className='champs mt-4'>
            <label className='w-7rem'>Champs</label>
            <Dropdown
              options={dropdownOptions}
              placeholder='selectionner'
              value={selectedApplication}
              disabled={!checkedNiveau}
              className='h-3rem w-13rem'
              onChange={(e) => setSelectedApplication(e.value)}
            />
          </div>
          <div className='valeur ml-6 mt-4'>
            <label className='w-7rem'>Valeur</label>
            <InputText
              name='valeur'
              className='w-13rem h-3rem'
              value={selectedSrcData?.ClientID || selectedSrcData?.OTID}
              disabled={!selectedApplication || !checkedNiveau}
            />
            {/* table des valeur Niveau Application */}

            <DialogComponent
              header='Selected Item'
              visible={visibleSrcData}
              // footer={footer}
              onHide={() => setVisibleSrcData(false)}
              className='md:w-7 right-0'
              position='right-20'
            >
              <DialogContent
                tableId='table SrcData'
                columns={
                  selectedApplication === 'Dossier'
                    ? ColumnsNiveauSrcDataDossier
                    : ColumnsNiveauSrcDataClient
                }
                data={NiveauSrcData}
                onSelections={handleSelection}
                selectionMode='single'
                onAddButtonClick={saveSrcData}
              />
            </DialogComponent>
          </div>
          <div className='champs mt-4 ml-3 flex align-items-center h-3rem '>
            <ButtonComponent
              icon='pi pi-pencil'
              className='h-2rem w-2rem'
              onClick={() => setVisibleSrcData(true)}
              disabled={!selectedApplication || !checkedNiveau}
            />
          </div>
        </div>
      </div>
      <div className='application card border-300 border-1 w-full p-3 mt-3'>
        <div className='flex flex-column w-full'>
          <div className='flex justify-content-between'>
            <div className='flex'>
              <p className='text-xl font-normal mr-2'>Formule Condition</p>
              <Checkbox
                className='mt-1'
                onChange={(e) => setCheckedFormule(e.checked)}
                checked={checkedFormule}
              />
            </div>
            <i
              className='pi pi-calculator cursor-pointer'
              disabled={!checkedFormule}
              onClick={() => handleClickCalc('condition')}
            ></i>
          </div>
          <div className='w-full'>
            <InputText
              className='h-full w-9 text-xl font-normal border-gray-300 border-1 p-1'
              value={formuleCondition}
              disabled={!checkedFormule}
            />
            {/* <i className="pi pi-calculator cursor-pointer" onClick={()=>setVisibleCalcul(true)}></i> */}
          </div>
          <DialogComponent
            header='Calculatrice'
            visible={visibleCalcul}
            onHide={hideCalcul}
            className='md:w-5 right-0'
          >
            <CalculatorPopup type={typeFormule} />
          </DialogComponent>
        </div>
      </div>
      <div className='application card border-300 border-1 w-full p-3 mt-3'>
        <div className='flex flex-column'>
          <div className='flex justify-content-between'>
            <p className='text-xl font-normal mr-2'>Formule Calcule</p>
            <i
              className='pi pi-calculator cursor-pointer'
              onClick={() => handleClickCalc('calcule')}
            ></i>
          </div>
          <div>
            <InputText
              className='h-full w-9 text-xl font-normal border-gray-300 border-1 p-1'
              value={formuleCalcul}
              required
            />
          </div>
          {/* <DialogComponent
                    header= "Calculatrice"
                    visible={visibleCalcul}
                    onHide={hideCalcul} 
                    className='md:w-5 right-0'
                    
            >
                    {<CalculatorPopup type='' />}
                </DialogComponent> */}
        </div>
      </div>
      <div className='application card border-300 border-1 w-full p-3 mt-3'>
        <div className='flex justify-content-end'>
          <ButtonComponent icon='pi pi-save' onClick={handleSaveTarif} />
        </div>
      </div>
    </div>
  )
}

export default NewTarif
