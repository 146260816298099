import axios from 'axios'
console.log('process.env.REACT_APP_API_URL:', process.env.REACT_APP_API_URL)
export default axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
})

export const psCore = axios.create({
  baseURL: process.env.REACT_APP_PSCORE_API,
})
