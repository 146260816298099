/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'

import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { checkUser, getCurrentUser, login } from '../../slice/user.slice'
import SplashScreen from '../../../SplashScreen/SplashScreen'

import { toAbsoluteUrl } from '../../../../_metronic/helpers'

import bgImage from '../../assets/images/network-bg.jpg'
import { getIsMenuReady } from '../../../Layout/slice/layout.slice'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    // .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function LoginComponent() {
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUser)
  const [showSpash , setShowSpash] = useState(true)
  const isMenuReady = useAppSelector(getIsMenuReady)
  
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const obj = {user: values.email , password: values.password}
        dispatch(login(obj));
        setLoading(false)
      } catch (error) {
        console.error(error)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(()=> {
      if(currentUser || !localStorage.getItem('token')){
        setShowSpash(false)
        return
      }

      if(!isMenuReady) return
      setTimeout(()=> {
        dispatch(checkUser()).then(({payload})=> {
          console.log('user:', payload)
          setShowSpash(false)
        }).catch(err => {
          console.log('error:', err)
          setShowSpash(false)
        })
      },500)
  }, [currentUser , isMenuReady])

  if(showSpash || !isMenuReady) return <SplashScreen />
  return (
    <div
      className='md:flex  flex-column-fluid flex-wrap bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illstrations/sketchy-1/14.png')})`,
      }}
    >
      <div className='w-12 md:w-5 bg-dark h-30rem md:h-full bgi-no-repeat bgi-size-cover'
        style={{
          backgroundImage: `url(${bgImage})`,
        }}>

      </div>
      {/* begin::Content */}
      <div className='d-flex  flex-center flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Wrapper */}
        
        <div className='w-11 md:w-7 lg:w-6 bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'>
           <form
                className='form w-100'
                onSubmit={formik.handleSubmit}
                noValidate
                id='kt_login_signin_form'
              >
                {/* begin::Form group */}
                <div className='fv-row mb-5'>
                  <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
                  <input
                    placeholder='Email'
                    {...formik.getFieldProps('email')}
                    className={clsx(
                      'form-control bg-transparent',
                      {'is-invalid': formik.touched.email && formik.errors.email},
                      {
                        'is-valid': formik.touched.email && !formik.errors.email,
                      }
                    )}
                    type='email'
                    name='email'
                    autoComplete='off'
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert'>{formik.errors.email}</span>
                    </div>
                  )}
                </div>
                {/* end::Form group */}

                {/* begin::Form group */}
                <div className='fv-row mb-3'>
                  <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
                  <input
                    type='password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control bg-transparent',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* end::Form group */}

              

                {/* begin::Action */}
                <div className='d-grid mb-10 mt-6'>
                  <button
                    type='submit'
                    id='kt_sign_in_submit'
                    className='btn btn-primary'
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && <span className='indicator-label'>Continue</span>}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
                {/* end::Action */}

              
           </form>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
    </div>
    
  )
}
