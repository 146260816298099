import {Tag} from 'primereact/tag'

const CardHistoStatus = ({status, date, statusIcon, stat, user, address, statusColor}) => {
  return (
    <div className='p-4 border-round-md bg-white w-full border-1 border-gray-300'>
      <div className='flex flex-row align-items-center gap-4'>
        <div className='flex flex-row gap-2 align-items-center'>
          <i class={`fa-solid fa-square ${'text-' + statusColor} text-lg`}></i>
          <div className='text-lg text-800 font-semibold'>{status}</div>
          <i class={`fas ${statusIcon} ${'text-' + statusColor} text-lg`}></i>
        </div>
        <Tag className='mr-2' icon='pi pi-tag' value='BC572902238F'></Tag>
      </div>
      <div className='flex flex-row gap-4 align-items-center mt-3'>
        <div className='flex flex-row gap-2 align-items-center'>
          <i class='fa-duotone fa-solid fa-user text-xl'></i>
          <div className='text-lg text-800 font-semibold'>{user}</div>
        </div>
        <div className='flex flex-row gap-2 align-items-center'>
          <i class='fa-duotone fa-solid fa-calendar-week text-xl text-yellow-500'></i>
          <div className='text-lg text-800 font-semibold'>{date}</div>
        </div>
        <div className='flex flex-row gap-2 align-items-center'>
          <i class='fa-duotone fa-solid fa-truck text-xl'></i>
          <div className='text-lg text-800 font-semibold'>{stat}</div>
        </div>
        <div className='flex flex-row gap-2 align-items-center'>
          <i class='fa-duotone fa-solid fa-location-dot text-xl text-blue-500'></i>
          <div className='text-lg text-800 font-semibold '>{address}</div>
        </div>
      </div>
    </div>
  )
}

export default CardHistoStatus
