import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'
import React, {useEffect, useState} from 'react'
import ButtonComponent from '../../shared/ButtonComponent'
import {DatatableComponent} from '../../shared/DatatableComponent/DataTableComponent'
import {DialogComponent} from '../../shared/DialogComponent'
import DialogContent from './DialogContent'
import {columnsTarif, columnsMatrice} from './columns'
import NewTarif from './NewTarif'
import NewMatrice from './NewMatrice'
import _ from 'lodash'

import {
  getMatrices,
  fetchMatrices,
  getVisibleNew,
  setVisibleNew,
  setMatriceDetail,
  getMatriceDetail,
  setIsNewDetail,
  setSelectedMatrix,
  getIsNewDetail,
  setSelectedPrestation,
  setSelectedEtat,
  setSelectedSrcData,
  setFormuleCondition,
  setFormuleCalcul,
  fetchTarifs,
  getTarifs,
  getEditMatrice,
  setEditMatrice,
  removeMatrice,
} from '../slice/facturation.slice'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import MatrixDetail from './MatrixDetail'
import ElementFacturable from './ElementFacturable'
import EditMatrice from './Edit/EditMatrice'

const Facturation = () => {
  const typeTarif = ['List des tarifs', 'List des matrices']
  const [selectedType, setSelectedType] = useState(null)
  const [client, setClient] = useState(null)
  const [visible, setIsVisible] = useState(false)

  // const [visibleTest , setVisible] = useState(false)
  const dispatch = useAppDispatch()
  const isNewDetail = useAppSelector(getIsNewDetail)
  let visibleNew = useAppSelector(getVisibleNew)
  let visibleEdit = useAppSelector(getEditMatrice)
  let matriceDetail = useAppSelector(getMatriceDetail)

  let actionsMatrice = [
    {
      label: 'Detail',
      icon: 'pi pi-eye text-blue-500',
      command: (e) => {
        dispatch(setIsNewDetail(false))
        dispatch(setSelectedMatrix(e.item.data))
        dispatch(setMatriceDetail(true))
      },
    },
    {
      label: 'Edit',
      icon: 'pi pi-cog text-blue-500',
      command: (e) => {
        dispatch(setVisibleNew(true))
        dispatch(setSelectedMatrix(e.item.data))
      },
    },
    {
      label: 'Delete',
      icon: 'pi pi-trash text-red-500',
      command: (e) => {
        dispatch(removeMatrice(e.item.data))
      },
    },
  ]
  console.log('isNewDetail in detail', isNewDetail)

  useEffect(() => {
    dispatch(fetchMatrices())
    dispatch(fetchTarifs())
  }, [])

  let matrices = useAppSelector(getMatrices)
  let tarifs = useAppSelector(getTarifs)
  console.log('matrices', matrices)

  const handleChange = (e) => {
    setSelectedType(e.value)
    setIsVisible(true)
  }

  const onHide = () => {
    setIsVisible(false)
    setSelectedType(null)
  }

  const onHideAdd = () => {
    // setVisibleNew(false)
    dispatch(setVisibleNew(false))
    dispatch(setSelectedMatrix(null))
    if (selectedType === 'List des tarifs') {
      dispatch(setSelectedPrestation(null))
      dispatch(setSelectedEtat(null))
      dispatch(setSelectedSrcData(null))
      dispatch(setFormuleCondition(null))
      dispatch(setFormuleCalcul(null))
    }
  }

  const onAddButtonClick = () => {
    // setVisibleNew(true)
    dispatch(setVisibleNew(true))
    dispatch(setIsNewDetail(true))
    dispatch(setSelectedMatrix(null))
  }
  const footer = (
    <div className='flex justify-content-start'>
      <ButtonComponent label='Annuler' className='p-button-danger' onClick={onHide} />
    </div>
  )

  const columns = [
    {
      header: 'N Dossier',
      olang: 'N Dossier',
      field: 'nDossier',
    },
    {
      header: 'Date de Livraison',
      field: 'dateLivraison',
      olang: 'Date.de.Livraison',
    },
    {
      header: 'Date de Cloture',
      field: 'dateCloture',
      olang: 'Date.de.Cloture',
    },
    {
      header: 'Client',
      field: 'client',
      olang: 'Client',
    },
    {
      header: 'NP',
      field: 'np',
      olang: 'NP',
    },
    {
      header: 'Ville',
      field: 'ville',
      olang: 'Ville',
    },
    {
      header: 'Code Magasin',
      field: 'codeMagasin',
      olang: 'Code.Magasin',
    },
    {
      header: 'Prix',
      field: 'prix',
      olang: 'Prix',
    },
    {
      header: 'Etat',
      field: 'etat',
      olang: 'Etat',
    },
    {
      header: 'Prestation',
      field: 'prestation',
      olang: 'Prestation',
    },
    {
      header: 'Ref Client',
      field: 'refClient',
      olang: 'Ref.Client',
    },
    {
      header: 'Volume',
      field: 'volume',
      olang: 'Volume',
    },
    {
      header: 'Adresse',
      field: 'adress',
      olang: 'Adresse',
    },
    {
      header: 'Agence',
      field: 'agence',
      olang: 'Agence',
    },
  ]

  const data = [
    {
      nDossier: 120,
      dateLivraison: '22/04/2020',
      dateCloture: '22/04/2021',
      client: 'younes',
      np: '__',
      ville: 'Casablanca',
      codeMagasin: 22212,
      prix: 110,
      etat: 'bien',
      prestation: '__',
      refClient: 111222,
      volume: 160,
      adresse: '212 mediona casablanca',
      agence: '__',
    },
  ]

  console.log('vvisible', visibleNew)

  return (
    <div>
      <EditMatrice visible={visibleEdit} />
      <div className='flex'>
        <p className=' text-4xl'>Facturation</p>
        <Dropdown
          placeholder='tarif'
          value={selectedType}
          onChange={handleChange}
          options={typeTarif}
          className=' ml-8 w-full h-3rem md:w-13rem'
        />
      </div>
      {/* <div className='card bg-gray-100 mt-5'>
            <p className='text-xl pt-3 pl-3'>Client</p>
            <div className='flex pl-3 pb-3'>
                <InputText className=' h-3rem w-3' value={client} onChange={(e) => setClient(e.target.value)} />
                <ButtonComponent label='Rechercher' className='ml-3 h-3rem' />
                <ButtonComponent icon='pi pi-search' className='ml-3 h-3rem' />
                
            </div>
        </div> */}
      <div className='mt-4 w-full'>
        <div className='element-facturable'>
          <ElementFacturable />
        </div>
        <DialogComponent
          header='List des tarifs'
          visible={visible}
          footer={footer}
          onHide={onHide}
          className='md:w-9 right-0'
          position='right'
        >
          <DialogContent
            tableId='Ttest'
            columns={selectedType === 'List des tarifs' ? columnsTarif : columnsMatrice}
            data={selectedType === 'List des matrices' ? matrices : tarifs}
            onAddButtonClick={onAddButtonClick}
            rowActions={selectedType === 'List des matrices' ? actionsMatrice : ''}
          />
        </DialogComponent>

        {/* Add New Tarif/Matrice */}
        <DialogComponent
          header={selectedType === 'List des tarifs' ? 'Nouveau tarif' : 'Nouvelle matrice'}
          visible={visibleNew}
          onHide={onHideAdd}
          className='md:w-9 right-0 h-auto'
          position='right'
        >
          {selectedType === 'List des tarifs' ? <NewTarif /> : <NewMatrice />}
        </DialogComponent>
      </div>
      <MatrixDetail />
    </div>
  )
}

export default Facturation
