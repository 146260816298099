import React, {useEffect} from 'react'
import {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {getSelectedGateway, setGatewayView, setSelectedGateway} from '../../slice/gateway.slice'
import {Divider} from 'primereact/divider'
import {TabPanel, TabView} from 'primereact/tabview'
import GatewayEditor from './GatewayEditor/GatewayEditor'
import {useNavigate} from 'react-router-dom'
import ButtonComponent from '../../../shared/ButtonComponent'
import GatewayGeofence from '../GatewayGeofence/GatewayGeofence'
import {OlangItem} from '../../../shared/Olang/user-interface/OlangItem/OlangItem'

const GatewayDetailComponent = (props) => {
  const [inputs, setInputs] = useState({})
  const selectedGateway = useSelector(getSelectedGateway)
  const dispatch = useDispatch()
  const onHide = () => {
    typeof props.onHide == 'function' && props.onHide()
    dispatch(setSelectedGateway(null))
  }

  useEffect(() => {
    setInputs(selectedGateway || {})
  }, [selectedGateway])

  console.log('inputs GateWay', inputs)

  return (
    <div className='bg-white p-2'>
      <div className='my-2 flex align-items-between'>
        <div className='flex gap-3 align-items-center'>
          <ButtonComponent onClick={onHide}>
            <i class='fa-solid fa-share fa-flip-horizontal text-white'></i>
            <div className='ml-2'>
              <OlangItem olang='btn.back' />
            </div>
          </ButtonComponent>
          {!inputs?.label ? (
            <strong>
              <OlangItem olang='new.Gat' />
            </strong>
          ) : (
            <strong className='text-lg'>{inputs.label}</strong>
          )}
        </div>
      </div>
      <Divider type='dashed' />
      <TabView>
        <TabPanel header='Général'>
          <GatewayEditor root={props.root} />
        </TabPanel>
        {/* <TabPanel header='Site'>
          <GatewayGeofence root={props.root} />
        </TabPanel> */}
      </TabView>
    </div>
  )
}

export default GatewayDetailComponent
