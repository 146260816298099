import {Card} from 'primereact/card'
import {useAppSelector} from '../../../../hooks'
import {DialogComponent} from '../../../shared/DialogComponent/DialogComponent'
import {OlangItem} from '../../../shared/Olang/user-interface/OlangItem/OlangItem'
import {InputText} from 'primereact/inputtext'
import ButtonComponent from '../../../shared/ButtonComponent'
import {InputSwitch} from 'primereact/inputswitch'
import _ from 'lodash'
import {
  createOrUpdateUser,
  getselectedUser,
  getSelectedUser,
  setEditUser,
  setSelectedUser,
} from '../../slice/team.slice'
import {useDispatch} from 'react-redux'
import {useEffect, useState} from 'react'
import {fetchValidator, getValidator} from '../../../Inventory/slice/inventory.slice'
import {useSelector} from 'react-redux'
import {Message} from 'primereact/message'
import { setAlertParams } from '../../../../store/slices/alert.slice'

const UserEdit = ({dialogVisible, setDialogVisible}) => {
  const selectedUser = useAppSelector(getSelectedUser)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const validators = useSelector(getValidator)
  const [passwordMessage, setPasswordMessage] = useState('')
  const [passwordConfirmationMessage, setPasswordConfirmationMessage] = useState('')
  const [isTyping, setIsTyping] = useState(false)
  const [disableMessage, setDisableMessage] = useState('')

  const verifyPassword = () => {
    if (selectedUser.pass !== selectedUser.pwd2) {
      reset()
      setPasswordConfirmationMessage('Passwords do not match')
      return false
    }
    if (selectedUser?.pass && selectedUser?.pwd2) {
      const passValidator = validators?.find((field) => field.id === 'pass')
      const pwd2Validator = validators?.find((field) => field.id === 'pwd2')
      console.log('xxpassValidator', passValidator, 'pwd2Validator', pwd2Validator)
      if (selectedUser?.pass && selectedUser?.pwd2) {
        console.log(
          'sssselectedUser?.pass',
          selectedUser?.pass,
          'selectedUser?.pwd2',
          selectedUser?.pwd2
        )
        const repassValidator = new RegExp(passValidator.regExp.slice(1, -1))
        const repwd2Validator = new RegExp(pwd2Validator.regExp.slice(1, -1))
        const isPassValid = repassValidator.test(selectedUser?.pass)
        const isPwd2Valid = repwd2Validator.test(selectedUser?.pwd2)
        console.log('pppisPassValid', isPassValid, 'isPwd2Valid', isPwd2Valid)
        setPasswordMessage(isPassValid ? '' : passValidator.messageError)
        setPasswordConfirmationMessage(isPwd2Valid ? '' : pwd2Validator.messageError)

        return isPassValid && isPwd2Valid
      } else {
        setPasswordMessage('')
        setPasswordConfirmationMessage('')
        return false
      }
    }
  }

  const reset = () => {
    setIsTyping(false)
    setDisableMessage('')
    setPasswordMessage('')
    setPasswordConfirmationMessage('')
  }

  console.log('Xxvalidators', validators)

  const dialogTemplate = () => {
    return (
      <div className='flex'>
        <div>
          <p className='text-2xl'>{<OlangItem olang='user.edit' />}</p>
        </div>
      </div>
    )
  }

  const save = () => {
    console.log('oooooooselectedUser', selectedUser?.pass, selectedUser?.pwd2)

    try{
      setLoading(true)
      if (
        (selectedUser?.pass == '' && selectedUser?.pwd2 != '') ||
        (selectedUser?.pass != '' && selectedUser?.pwd2 == '') || !selectedUser?.pass
      ) {
        console.log('eeeeeee' , selectedUser?.pass)
        if (selectedUser?.pass == '') {
          reset()
          setPasswordMessage('Please enter password')
        }
        else if(selectedUser?.pwd2 == '') {
          reset()
          setPasswordConfirmationMessage('Please enter confirmation password')
        }
        setLoading(false)
        return
      }
      let isPasswordValid = true
      if (isTyping) {
        isPasswordValid = verifyPassword()
        console.log('sssisPasswordValid', isPasswordValid)
      }
      
      if (isPasswordValid) {
        dispatch(createOrUpdateUser()).then((res) => {
          setLoading(false)
          if (res.payload) {
            reset()
            dispatch(setEditUser(false))
            dispatch(setSelectedUser(null))
            setDialogVisible(false)
          }
        })
      }else{
        setLoading(false)
      }
    }catch(e){
      dispatch(setAlertParams({
        title: 'Erreur',
        message: `L'erreur suivante est survenu:${e.message}. Veuillez revoir vos valeurs réessayer`,
        visible: true,
        type: 'message'
      }))

      setLoading(false)
      
    }
  }

  const onHide = () => {
    reset()
    dispatch(setEditUser(false))
    dispatch(setSelectedUser(null))
    setDialogVisible(false)
  }

  const footer = (
    <div>
      <ButtonComponent label='Annuler' className='p-button-danger' onClick={onHide} />
      <ButtonComponent loading={loading} label='Enregistrer' onClick={save} />
    </div>
  )

  const onInputChange = (e) => {
    let old = _.cloneDeep(selectedUser)

    old = {
      ...old,
      id: selectedUser?.userID,
      rest: old.rest === undefined ? false : old.rest,
      active: old.active === undefined ? false : old.active,
      [e.target.name]: e.target.value,
    }
    let isTypingOrNotEmpty = ''
    if (e.target.name == 'pass' || e.target.name == 'pwd2') {
      isTypingOrNotEmpty = e.target.value.trim() != ''
      old.rest = false
      setIsTyping(isTypingOrNotEmpty)
    }
    console.log('eeelllllold user', old)
    if (isTypingOrNotEmpty) {
      setDisableMessage('Please clear the password field to enable the password reset by email')
    } else {
      setDisableMessage('')
    }
    dispatch(setSelectedUser(old))
    const areAllRequiredFieldsFilled = validators
      .filter((validator) => validator.isRequired)
      .every((validator) => !!old[validator.id])
    console.log('XXareAllRequiredFieldsFilled', areAllRequiredFieldsFilled)
    // setIsValid(!areAllRequiredFieldsFilled)
  }

  useEffect(() => {
    dispatch(fetchValidator('user'))
  }, [])

  const _loginValidator = validators?.find((field) => field.id === 'login')
  const _addrMailValidator = validators?.find((field) => field.id === 'addrMail')
  const _passwordValidator = validators?.find((field) => field.id === 'password')
  const _modelValidator = validators?.find((field) => field.id === 'model')
  const _immatriculationValidator = validators?.find((field) => field.id === 'immatriculation')
  const _vinValidator = validators?.find((field) => field.id === 'vin')

  return (
    <DialogComponent
      header={dialogTemplate}
      footer={footer}
      visible={dialogVisible}
      onHide={onHide}
      position='center'
    >
      <div className='my-4 mt-5'>
        <label>
          <OlangItem olang='user.username' />
          {_loginValidator?.isRequired == 1 && <span className='h3 text-danger'>*</span>}
        </label>
        <InputText
          name='login'
          id='login'
          value={selectedUser?.login}
          onChange={onInputChange}
          className='w-full font-semibold text-lg'
        />
      </div>
      <div className='my-4 mt-5'>
        <label>
          <OlangItem olang='user.email' />
          {_addrMailValidator?.isRequired == 1 && <span className='h3 text-danger'>*</span>}
        </label>
        <InputText
          name='addrMail'
          id='addrMail'
          value={selectedUser?.addrMail}
          onChange={onInputChange}
          className='w-full font-semibold text-lg'
        />
      </div>
      <div className='my-4 mt-5'>
        <label>
          <OlangItem olang='user.password' />
        </label>
        <InputText
          type='password'
          name='pass'
          id='pass'
          feedback={false}
          onChange={onInputChange}
          className='w-full font-semibold text-lg'
        />
        <small className='text-danger'>{passwordMessage}</small>
      </div>
      <div className='my-4 mt-5'>
        <label>
          <OlangItem olang='user.confirmation' />
        </label>
        <InputText
          type='password'
          name='pwd2'
          id='pwd2'
          feedback={false}
          onChange={onInputChange}
          className='w-full font-semibold text-lg'
        />
        <small className='text-danger'>{passwordConfirmationMessage}</small>
      </div>

      <div className='my-5 flex align-items-center gap-2'>
        <label>
          <OlangItem olang='user.reset' />
        </label>
        <InputSwitch
          id='rest'
          name='rest'
          checked={selectedUser?.rest == 1 ? true : false}
          disabled={isTyping}
          onChange={onInputChange}
        />
        {disableMessage && <Message severity='info' text={disableMessage} />}
      </div>

      <div className='my-5 flex align-items-center gap-2'>
        <label>
          <OlangItem olang='user.active' />
        </label>
        <InputSwitch
          name='active'
          checked={selectedUser?.active == 1 ? true : false}
          onChange={onInputChange}
        />
      </div>
    </DialogComponent>
  )
}

export default UserEdit
