import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {FACTURATION as name} from '../../../store/slices/config'
import {
  _fetchEtatList,
  _fetchMatriceItemsList,
  _fetchMatriceList,
  _fetchNiveauSrcDataList,
  _fetchParametersList,
  _fetchParamsList,
  _fetchPrestationList,
  _fetchTarifList,
  _removeMatrice,
  _saveMatrix,
  _saveMatrixDetails,
  _savePrixMatrix,
  _saveTarif,
} from '../api'
import _ from 'lodash'
import moment from 'moment'

export const fetchMatrices = createAsyncThunk(
  `${name}/fetchMatrices`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchMatriceList()
    console.log('matrices list :  ', response)
    if (response.status === 200) dispatch(setMatrices(response.data))
  }
)

export const fetchMatriceItems = createAsyncThunk(
  `${name}/fetchMatriceItems`,
  async (id, {getState, dispatch}) => {
    let response = await _fetchMatriceItemsList(id)
    console.log('id items  ', id)
    console.log('respons')
    if (response.status === 200) dispatch(setMatriceItems(response.data))
    else dispatch(setMatriceItems([]))
  }
)

export const fetchTarifs = createAsyncThunk(
  `${name}/fetchTarifs`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchTarifList()
    console.log('Tarifs list :  ', response)
    if (response.status === 200) dispatch(setTarifs(response.data))
  }
)

export const fetchParamsNiveau = createAsyncThunk(
  `${name}/fetchParamsNiveau`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchParamsList()
    console.log('Params Niveau', response)
    if (response.status === 200) dispatch(setParamsNiveau(response.data))
  }
)

export const fetchParameters = createAsyncThunk(
  `${name}/fetchParameters`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchParametersList()
    console.log('response Parameters', response)
    if (response.status === 200) dispatch(setParametersList(response.data))
  }
)

export const fetchNiveauSrcData = createAsyncThunk(
  `${name}/fetchNiveauSrcData`,
  async (IdParam, {getState, dispatch}) => {
    console.log('IdParam', IdParam)
    let response = await _fetchNiveauSrcDataList(IdParam)
    console.log('response NiveauSrcData', response)
    if (response.status === 200) dispatch(setNiveauSrcData(response.data))
  }
)

export const fetchPrestation = createAsyncThunk(
  `${name}/fetchPrestation`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchPrestationList()
    console.log('resp Prestation List', response)
    if (response.status === 200) dispatch(setPrestationList(response.data))
  }
)

export const fetchEtat = createAsyncThunk(
  `${name}/fetchPrestation`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchEtatList()
    console.log('resp Etat List', response)
    if (response.status === 200) dispatch(setEtatList(response.data))
  }
)

export const createOrUpdateMatrix = createAsyncThunk(
  `${name}/createOrUpdateMatrix`,
  async (data, {dispatch, getState}) => {
    try {
      console.log('innn')
      console.log('data ma', data)
      let {selectedMatrix} = getState()[name]
      console.log('data matrix', selectedMatrix)
      console.log('data matri', getState()[name])
      let matrix = _.cloneDeep(selectedMatrix)
      let obj = {
        id: selectedMatrix?.id || 0,
        code: selectedMatrix?.Code || matrix?.code,
        desc: selectedMatrix?.Description || matrix?.desc,
        xp: data?.selectedDimensV || selectedMatrix?.XFieldName,
        xu:
          data?.selectedDimensV === 'Volume prestation'
            ? 'm3'
            : data?.selectedDimensV === 'Poids prestation'
            ? 'kg'
            : data?.selectedDimensV === 'Prix du prestation'
            ? 'CHF'
            : 'zone',
      }

      if (data?.checkedDimension) {
        obj.yp = data?.selectedDimensH
        obj.yu =
          data?.selectedDimensH === 'Volume prestation'
            ? 'm3'
            : data?.selectedDimensH === 'Poids prestation'
            ? 'kg'
            : data?.selectedDimensH === 'Prix du prestation'
            ? 'CHF'
            : data?.selectedDimensH === 'Zone selon NP livraison'
            ? 'zone'
            : null
      }

      // console.log('data PendingBilling : ', data)
      console.log('object save matrix : ', obj)
      let res = null

      res = await _saveMatrix(obj)

      console.log('matrix res:', res)
      if ((Array.isArray(res.data) && (res.data || [])[0]?.msg === 'added') || 'updated') {
        console.log('ok')
        dispatch(fetchMatrices())
        return true
      } else if (Array.isArray(res.data) && (res.data || [])[0]?.msg === 'Existe déjà ') {
        //dispatch(setExistItem(true))
      }

      return false
    } catch (e) {
      console.log('error:', e.message)
      //   return { error: true, message: e.message }
      return false
    }
  }
)

export const createMatrixDimension = createAsyncThunk(
  `${name}/createMatrixDimension`,
  async (data, {dispatch, getState}) => {
    try {
      console.log('data detail matrix', data)
      let {selectedMatrix} = getState()[name]
      console.log('selected mmatrix', selectedMatrix)
      let obj = {
        id: 1,
        dim: '',
        max: '',
      }

      console.log('object save detail matrix : ', obj)
      let res = null

      res = await _saveMatrixDetails(data)

      console.log('matrix details res:', res)
      if ((Array.isArray(res.data) && (res.data || [])[0]?.msg === 'added') || 'updated') {
        console.log('ok')
        return true
      } else if (Array.isArray(res.data) && (res.data || [])[0]?.msg === 'Existe déjà ') {
        //dispatch(setExistItem(true))
      }

      return false
    } catch (e) {
      console.log('error:', e.message)
      //   return { error: true, message: e.message }
      return false
    }
  }
)

export const UpdatePrixMatrice = createAsyncThunk(
  `${name}/UpdatePrixMatrice`,
  async (args, {dispatch, getState}) => {
    try {
      let {selectedDetailMatrix} = getState()[name]
      console.log('selected mmatrix', selectedDetailMatrix)

      let res = null
      res = await _savePrixMatrix(selectedDetailMatrix)

      console.log('res prix updated', res.success)

      if (res.status === 200) {
        return true
      } else if (!res.success) {
        return false
      }
    } catch (e) {
      console.log('error:', e.message)
      //   return { error: true, message: e.message }
      return false
    }
  }
)

export const createOrUpdateTarif = createAsyncThunk(
  `${name}/createOrUpdateTarif`,
  async (data, {dispatch, getState}) => {
    try {
      let {dataTarif} = getState()[name]
      console.log('data Tarif', dataTarif)
      console.log('ddTarif', data)
      let obj = {
        code: dataTarif.codeTarif,
        desc: dataTarif.desc,
        prestation: data?.prestation,
        etat: data?.etat,
        id_tarif_filter_selection: data?.id_tarif_filter_selection,
        valeur_filter_selection: data?.valeur_filter_selection,
        formule_condition: data?.formule_condition,
        formule_calcule: data?.formule_calcule,
      }

      let res = null
      res = await _saveTarif(obj)

      console.log('res createTarif', res)

      if (res.status === 200) {
        return true
      } else if (!res.success) {
        return false
      }
    } catch (e) {
      console.log('error:', e.message)
      //   return { error: true, message: e.message }
      return false
    }
  }
)

export const removeMatrice = createAsyncThunk(`${name}/removeMatrice`, async (_arg, {dispatch}) => {
  console.log('args matrice', _arg)
  let res = await _removeMatrice(_arg?.id)

  console.log('res:', res)

  dispatch(fetchMatrices())
})

//Actions
export const setMatrices = createAction(`${name}/fetchMatrices`)
export const setTarifs = createAction(`${name}/fetchTarifs`)
export const setParamsNiveau = createAction(`${name}/fetchParamsNiveau`)
export const setParametersList = createAction(`${name}/fetchParameters`)
export const setPrestationList = createAction(`${name}/fetchPrestation`)
export const setEtatList = createAction(`${name}/fetchEtat`)
export const setNiveauSrcData = createAction(`${name}/fetchNiveauSrcData`)
export const setVisibleNew = createAction(`${name}/setVisibleNew`)
export const setSelectedMatrix = createAction(`${name}/selectedMatrix`)
export const setSelectedPrestation = createAction(`${name}/selectedPrestation`)
export const setSelectedEtat = createAction(`${name}/selectedEtat`)
export const setSelectedSrcData = createAction(`${name}/selectedSrcData`)
export const setMatriceDetail = createAction(`${name}/setMatriceDetail`)
export const setMatriceItems = createAction(`${name}/fetchMatriceItems`)
export const setIsNewDetail = createAction(`${name}/setIsNewDetail`)
export const setSelectedDetailMatrix = createAction(`${name}/setSelectedDetailMatrix`)
export const setSelectedParam = createAction(`${name}/setSelectedParam`)
export const setSelectedTarif = createAction(`${name}/setSelectedTarif`)
export const setFormuleCondition = createAction(`${name}/setFormuleCondition`)
export const setFormuleCalcul = createAction(`${name}/setFormuleCalcul`)
export const setVisibleCalcul = createAction(`${name}/setVisibleCalcul`)
export const setDataTarif = createAction(`${name}/setDataTarif`)
export const setEditMatrice = createAction(`${name}/setEditMatrice`)

const FacturationSlice = createSlice({
  name,
  initialState: {
    matrices: [],
    editMatrice: false,
    matriceDetail: false,
    matriceItems: [],
    isNewDetail: true,
    tarifs: [],
    paramsNiveau: [],
    niveauSrcData: [],
    selectedSrcData: [],
    visibleNew: false,
    selectedMatrix: null,
    prestationList: [],
    selectedPrestation: null,
    etatList: [],
    selectedEtat: [],
    parametersList: [],
    selectedDetailMatrix: [],
    selectedParam: null,
    selectedTarif: [],
    formuleCondition: null,
    formuleCalcul: null,
    visibleCalcul: false,
    dataTarif: [],
  },
  reducers: {},
  extraReducers: {
    [setMatrices]: (state, {payload}) => {
      state.matrices = payload
    },
    [setTarifs]: (state, {payload}) => {
      state.tarifs = payload
    },
    [setVisibleNew]: (state, {payload}) => {
      state.visibleNew = payload
    },
    [setSelectedMatrix]: (state, {payload}) => {
      state.selectedMatrix = payload
    },
    [setParamsNiveau]: (state, {payload}) => {
      state.paramsNiveau = payload
    },
    [setNiveauSrcData]: (state, {payload}) => {
      state.niveauSrcData = payload
    },
    [setPrestationList]: (state, {payload}) => {
      state.prestationList = payload
    },
    [setEtatList]: (state, {payload}) => {
      state.etatList = payload
    },
    [setParametersList]: (state, {payload}) => {
      state.parametersList = payload
    },
    [setSelectedPrestation]: (state, {payload}) => {
      state.selectedPrestation = payload
    },
    [setSelectedEtat]: (state, {payload}) => {
      state.selectedEtat = payload
    },
    [setSelectedSrcData]: (state, {payload}) => {
      state.selectedSrcData = payload
    },
    [setMatriceDetail]: (state, {payload}) => {
      state.matriceDetail = payload
    },
    [setMatriceItems]: (state, {payload}) => {
      state.matriceItems = payload
    },
    [setIsNewDetail]: (state, {payload}) => {
      state.isNewDetail = payload
    },
    [setSelectedDetailMatrix]: (state, {payload}) => {
      state.selectedDetailMatrix = payload
    },
    [setSelectedParam]: (state, {payload}) => {
      state.selectedParam = payload
    },
    [setSelectedTarif]: (state, {payload}) => {
      state.selectedTarif = payload
    },
    [setFormuleCondition]: (state, {payload}) => {
      state.formuleCondition = payload
    },
    [setVisibleCalcul]: (state, {payload}) => {
      state.visibleCalcul = payload
    },
    [setFormuleCalcul]: (state, {payload}) => {
      state.formuleCalcul = payload
    },
    [setDataTarif]: (state, {payload}) => {
      state.dataTarif = payload
    },
    [setEditMatrice]: (state, {payload}) => {
      state.editMatrice = payload
    },
  },
})

//selectors
export const getMatrices = (state) => state[name].matrices
export const getTarifs = (state) => state[name].tarifs
export const getVisibleNew = (state) => state[name].visibleNew
export const getSelectedMatrix = (state) => state[name].selectedMatrix
export const getParamsNiveau = (state) => state[name].paramsNiveau
export const getNiveauSrcData = (state) => state[name].niveauSrcData
export const getPrestationList = (state) => state[name].prestationList
export const getEtatList = (state) => state[name].etatList
export const getParametersList = (state) => state[name].parametersList
export const getSelectedPrestation = (state) => state[name].selectedPrestation
export const getSelectedEtat = (state) => state[name].selectedEtat
export const getSelectedSrcData = (state) => state[name].selectedSrcData
export const getMatriceDetail = (state) => state[name].matriceDetail
export const getMatriceItems = (state) => state[name].matriceItems
export const getIsNewDetail = (state) => state[name].isNewDetail
export const getSelectedDetailMatrix = (state) => state[name].selectedDetailMatrix
export const getSelectedParam = (state) => state[name].selectedParam
export const getSelectedTarif = (state) => state[name].selectedTarif
export const getFormuleCondition = (state) => state[name].formuleCondition
export const getFormuleCalcul = (state) => state[name].formuleCalcul
export const getVisibleCalcul = (state) => state[name].visibleCalcul
export const getDataTarif = (state) => state[name].dataTarif
export const getEditMatrice = (state) => state[name].editMatrice

export default FacturationSlice.reducer
