import {createSlice, createAsyncThunk, createAction} from '@reduxjs/toolkit'
import {TEAM as name} from '../../../store/slices/config'
import _ from 'lodash'
import {_fetchTeams, _fetchTypeListStaff, _removeTeam, _saveTeam, _saveUser} from '../api'
import moment from 'moment'

export const fetchTeams = createAsyncThunk(
  `${name}/fetchTeams`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchTeams(_args)
    console.log('xx team', response)
    if (!response.error) dispatch(setTeams(response.data))
  }
)

export const fetchTypesStaff = createAsyncThunk(
  `${name}/fetchTypesStaff`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchTypeListStaff()
    console.log('xx team', response)
    if (!response.error) dispatch(setTypesStaff(response.data))
  }
)

export const createOrUpdateTeam = createAsyncThunk(
  `${name}/createOrUpdateTeam`,
  async (_args, {dispatch, getState}) => {
    try {
      console.log('team args:', _args)
      let {selectedTeam} = getState()[name]

      let data = _.cloneDeep(selectedTeam)
      console.log('dataSelectedTeam', data)

      let birthday = selectedTeam.birthday
        ? moment(selectedTeam.birthday, 'DD/MM/YYYY').format('YYYY/MM/DD')
        : null
      let hireday = selectedTeam.hireday
        ? moment(selectedTeam.hireday, 'DD/MM/YYYY').format('YYYY/MM/DD')
        : null
      let exitday = selectedTeam.exitday
        ? moment(selectedTeam.exitday, 'DD/MM/YYYY').format('YYYY/MM/DD')
        : null

      let obj = {
        id: data?.id || 0,
        firstname: data?.firstname?.trim() || '',
        lastname: data?.lastname?.trim() || '',
        typeId: _args?.status || data?.typeId,
        active: data?.active == (true || 1) ? 1 : 0,
        hireday: hireday || null,
        birthday: birthday || null,
        exitday: exitday || null,
        imageid: data?.imageid || _args.imageId,
        companyId: data?.companyId || 1,
        departementId: data?.departementId || 0,
      }
      console.log('team obj:', obj)
      let res = null

      console.log('xx selected:', obj)

      res = await _saveTeam(obj)
      console.log('xxx res:', res)

      if (Array.isArray(res.data) && (res.data || [])[0]?.typeMsg === 'success') {
        console.log('ok')
        dispatch(fetchTeams())
        return true
      } else if (Array.isArray(res.data) && (res.data || [])[0]?.msg === 'Already exist') {
        dispatch(setExistItem(true))
      }
      return false
    } catch (e) {
      console.log('error:', e.message)
      return false
    }
  }
)

export const createOrUpdateUser = createAsyncThunk(
  `${name}/createOrUpdateUser`,
  async (_args, {dispatch, getState}) => {
    try {
      console.log('args:', _args)
      let {selectedUser} = getState()[name]
      let data = _.cloneDeep(selectedUser)
      console.log('staff user data', data)
      let obj = {
        ...data,
        id: data.userID || 0,
        rest: data.rest ? 1 : 0,
        active: data.active ? 1 : 0,
      }
      let res = null

      console.log('eexx selected:', obj)

      res = await _saveUser(obj)
      console.log('xxx res:', res)

      if (Array.isArray(res.data) && (res.data || [])[0]?.typeMsg === 'success') {
        console.log('ok')
        dispatch(fetchTeams())
        return true
      } else if (Array.isArray(res.data) && (res.data || [])[0]?.msg === 'Already exist') {
        dispatch(setExistItem(true))
      }
      return false
    } catch (e) {
      console.log('error:', e.message)
      return false
    }
  }
)

export const removeTeam = createAsyncThunk(`${name}/removeTeam`, async (_arg, {dispatch}) => {
  let res = await _removeTeam(_arg?.id)

  console.log('res:', res)

  dispatch(fetchTeams())
})

//Actions
export const setTeams = createAction(`${name}/fetchTeams`)
export const setTypesStaff = createAction(`${name}/fetchTypesStaff`)
export const setSelectedUser = createAction(`${name}/setSelectedUser`)
export const setEditTeam = createAction(`${name}/setEditTeam`)
export const setEditUser = createAction(`${name}/setEditUser`)
export const setExistItem = createAction(`${name}/setExistItem`)
export const setShow = createAction(`${name}/setShow`)

const teamslice = createSlice({
  name,
  initialState: {
    teams: [],
    staffTypes: [],
    selectedTeam: null,
    selectedUser: null,
    editTeam: false,
    editUser: false,
    alreadyExist: false,
    show: true,
    tagVisible: false,
  },
  reducers: {
    setSelectedTeam(state, {payload}) {
      console.log('setSelectedTeam payload', payload)
    },
    setSelectedTeamV(state, {payload}) {
      console.log('setSelectedTeamV payload', payload)
      state.selectedTeam = payload
    },
    setTagVisible(state, {payload}) {
      state.tagVisible = payload
    },
  },
  extraReducers: {
    [setTeams]: (state, {payload}) => {
      state.teams = payload
    },

    [setSelectedUser]: (state, {payload}) => {
      state.selectedUser = payload
    },
    [setEditTeam]: (state, {payload}) => {
      state.editTeam = payload
    },
    [setEditUser]: (state, {payload}) => {
      state.editUser = payload
    },
    [setExistItem]: (state, {payload}) => {
      state.alreadyExist = payload
    },
    [setShow]: (state, {payload}) => {
      state.show = payload
    },
    [setTypesStaff]: (state, {payload}) => {
      state.staffTypes = payload
    },
  },
})

//selectors
export const getTeams = (state) => state[name].teams
export const getSelectedTeam = (state) => state[name].selectedTeam
export const getSelectedUser = (state) => state[name].selectedUser
export const getEditTeam = (state) => state[name].editTeam
export const getEditUser = (state) => state[name].editUser
export const getShow = (state) => state[name].show
export const getAlreadyExist = (state) => state[name].alreadyExist
export const getTypesStaff = (state) => state[name].staffTypes
export const getTagVisible = (state) => state[name].tagVisible

export const {setSelectedTeam, setSelectedTeamV, setTagVisible} = teamslice.actions

export default teamslice.reducer
