/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {Languages} from './Languages'
import {toAbsoluteUrl} from '../../../helpers'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { getCurrentUser, logout } from '../../../../components/User/slice/user.slice'
import ButtonComponent from '../../../../components/shared/ButtonComponent/ButtonComponent'
import { setEditLang , getEditLang } from '../../../../components/shared/Olang/slice/olang.slice'
import { Divider } from 'primereact/divider'
import { InputSwitch } from 'primereact/inputswitch'
import { getEnableNotification, setEnableNotification } from '../drawer-messenger/slice/Chat.slice'
import { API_BASE_URL_IMAGE } from '../../../../api/config'



const HeaderUserMenu: FC = () => {
  const currentUser = useAppSelector(getCurrentUser)
  const IMAGE_BASE_URL = API_BASE_URL_IMAGE
  const dispatch = useAppDispatch()
  const _logout = ()=> dispatch(logout())
  const edit = useAppSelector(getEditLang)
  const enableNotification = useAppSelector(getEnableNotification)

  const startEditing = ()=> {
    dispatch(setEditLang(!edit))
  }

  useEffect(()=> {
       console.log('enableNotification:', enableNotification)
  }, [enableNotification])


  
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl(IMAGE_BASE_URL + currentUser?.image)} />
            {/* <img alt='Logo' src={toAbsoluteUrl('/media/avatars/300-1.jpg')} /> */}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.first_name} {currentUser?.userName}
              <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>Pro</span>
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
          </div>
        </div>
      </div>

      <div className='separator my-2'></div>

      <div className='menu-item px-5 d-none'>
        <Link to={'/crafted/pages/profile'} className='menu-link px-5'>
          Profil
        </Link>
      </div>

      
      <div className='separator my-2'></div>

      <div className='flex justify-content-between pr-2'>
         <div className='w-12'>
            <Languages />
         </div>
         <ButtonComponent 
              icon={edit ? "pi pi-eye-splash" : "pi pi-pencil"} 
              className={`pr-2 ${edit ? 'p-button-danger': 'p-button-warning'}`} 
              onClick={startEditing} 
              />
      </div>

      <div className='menu-item px-5 my-1 d-none'>
        <Link to='/crafted/account/settings' className='menu-link px-5'>
          Parametre de compte
        </Link>
      </div>
      <Divider />
      <div className='flex align-items-center gap-2 px-2'>
          <InputSwitch checked={enableNotification} onChange={e => {
            dispatch(setEnableNotification(e.value))
          }}/>
          <span>Notifications</span>
      </div>
      <Divider />

      <div className='menu-item px-2 mt-3'>
        <a onClick={_logout} className='menu-link px-5'>
          Deconnexion
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}
