import {createSlice, createAsyncThunk, createAction} from '@reduxjs/toolkit'
import {PLANNING as name} from '../../../store/slices/config'
import _ from 'lodash'
import {_fetchEngineEvents} from '../api/api'

export const fetchEngineEvents = createAsyncThunk(
  `${name}/fetchEngineEvents`,
  async (_args, {getState, dispatch}) => {
    console.log('getState', getState())
    let response = await _fetchEngineEvents(_args)
    console.log('engine events: ', response)
    if (!response.error) {
      dispatch(setEngineEvents(response.data))
      return true
    }
  }
)

export const fetchEngineEventsWorksite = createAsyncThunk(
  `${name}/fetchEngineEventsWorksite`,
  async (_args, {getState, dispatch}) => {
    console.log('getState', getState())
    let response = await _fetchEngineEvents(_args)
    console.log('engine events worksite: ', response)
    if (!response.error) dispatch(setEngineEventsWorksite(response.data))
  }
)

//Actions
export const setEngineEvents = createAction(`${name}/fetchEngineEvents`)
export const setEngineEventsWorksite = createAction(`${name}/fetchEngineEventsWorksite`)

const planningSlice = createSlice({
  name,
  initialState: {
    engineEvents: [],
    engineEventsWorksite: [],
    messages: [],
    userID: null,
  },
  reducers: {},
  extraReducers: {
    [setEngineEvents]: (state, {payload}) => {
      state.engineEvents = payload
    },
    [setEngineEventsWorksite]: (state, {payload}) => {
      state.engineEventsWorksite = payload
    },
    setMessages: (state, {payload}) => {
      state.messages = payload
    },
    setMessage: (state, {payload}) => {
      console.log('message:', payload)
      state.messages.push(payload)
    },
    setUserID: (state, {payload}) => {
      state.userID = payload
    },
  },
})

//selectors
export const getEngineEvents = (state) => state[name].engineEvents
export const getEngineEventsWorksite = (state) => state[name].engineEventsWorksite
export const {setMessages, setMessage, setUserID} = planningSlice.actions

export const getMessages = (state) => state[name].messages
export const getUserID = (state) => state[name].userID

export default planningSlice.reducer
