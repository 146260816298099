import {createAction, createSlice} from '@reduxjs/toolkit'
import {FACTURE as name} from '../../../store/slices/config'

export const setSelectedFacture = createAction(`${name}/setSelectedFacture`)
export const setFactures = createAction(`${name}/setFactures`)
export const setOptionsValid = createAction(`${name}/setOptionsValid`)
export const setSelectedOptionsValid = createAction(`${name}/setSelectedOptionsValid`)
export const setOptionsConfirmation = createAction(`${name}/setOptionsConfirmation`)
export const setElementsFacture = createAction(`${name}/setElementsFacture`)

const FactureSlice = createSlice({
  name,
  initialState: {
    factures: [
      {
        identifiant: 187,
        nTourne: 100,
        idClient: 1,
        prestation: 'Article001',
        changeAdr: 'Casablanca',
        livraisonAdr: 'Kenitra',
        formule: '____',
        tarif: '2000',
        date: '29/04/2023',
        status: 'confirmed',
        isValid: null,
        validationFournisseur: 'pas',
        comment: '',
        validationClient: 'non',
        codeStatus: null,
        color: '#eee',
        backgroundColor: null,
        CustomerId: 27,
      },
      {
        identifiant: 120,
        nTourne: 330,
        idClient: 2,
        prestation: 'Article002',
        changeAdr: 'Fes',
        livraisonAdr: 'Oujda',
        formule: '____',
        tarif: '2000',
        date: '04/08/2020',
        status: 'confirmed',
        isValid: null,
        validationFournisseur: 'pas',
        validationClient: 'non',
        comment: '',
        client: 'kk',
        codeStatus: null,
        color: '#eee',
        backgroundColor: null,
        CustomerId: 27,
      },
      {
        identifiant: 90,
        nTourne: 30,
        idClient: 2,
        prestation: 'Article002',
        changeAdr: 'Fes',
        livraisonAdr: 'Oujda',
        formule: '____',
        tarif: '2000',
        date: '04/08/2020',
        status: 'confirmed',
        isValid: false,
        validationFournisseur: true,
        validationClient: 'non',
        comment: 'remarque1',
        client: 'kk',
        codeStatus: null,
        color: '#eee',
        backgroundColor: null,
        CustomerId: 27,
      },
      {
        identifiant: 1780,
        nTourne: 50,
        idClient: 2,
        prestation: 'Article002',
        changeAdr: 'Fes',
        livraisonAdr: 'Oujda',
        formule: '____',
        tarif: '2000',
        date: '04/08/2020',
        status: 'confirmed',
        isValid: true,
        validationFournisseur: false,
        validationClient: 'non',
        comment: '',
        client: 'kk',
        codeStatus: null,
        color: '#eee',
        backgroundColor: null,
        CustomerId: 27,
      },
    ],

    elementsFacture: [],
    selectedFacture: [],
    optionsValid: [
      {
        id: 1,
        name: 'Valide',
        value: true,
      },
      {
        id: 0,
        name: 'Non Valide',
        value: false,
      },
    ],
    optionsConfirmation: [
      {
        id: 1,
        name: 'confirmed',
        value: true,
      },
      {
        id: 0,
        name: 'Non confirmed',
        value: false,
      },
    ],
    selectedOptionsValid: '',
  },
  reducers: {},
  extraReducers: {
    [setFactures]: (state, {payload}) => {
      state.factures = payload
    },

    [setSelectedFacture]: (state, {payload}) => {
      state.selectedFacture = payload
    },

    [setOptionsValid]: (state, {payload}) => {
      state.optionsValid = payload
    },
    [setSelectedOptionsValid]: (state, {payload}) => {
      state.selectedOptionsValid = payload
    },
    [setOptionsConfirmation]: (state, {payload}) => {
      state.optionsValid = payload
    },
    [setElementsFacture]: (state, {payload}) => {
      state.elementsFacture = payload
    },
  },
})

//selectors

export const getFactures = (state) => state[name].factures
export const getSelectedFacture = (state) => state[name].selectedFacture
export const getOptionsValid = (state) => state[name].optionsValid
export const getSelectedOptionsValid = (state) => state[name].selectedOptionsValid
export const getOptionsConfirmation = (state) => state[name].optionsConfirmation
export const getElementsFacture = (state) => state[name].elementsFacture
export default FactureSlice.reducer
