import {memo, useEffect, useState} from 'react'
import {DialogComponent} from '../../../shared/DialogComponent'
import {InputText} from 'primereact/inputtext'
import ButtonComponent from '../../../shared/ButtonComponent.js'
import {InputSwitch} from 'primereact/inputswitch'
import {useAppDispatch, useAppSelector} from '../../../../hooks'

import _ from 'lodash'
import {
  createOrUpdateTeam,
  fetchTypesStaff,
  getAlreadyExist,
  getEditTeam,
  getSelectedTeam,
  getTypesStaff,
  setEditTeam,
  setExistItem,
  setSelectedTeam,
  setSelectedTeamV,
} from '../../slice/team.slice'
import {Calendar} from 'primereact/calendar'
import {Button} from 'primereact/button'
import {Message} from 'primereact/message'
import {Dropdown} from 'primereact/dropdown'
import {getValidator} from '../../../Inventory/slice/inventory.slice'
import {OlangItem} from '../../../shared/Olang/user-interface/OlangItem/OlangItem'

function TeamEditor() {
  const visible = useAppSelector(getEditTeam)
  const selectedTeam = useAppSelector(getSelectedTeam)
  console.log('**rabSelectedTeam', selectedTeam)
  const existItem = useAppSelector(getAlreadyExist)
  const dispatch = useAppDispatch()
  const [inputs, setInputs] = useState({})
  const [disabled, setDisabled] = useState(true)
  const [imageId, setImageId] = useState()
  const [imageChange, setImageChange] = useState(false)
  const [statusOption, setStatusOption] = useState([])
  const [statusClick, setStatusClick] = useState()
  const [isValid, setIsValid] = useState(true)
  const validators = useAppSelector(getValidator)
  const status = useAppSelector(getTypesStaff)
  const [inputValidity, setInputValidity] = useState({})
  const [isNotValid, setIsNotValid] = useState(true)

  const onHide = () => {
    dispatch(setEditTeam(false))
    dispatch(setStatusClick(null))
    dispatch(setExistItem(false))
    setIsNotValid(true)
    setInputValidity({})
  }

  const validateFields = (validators, old) => {
    console.log('old', old)
    let isValid = true
    validators.forEach((validator) => {
      const _regExp = new RegExp(validator.regExp.slice(1, -1))
      if (validator.isRequired && _regExp.test(old[validator.id]) === false) {
        isValid = false
      }
    })
    return isValid
  }

  const onInputChange = (e) => {
    // let old = _.cloneDeep(selectedTeam)
    console.log('setSelectedTeam old', selectedTeam)
    let old = {
      ...selectedTeam,
      active: selectedTeam ? selectedTeam?.active == 1 : true,
      typeName: selectedTeam?.typeName,
      [e.target.name]: e.target.value,
    }
    setInputs(old)
    dispatch(setSelectedTeamV(old))
    let isLabel = validateFields(validators, old)
    const areAllRequiredFieldsFilled = validators
      .filter((validator) => validator.isRequired)
      .every((validator) => !!old[validator.id])
    setIsNotValid(!areAllRequiredFieldsFilled || !isLabel)
  }

  const save = () => {
    if (isNotValid) {
      const requiredFieldsValidity = {}
      validators
        .filter((validator) => validator.isRequired)
        .forEach((validator) => {
          requiredFieldsValidity[validator.id] = !!selectedTeam?.[validator.id]
        })
      setInputValidity(requiredFieldsValidity)
      return
    }
    dispatch(createOrUpdateTeam({imageId: imageId, status: statusClick})).then((res) => {
      if (res.payload) {
        onHide()
      }
    })
  }

  const footer = (
    <div>
      <ButtonComponent label='Annuler' className='p-button-danger' onClick={onHide} />
      <ButtonComponent label='Enregistrer' onClick={save} />
    </div>
  )

  console.log('xxxxxxxxx selectedTeam', selectedTeam)

  useEffect(() => {
    if (!selectedTeam?.id) {
      setImageChange(true)
    } else if (selectedTeam?.id) {
      setImageChange(false)
    }
  }, [selectedTeam])

  useEffect(() => {
    if (existItem) {
      setTimeout(() => {
        dispatch(setExistItem(false))
      }, 3000)
    }
  }, [existItem])
  useEffect(() => {
    dispatch(
      setSelectedTeam({
        active: 1,
      })
    )
    setStatusOption([
      {name: 'selectionner', value: 0},
      ...status?.map((st) => ({
        name: st.label,
        value: st.id,
      })),
    ])
  }, [status])

  useEffect(() => {
    dispatch(fetchTypesStaff())
  }, [])

  const lastnameValidator = validators?.find((field) => field.id === 'lastname')
  const firstnameValidator = validators?.find((field) => field.id === 'firstname')
  const typeNameValidator = validators?.find((field) => field.id === 'typeName')
  return (
    <div>
      <DialogComponent
        visible={visible}
        footer={footer}
        header={`${selectedTeam?.id ? 'Edit team' : 'Create team'}`}
        onHide={onHide}
      >
        <div className='flex justify-content-center'>
          {existItem && (
            <Message severity='error' text='This Member is already exist' className='w-6' />
          )}
        </div>
        <div className='my-3'>
          <label>
            <OlangItem olang='staff.firstname' />
            {firstnameValidator?.isRequired == 1 && <span className='h3 text-danger'>*</span>}
          </label>
          <InputText
            id='firstname'
            name='firstname'
            className={`w-full ${existItem ? 'p-invalid' : null} ${
              inputValidity['firstname'] == false ? 'p-invalid' : ''
            }`}
            onChange={onInputChange}
            value={selectedTeam?.firstname}
          />
        </div>

        <div className='my-3'>
          <label>
            <OlangItem olang='staff.lastname' />
            {lastnameValidator?.isRequired == 1 && <span className='h3 text-danger'>*</span>}
          </label>
          <InputText
            id='lastname'
            name='lastname'
            className={`w-full ${existItem ? 'p-invalid' : null} ${
              inputValidity['lastname'] == false ? 'p-invalid' : ''
            }`}
            onChange={onInputChange}
            value={selectedTeam?.lastname}
          />
        </div>
        <div className='my-3'>
          <label>
            <OlangItem olang='staff.fonction' />
            {typeNameValidator?.isRequired == 1 && <span className='h3 text-danger'>*</span>}
          </label>
          <Dropdown
            id='typeName'
            name='typeName'
            value={statusClick}
            options={statusOption}
            optionLabel='name'
            onChange={(e) => {
              if (!e.value) return
              setStatusClick(e.value)
              onInputChange(e)
            }}
            placeholder={`${selectedTeam?.typeName || 'Select fonction'}`}
            className={`w-full ${inputValidity['typeName'] == false ? 'p-invalid' : ''}`}
          />
        </div>
        <div className='my-3 flex flex-column'>
          <label>Anniversaire</label>
          <Calendar
            id='birthday'
            name='birthday'
            showIcon
            className={`w-full ${inputValidity['birthday'] == false ? 'p-invalid' : ''}`}
            dateFormat='dd/mm/yy'
            onChange={onInputChange}
            placeholder={selectedTeam?.birthday ? `${selectedTeam?.birthday}` : 'dd/mm/yy'}
            value={selectedTeam?.birthday}
          />
          {/*  */}
        </div>
        <div className='my-3 flex flex-column'>
          <label>Create début</label>
          <Calendar
            id='hireday'
            name='hireday'
            showIcon
            className={`w-full ${inputValidity['hireday'] == false ? 'p-invalid' : ''}`}
            dateFormat='dd/mm/yy'
            onChange={onInputChange}
            placeholder={selectedTeam?.hireday ? `${selectedTeam?.hireday}` : 'dd/mm/yy'}
            value={selectedTeam?.hireday}
          />
        </div>
        <div className='my-3 flex flex-column w-full'>
          <label>Date départ</label>
          <div className='flex flex-row w-full justify-content-between'>
            <Calendar
              style={{width: '95%'}}
              showIcon
              disabled={disabled}
              id='exitday'
              name='exitday'
              dateFormat='dd/mm/yy'
              className={`w-full ${inputValidity['exitday'] == false ? 'p-invalid' : ''}`}
              onChange={onInputChange}
              placeholder={selectedTeam?.exitday ? `${selectedTeam?.exitday}` : 'dd/mm/yy'}
              value={selectedTeam?.exitday}
            />
            <Button
              icon={`${disabled ? 'pi pi-plus' : 'pi pi-times'}`}
              aria-label={`${disabled ? 'Filter' : 'Cancel'}`}
              severity={`${disabled ? 'success' : 'danger'}`}
              onClick={() => setDisabled(!disabled)}
            />
          </div>
        </div>
        <div className='my-3 flex align-items-center gap-2'>
          <label>Active</label>
          <InputSwitch
            id='active'
            name='active'
            checked={selectedTeam?.active == 1 ? true : selectedTeam == null ? true : false}
            onChange={onInputChange}
          />
        </div>
      </DialogComponent>
    </div>
  )
}

export default memo(TeamEditor)
