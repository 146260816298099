import {createSlice, createAsyncThunk, createAction, current} from '@reduxjs/toolkit'
import {ENGINE as name} from '../../../store/slices/config'
import _ from 'lodash'
import {
  _activateEngine,
  _deleteEngine,
  _fetchEnginListHistory,
  _fetchEnginListHistoryFromFlespi,
  _fetchEngines,
  _fetchInactiveEngin,
  _fetchStatusList,
  _fetchTypeList,
  _getGeoByIdGeo,
  _removeEngine,
  _saveEngine,
  _tagPosition,
} from '../api/api'
import {socket} from '../../../socket/socket'
import {_getGeoByIdSite, _saveEngineTypes} from '../../../api'
import {setToastParams} from '../../../store/slices/ui.slice'
import {setStatus} from '../../Tag/slice/tag.slice'

export const fetchEngines = createAsyncThunk(
  `${name}/fetchEngines`,
  async (_args, {getState, dispatch}) => {
    try {
      if (!_args) _args = {}
      let response = await _fetchEngines(_args)
      if (!response.error) {
        dispatch(setEngines(response?.data))
      } else {
        dispatch(setEngines([]))
      }
    } catch (error) {
      console.log('error', error)
    }
  }
)

export const fetchStatusList = createAsyncThunk(
  `${name}/fetchTypesList`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchStatusList('fr')
    if (!response.error) dispatch(setStatusList(response.data))
  }
)

export const getGeoByIdGeo = createAsyncThunk(
  `${name}/getGeoByIdGeo`,
  async (_args, {getState, dispatch}) => {
    let response = await _getGeoByIdGeo(_args)
    if (!response.error) {
      dispatch(setGeoByIdSite(response.data))
      console.log('getGeoByIdGeo', response)
    }
  }
)

export const fetchEnginListHistory = createAsyncThunk(
  `${name}/fetchEnginListHistory`,
  async (_args, {getState, dispatch}) => {
    try {
      console.log('fetchEnginListHistory', _args)
      if (_args.srcMouvement == 'pos') {
        let params = {
          reverse: 1,
          grouBy: 'dateFormated',
          fields:
            'locationName,LocationID,timestamp,address,dateFormated,lat,lng,userID,enginId,gateway,enginState,locationGeometry,nearestLocationName,macAddr,gateway,enginStateName,engin,user,userID',
        }
        if (_args?.srcId) params.enginId = `[${_args?.srcId}]`
        console.log('paramssss:', params)
        let response = await _fetchEnginListHistoryFromFlespi(params) //_fetchEnginListHistory(_args)
        console.log('flespi response:', response)
        if (response) {
          // if (Array.isArray(response.response)) {
          //   response.response = response.response.map((o) => ({
          //     ...o,
          //     enginState: o.locationName ? 'reception' : 'exit',
          //     enginStateName: o.locationName ? 'Entrée' : 'Sortie',
          //   }))
          // } else {
          //   response.response = []
          // }

          // let list = groupePositionHistories(response.response)
          // dispatch(setEnginListHistory(list))

          dispatch(setEnginListHistory(response))
        }
      } else {
        let response = await _fetchEnginListHistory(_args)
        console.log('fetchEnginListHistory', response)
        if (!response.error) dispatch(setEnginListHistory(response.data))
      }
    } catch (error) {
      console.log('error', error)
    }
  }
)

export const fetchEnginesWorksite = createAsyncThunk(
  `${name}/fetchEnginesWorksite`,
  async (_args, {getState, dispatch}) => {
    try {
      console.log('fetchEnginesWorksite _args', _args)
      let response = await _fetchEngines({
        LocationObject: _args.LocationObject,
        LocationID: _args.LocationID,
      })
      console.log('fetchEnginesWorksite', response)
      if (!response.error) dispatch(setEnginesWorksite(response.data))
    } catch (error) {
      console.log('error', error)
    }
  }
)

export const fetchTypesList = createAsyncThunk(
  `${name}/fetchTypesList`,
  async (_args, {getState, dispatch}) => {
    try {
      let response = await _fetchTypeList()
      if (!response.error) dispatch(setTypeList(response.data))
    } catch (error) {
      console.log('error', error)
    }
  }
)

export const getGeoByIdSite = createAsyncThunk(
  `${name}/getGeoByIdSite`,
  async (_args, {getState, dispatch}) => {
    try {
      console.log('getGeoByIdSite _args', _args)
      let response = await _getGeoByIdSite({worksiteID: _args})
      console.log('getGeoByIdSite response', response)
      if (!response.error) dispatch(setGeoByIdSite(response.data))
    } catch (error) {
      console.log('error', error)
    }
  }
)

export const createOrUpdateEngine = createAsyncThunk(
  `${name}/createOrUpdateEngine`,
  async (_args, {dispatch, getState}) => {
    try {
      let {selectedEngine} = getState()[name]
      let {typeFields} = getState()[name]

      let data = _.cloneDeep(selectedEngine)

      let filteredData = typeFields.filter((item) => item.type !== '')
      if (Array.isArray(filteredData) && filteredData.length === 0) {
        filteredData = ''
      }

      let obj = {
        ...data,
        tagName: data?.tagname || '',
        id: data?.id || 0,
        label: data?.reference,
        typeid: data?.typeID || data?.typeid,
        imageid: _args?.imageId || data?.imageid || 0,
        types: filteredData,
        familleId: data?.familleId,
        vin: data?.vin || '',
        infosAdditionnelles: data?.infosAdditionnelles || '',
        model: data?.model || '',
        immatriculation: data?.immatriculation || '',
        brand: data?.brand || '',
        reference: data?.reference || '',
      }
      let res = null
      res = await _saveEngine(obj)

      if (Array.isArray(res.data) && (res.data || [])[0]?.msg === 'ok') {
        socket.emit('engin_status_changed', {
          obj,
        })
        dispatch(fetchEngines({}))
        return true
      } else if (Array.isArray(res.data) && (res.data || [])[0]?.msg === 'Already exists!') {
        dispatch(setExistItem(true))
      }

      return false
    } catch (e) {
      console.log('error:', e.message)
      return false
    }
  }
)

export const createOrUpdateEnginTypes = createAsyncThunk(
  `${name}/createOrUpdateEnginTypes`,
  async (_args, {dispatch, getState}) => {
    try {
      let res = null
      res = await _saveEngineTypes(_args)
      if (Array.isArray(res.data) && (res.data || [])[0]?.msg === 'ok') {
        dispatch(fetchEngines({}))
        return true
      }
      //else if (Array.isArray(res.data) && (res.data || [])[0]?.msg === 'Already exists!') {
      //   dispatch(setExistItem(true))
      // }
    } catch (e) {
      console.log('error:', e.message)
      return false
    }
  }
)

export const fetchInactiveEngin = createAsyncThunk(
  `${name}/fetchInactiveEngin`,
  async (_args, {dispatch, getState}) => {
    try {
      let res = null
      res = await _fetchInactiveEngin()
      console.log('xxx fetchInactiveEngin', res)
      if (!res.error) {
        dispatch(setInactiveEngin(res.data))
        return true
      }
    } catch (e) {
      console.log('error:', e.message)
      return false
    }
  }
)

export const saveTagAddress = createAsyncThunk(
  `${name}/fetchDataDashboard`,
  async (_args, {getState, dispatch}) => {
    try {
      let newObj = {
        LocationID: +_args?.locationId,
        lat: +_args?.latitude,
        lng: +_args?.longitude,
        macAddr: _args?.macAddress,
      }
      console.log('yyy newObj', newObj)
      let response = await _tagPosition({tags: newObj})
      console.log('saveTagAddress', response)
      if (response.success) {
        return true
      }
    } catch (error) {
      console.log('error', error)
    }
  }
)

export const removeEngine = createAsyncThunk(`${name}/removeEngine`, async (_arg, {dispatch}) => {
  try {
    let res = await _removeEngine(_arg?.id)
    dispatch(fetchEngines({}))
  } catch (error) {
    console.log('error', error)
  }
})

export const activateEngin = createAsyncThunk(`${name}/activateEngin`, async (_arg, {dispatch}) => {
  try {
    let res = await _activateEngine(_arg)
    console.log('aaaa activateEngin', res)
    if (!res.error) {
      dispatch(
        setToastParams({
          show: true,
          severity: 'success',
          summary: 'SUCCESS',
          detail: res?.data[0]?.msg,
          position: 'top-right',
        })
      )
      dispatch(fetchInactiveEngin())
    }
  } catch (error) {
    console.log('error', error)
  }
})

export const deleteEngin = createAsyncThunk(`${name}/deleteEngin`, async (_arg, {dispatch}) => {
  try {
    let res = await _deleteEngine(_arg)
    console.log('dddd deleteEngin', res)
    if (!res.error) {
      dispatch(
        setToastParams({
          show: true,
          severity: 'success',
          summary: 'SUCCESS',
          detail: res?.data[0]?.msg,
          position: 'top-right',
        })
      )
      dispatch(fetchInactiveEngin())
    }
  } catch (error) {
    console.log('error', error)
  }
})

function groupePositionHistories(list) {
  try {
    let currentIndex = 0
    let currentLat = null
    let currentLng = null
    let currentLocation = null
    list.forEach((o, idx) => {
      if (
        (o.lat != currentLat || o.lng != currentLng) &&
        (o.LocationID == 0 || (o.LocationID != 0 && o.LocationID !== currentLocation))
      ) {
        currentIndex++
        o.locIndex = currentIndex
        currentLat = o.lat
        currentLng = o.lng
        currentLocation = o.LocationID
      } else {
        o.locIndex = currentIndex
      }
    })

    console.log('groupePositionHistories O:', list)
    return _.uniqBy(list, 'locIndex')
  } catch (e) {
    console.log('groupePositionHistories Error:', e.message)
    return list
  }
}

//Actions
export const setEngines = createAction(`${name}/fetchEngines`)
export const setEnginListHistory = createAction(`${name}/fetchEnginListHistory`)
export const setEnginesWorksite = createAction(`${name}/fetchEnginesWorksite`)
export const setShow = createAction(`${name}/setShow`)
export const setTypeList = createAction(`${name}/setTypeList`)
export const setSelectedEngine = createAction(`${name}/setSelectedEngine`)
export const setSelectedTag = createAction(`${name}/setSelectedTag`)
export const setEditEngine = createAction(`${name}/setEditEngine`)
export const setEditTagEngin = createAction(`${name}/setEditTagEngin`)
export const setTypeEdit = createAction(`${name}/setTypeEdit`)
export const setTypeFields = createAction(`${name}/setTypeFields`)
export const seTagEdit = createAction(`${name}/seTagEdit`)
export const setExistItem = createAction(`${name}/setExistItem`)

const engineSlice = createSlice({
  name,
  initialState: {
    engines: [],
    geoByIdSite: [],
    statusList: [],
    paramCardHis: {
      showList: false,
      title: '',
    },
    enginesHistory: [],
    enginesWorksite: [],
    inactiveEngin: [],
    selectedHistory: null,
    srcMovement: null,
    socketEnterOrExit: null,
    socketStatusChange: null,
    showHistory: false,
    typesList: [],
    typeFields: [],
    selectedTag: null,
    editTag: false,
    show: true,
    selectedEngine: null,
    editEngine: false,
    editTagEngin: false,
    editType: false,
    selectedEnginMap: null,
    existItem: false,
    lastUpdates: [],
    statusVisible: false,
  },
  reducers: {
    setSocketEnterOrExit(state, {payload}) {
      state.socketEnterOrExit = payload
      const msg = payload.msg
      const id = payload?.data[0].enginId
      const findEngin = state.engines.find((item) => item.id === id)
      let newObj = {
        ...findEngin,
        etatengin: msg !== 'exit' ? 'Entrée' : 'Exit',
        etatenginname: msg !== 'exit' ? 'reception' : 'exit',
      }
      state.engines = state.engines.map((item) => {
        if (item.id === id) {
          return newObj
        }
        return item
      })
    },
    setStatusVisible(state, {payload}) {
      state.statusVisible = payload
    },
    setStatusList(state, {payload}) {
      state.statusList = payload
    },
    setGeoByIdSite(state, {payload}) {
      state.geoByIdSite = payload
    },
    setUpdatedEngin(state, {payload}) {
      if (Array.isArray(state.engines) && state.engines.length > 0) {
        const newEngines = state.engines.map((item) => {
          const updatedItem = payload.find(
            (element) => element.id == item.id || element.uid == item.uid
          )
          return updatedItem ? {...item, ...updatedItem} : item
        })
        state.engines = newEngines
      }
    },
    setInactiveEngin(state, {payload}) {
      state.inactiveEngin = payload
    },
    setParamCadHis(state, {payload}) {
      state.paramCardHis = {...state.paramCardHis, ...payload}
    },
    setSrcMouvement(state, {payload}) {
      state.srcMovement = payload
    },
    setShowHistory(state, {payload}) {
      state.showHistory = payload
    },
    setSelectedHistory(state, {payload}) {
      state.selectedHistory = payload
    },
    setSelectedEnginMap(state, {payload}) {
      state.selectedEnginMap = payload
    },
    setSocketStatusChange(state, {payload}) {
      state.socketStatusChange = payload
      state.engines = state.engines.map((item) => {
        if (item.uid == payload?.newObj.uid) {
          return {...payload?.newObj, iconName: payload.icon, statusbgColor: payload.statusbgColor}
        }
        return item
      })
      return
      const msg = payload?.data[0].typeMsg
      const id = payload?.data[0].enginId
      const findEngin = state.engines.find((item) => item.id === id)
      let newObj = {
        ...findEngin,
        statuslabel: payload?.statuslabel,
        statusname: payload?.statusname,
        statusbgColor: payload?.statusbgColor,
      }
    },
    updateEnginLastSeen(state, {payload}) {
      let data = _.cloneDeep(payload)
      if (!Array.isArray(data) || data?.length == 0) return
      let list = _.cloneDeep(state.lastUpdates)
      let engines = _.cloneDeep(state.engines)

      for (let engin of payload) {
        let eng = list.find(({id}) => id == engin.engin)
        let eng2 = engines.find(({id}) => id == engin.engin)
        if (eng) eng.lastSeenAt = engin.lastSeen
        if (eng2) eng2.lastSeenAt = engin.lastSeen
      }
      state.lastUpdates = list
      state.engines = engines
    },
    setLastEnginUpdates(state, {payload}) {
      let oldState = _.cloneDeep(state.lastUpdates)
      let newState = _.cloneDeep(payload)
      let oldEngins = _.cloneDeep(state.engines)
      if (!Array.isArray(oldState)) oldState = []
      if (!Array.isArray(newState)) newState = []
      if (!Array.isArray(oldEngins)) oldEngins = []

      for (let engin of newState) {
        let eng = oldEngins.find(({id}) => id == engin.id)
        console.log('oldEngin:', eng)
        if (eng) {
          for(const [k , v] of Object.entries(engin)){
            eng[k] = v
          }
          eng.locationDate = engin.lastUpdate
          // eng.lastSeenAt = engin.lastSeenAt
          // eng.lastUpdate = engin.lastUpdate
          // eng.LocationID = engin.LocationID
          // eng.etatenginname = engin.etatenginname
          // eng.etatengin = engin.etatengin
          // 
        }
      }
      let list2 = oldEngins.map((t) => {
        let eng = newState.find(({id}) => id == t.id)
        if (eng) {
          for(const [k , v] of Object.entries(eng)){
            t[k] = v
          }
          // t.locationDate = eng.lastUpdate
          // t.lastSeenAt = eng.lastSeenAt
          // t.lastUpdate = eng.lastUpdate
          // t.LocationID = eng.LocationID
          // t.etatenginname = eng.etatenginname
          // t.etatengin = eng.etatengin
          t.locationDate = eng.lastUpdate
        }
        return t
      })
      console.log('data new new_updated_engins slice:', newState, oldEngins, list2)

      state.lastUpdates = newState
      state.engines = oldEngins
    },
  },
  extraReducers: {
    [setEngines]: (state, {payload}) => {
      state.engines = payload
    },
    [setEnginListHistory]: (state, {payload}) => {
      state.enginesHistory = payload
    },
    [setEnginesWorksite]: (state, {payload}) => {
      state.enginesWorksite = payload
    },
    [setSelectedEngine]: (state, {payload}) => {
      state.selectedEngine = payload
    },
    [setEditEngine]: (state, {payload}) => {
      state.editEngine = payload
    },
    [setTypeList]: (state, {payload}) => {
      state.typesList = payload
    },
    [setShow]: (state, {payload}) => {
      state.show = payload
    },
    [setEditTagEngin]: (state, {payload}) => {
      state.editTagEngin = payload
    },
    [setTypeEdit]: (state, {payload}) => {
      state.editType = payload
    },
    [setTypeFields]: (state, {payload}) => {
      state.typeFields = payload
    },
    [seTagEdit]: (state, {payload}) => {
      state.editTag = payload
    },
    [setSelectedTag]: (state, {payload}) => {
      state.selectedTag = payload
    },
    [setExistItem]: (state, {payload}) => {
      state.existItem = payload
    },
  },
})

//selectors
export const getEngines = (state) => state[name].engines
export const getEnginListHistory = (state) => state[name].enginesHistory
export const getEnginesWorksite = (state) => state[name].enginesWorksite
export const getSelectedEngine = (state) => state[name].selectedEngine
export const getEditEngine = (state) => state[name].editEngine
export const getTypeList = (state) => state[name].typesList
export const getEditTagEngin = (state) => state[name].editTagEngin
export const getShow = (state) => state[name].show
export const getEditType = (state) => state[name].editType
export const getTypeFields = (state) => state[name].typeFields
export const getTagEdit = (state) => state[name].editTag
export const getSelectedTag = (state) => state[name].selectedTag
export const getExistItem = (state) => state[name].existItem
export const getSelectedEnginMap = (state) => state[name].selectedEnginMap
export const getInactiveEngin = (state) => state[name].inactiveEngin
export const getShowHistory = (state) => state[name].showHistory
export const getSrcMouvement = (state) => state[name].srcMovement
export const getSelectedHistory = (state) => state[name].selectedHistory
export const getParamCardHis = (state) => state[name].paramCardHis
export const getGeoByIdPos = (state) => state[name].geoByIdSite
export const getLastEnginsUpdates = (state) => state[name].lastUpdates
export const getStatusList = (state) => state[name].statusList

export const getStatusVisible = (state) => state[name].statusVisible

//actions
export const {
  setSocketEnterOrExit,
  setSocketStatusChange,
  setParamCadHis,
  setUpdatedEngin,
  setSelectedEnginMap,
  setSrcMouvement,
  setSelectedHistory,
  setStatusList,
  setGeoByIdSite,
  setInactiveEngin,
  setShowHistory,
  updateEnginLastSeen,
  setLastEnginUpdates,
  setStatusVisible,
} = engineSlice.actions

export default engineSlice.reducer
