import {memo, useEffect} from 'react'
import {DatatableComponent} from '../../../shared/DatatableComponent/DataTableComponent'
import {useAppDispatch, useAppSelector} from '../../../../hooks'

import {Image} from 'primereact/image'

import {Chip} from 'primereact/chip'
import {
  fetchTeams,
  getTeams,
  removeTeam,
  setEditTeam,
  setSelectedTeam,
  setSelectedTeamV,
  setSelectedUser,
  setShow,
} from '../../slice/team.slice'
import {OlangItem} from '../../../shared/Olang/user-interface/OlangItem/OlangItem'
import {SplitButton} from 'primereact/splitbutton'
import {useState} from 'react'
import {setAlertParams} from '../../../../store/slices/alert.slice'
import UserEdit from './UserEdit'
import {Panel} from 'primereact/panel'
import {API_BASE_URL_IMAGE} from '../../../../api/config'
import {fetchValidator} from '../../../Inventory/slice/inventory.slice'

const TeamList = () => {
  const [dialogVisible, setDialogVisible] = useState(false)
  const [isLoadingButton, setIsLoadingButton] = useState(false)

  const teams = useAppSelector(getTeams)

  const dispatch = useAppDispatch()

  let actions = [
    {
      label: 'Supprimer',
      icon: 'pi pi-trash text-red-500',
      command: (e) => {
        dispatch(setSelectedTeamV(e.item.data))
        dispatch(
          setAlertParams({
            title: 'Supprimer',
            message: 'Voulez-vous vraiment supprimercet équipe?',
            acceptClassName: 'p-button-danger',
            visible: true,
            accept: () => {
              dispatch(removeTeam(e.item.data))
            },
          })
        )
      },
    },
    {
      label: 'Detail',
      icon: 'pi pi-eye text-blue-500',
      command: (e) => {
        // toast.current.show({severity: 'success', summary: "success", detail: "Supprimer avec success!!!"})
        // console.log('eee:', e)
        dispatch(fetchValidator('staff'))
        dispatch(setSelectedTeamV(e.item.data))
        dispatch(setShow(false))
      },
    },
    {
      label: `Utilisateur`,
      icon: 'pi pi-user text-blue-500',
      command: (e) => {
        dispatch(setSelectedUser(e.item.data))
        setDialogVisible(true)
      },
    },
  ]
  const activeTemplate = (rowData) => (
    <Chip
      label={rowData?.active == 1 ? 'Actif' : 'Inactif'}
      icon={rowData?.active == 1 ? 'pi pi-check' : 'pi pi-times'}
      className={'text-white ' + (rowData?.active == 1 ? 'bg-green-500' : 'bg-red-500')}
    />
  )

  const imageTemplate = (rowData) => (
    <img
      src={`${API_BASE_URL_IMAGE}${rowData?.image}`}
      alt='EngineImage'
      className='image-preview rounded'
      style={{width: '60px', height: '60px', objectFit: 'cover'}}
    />
  )
  const actionTemplate = (rowData) => {
    actions = actions.map((_i) => ({..._i, data: rowData}))
    return (
      <div>
        <SplitButton
          model={actions}
          className='p-button-help p-button-raised  p-button-outlined p-button-sm bg-white'
        />
      </div>
    )
  }
  // const emptyColumn = (rowData) =>
  // rowData?.exitday == null ? <p className='pi pi-minus ml-4'></p> : rowData?.exitday
  const columns = [
    {
      header: 'Photo',
      field: 'image',
      olang: 'Photo',
      body: imageTemplate,
    },
    {
      header: 'Nom employé',
      field: 'lastname',
      olang: 'Nom.employe',
      filter: true,
    },
    {
      header: 'Prénom',
      field: 'firstname',
      olang: 'Prenom',
      filter: true,
    },
    {
      header: 'Login',
      field: 'login',
      olang: 'Login',
      filter: true,
    },
    {
      header: 'Function',
      field: 'famille',
      olang: 'Function',
      filter: true,
    },
    {
      header: 'hireday',
      olang: 'hireday',
      field: 'hireday',
      filter: true,
    },
    {
      header: 'exitday',
      olang: 'exitday',
      field: 'exitday',
      filter: true,
    },
    {
      header: 'Status',
      olang: 'status',
      field: 'active',
      body: activeTemplate,
    },
  ]

  const exportFields = [
    {label: 'Nom employé', column: 'lastname'},
    {label: 'Prénom', column: 'firstname'},
    {label: 'Function', column: 'famille'},
    {label: 'hireday', column: 'hireday'},
    {label: 'exitday', column: 'exitday'},
  ]

  const allowedGroupFields = ['famille']

  const rowGroupTemplates = {
    famille: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.famille} />
    ),
  }

  let create = () => {
    setIsLoadingButton(true)
    dispatch(fetchValidator('staff'))
      .then(() => {
        dispatch(setEditTeam(true))
        dispatch(setSelectedTeamV(null))
      })
      .finally(() => setIsLoadingButton(false))
  }

  useEffect(() => {
    dispatch(fetchTeams())
  }, [])

  return (
    <>
      <div className='py-3 flex flex-row align-items-center'>
        <h1 className='text-700'>
          <OlangItem olang={'staff.list'} />
        </h1>
      </div>
      <DatatableComponent
        tableId='team-table'
        data={teams}
        columns={columns}
        exportFields={exportFields}
        onNew={create}
        rowGroupTemplates={rowGroupTemplates}
        allowedGroupFields={allowedGroupFields}
        contextMenuModel={actions}
        onSelections={(r) => console.log('data:', r)}
        rowActions={actions}
        isLoading={isLoadingButton}
      />
      <UserEdit
        dialogVisible={dialogVisible}
        setDialogVisible={() => setDialogVisible((prev) => !prev)}
      />
    </>
  )
}

export default memo(TeamList)
