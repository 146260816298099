import React from 'react'
import {DatatableComponent} from '../../shared/DatatableComponent/DataTableComponent'
import ButtonComponent from '../../shared/ButtonComponent'

const DialogContent = (props) => {
  return (
    <div>
      <div>
        <DatatableComponent
          columns={props.columns}
          data={props.data}
          tableId={props.tableId}
          onSelections={props.onSelections}
          selectionMode={props.selectionMode}
          rowActions={props.rowActions}
        />
      </div>
      <div className='flex justify-content-end mt-5'>
        {props?.addBtn !== false && (
          <ButtonComponent
            label={props.label || 'Ajouter'}
            icon={props.icon || 'pi pi-plus-circle'}
            onClick={props.onAddButtonClick}
          />
        )}
      </div>
    </div>
  )
}

export default DialogContent
