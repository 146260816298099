import React, {useEffect, useRef, useState} from 'react'
import MapComponent from '../../shared/MapComponent/user-interface/MapComponent'
import {useSelector} from 'react-redux'
import {fetchTags, getTags} from '../../Tag/slice/tag.slice'
import {useDispatch} from 'react-redux'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import _ from 'lodash'
import assetConfigs from '../../../configs/index'
import {
  fetchEngines,
  fetchStatusList,
  fetchTypesList,
  getEngines,
  getStatusList,
} from '../../Engin/slice/engin.slice'
import {MapContainer, TileLayer} from 'react-leaflet'
import {API_BASE_URL_IMAGE} from '../../../api/config'
import {Image} from 'primereact/image'
import {Avatar} from 'primereact/avatar'
import {OlangItem} from '../../shared/Olang/user-interface/OlangItem/OlangItem'
import moment from 'moment'

let defaultLatLngs = []

let groups = [
  'status',
  'customer',
  {label: 'Famille', value: 'category'},
  {label: 'Zone', value: 'LocationObjectname'},
]
const TagMapViewComponent = ({type}) => {
  const [formatedList, setFormatedList] = useState([])

  const list = useAppSelector(getEngines)
  const statusList = useAppSelector(getStatusList)

  const dispatch = useAppDispatch()
  const pioDetailMap = useRef(null)
  console.log('formatedList', list)

  let groupEnter = [
    {
      label: <OlangItem olang='Enter' />,
      value: 'reception',
      icon: 'fa-down-to-bracket',
      color: 'green',
    },

    {label: <OlangItem olang='Exit' />, value: 'exit', icon: 'fa-up-from-bracket', color: 'red'},
  ]

  const itemTemplate = (item) => {
    return (
      <div className='flex'>
        {/* <div className='image'>
          <Image
            src={assetConfigs.asset_server_url + item.image}
            alt='EngineImage'
            width='50'
            height='50'
            preview
            imageStyle={{objectFit: 'cover', borderRadius: '10px'}}
          />
        </div> */}
        <div className='info flex flex-column mx-2'>
          <strong className='text-lg'>{item.reference}</strong>
          <strong className='text-sm text-gray-700'>{item.famille}</strong>
          <strong className='text-sm text-gray-700'>{item.LocationObjectname}</strong>
        </div>
      </div>
    )
  }

  const itemDetailTemplate = (poiInfos) => {
    return (
      <div>
        <span
          className='pi pi-times absolute text-white '
          onClick={(e) => {
            // setPioInfos(null)
            // setSelectedPio(null)
          }}
          style={{top: '10px', right: '10px', fontSize: '20px', zIndex: 10}}
        ></span>
        <div className=' bg-white' style={{width: '400px'}}>
          <div>
            <MapContainer
              minZoom={1}
              maxZoom={22}
              zoom={15}
              zoomControl={false}
              center={[poiInfos.last_lat, poiInfos.last_lng]}
              ref={pioDetailMap}
              style={{width: '100%', height: '170px'}}
            >
              <TileLayer
                maxNativeZoom={18}
                minZoom={1}
                maxZoom={22}
                attribution='&copy openstreetmap'
                url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
              />
            </MapContainer>
          </div>
          <div className='p-2'>
            <div className='flex justify-content-between'>
              <strong>{poiInfos?.reference}</strong>
              <div>
                <span className='pi pi-cog'></span>
              </div>
            </div>
            <div className='flex gap-4 flex-wrap mt-4'>
              <div className='flex gap-2 align-items-center'>
                <span
                  style={{color: poiInfos?.statusbgColor}}
                  className='pi pi-circle-fill tex-lg'
                ></span>
                <strong>{poiInfos?.statuslabel}</strong>
              </div>

              <div className='flex gap-2  align-items-center'>
                <i className='fa-solid fa-battery-full text-green-500'></i>
                <strong>{poiInfos?.batteries}</strong>
              </div>
              <div className='flex gap-2 align-items-center'>
                <span className='pi pi-wifi  text-green-500'></span>
                <strong>{'Good'}</strong>
              </div>
            </div>
            <div className='mt-4'>
              <div className='flex gap-2 mb-3 align-items-center'>
                <div className='w-1'>
                  <span className='pi pi-map-marker  text-gray-500'></span>
                </div>
                <div className='w-11'>
                  <strong className='block text-lg'>{poiInfos?.enginAddress}</strong>
                  <span className='text-gray-600'>{poiInfos?.locationDate}</span>
                </div>
              </div>
              <div className='flex gap-2 mb-3 align-items-center'>
                <div className='w-1'>
                  <span className='pi pi-truck  text-gray-500'></span>
                </div>
                {false && (
                  <div className='w-11'>
                    <strong className='block text-lg'>Test</strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const pioPopupTemplate = (poiInfos) => {
    return (
      <div className='text-left'>
        <div className='flex gap-2 flex-wrap align-items-center'>
          {poiInfos?.image && (
            <img
              alt=''
              style={{borderRadius: '50%'}}
              width={50}
              height={50}
              src={API_BASE_URL_IMAGE + poiInfos.image}
            />
          )}
          <strong>{poiInfos?.reference}</strong>
        </div>
        <div className='flex gap-3 flex-wrap mt-4'>
          <div className='flex gap-2 align-items-center'>
            <i
              title={poiInfos?.statuslabel}
              className={poiInfos.iconName + ' text-xl'}
              style={{color: poiInfos.statusbgColor}}
            />
          </div>

          <div className='flex gap-2  align-items-center'>
            <i className='fa-solid fa-battery-full text-green-500'></i>
            <strong>{poiInfos?.batteries >= 100 ? 100 : poiInfos?.batteries}%</strong>
          </div>
          <div className='flex gap-2 align-items-center'>
            <span className='pi pi-wifi  text-green-500'></span>
            <strong>{'Good'}</strong>
          </div>
          {!poiInfos?.sysMode === 'gateway' && (
            <div className='flex gap-2 align-items-center'>
              <i className='pi pi-user text-gray-500'></i>
              <strong className='text-base pl-2'>{poiInfos?.lastUser}</strong>
            </div>
          )}
        </div>
        <div className='mt-4'>
          <div className='flex gap-2 mb-3 align-items-center'>
            <div className='w-1'>
              <i
                title={poiInfos?.etatengin}
                className={poiInfos.etatIconName + ' text-2xl'}
                style={{color: poiInfos.etatbgColor}}
              ></i>
            </div>
            <div className='w-11'>
              <strong className='text-lg'>{poiInfos?.LocationObjectname}</strong>
              <span className='text-gray-600 pl-2'>
                {poiInfos?.lastSeenAt
                  ? moment(poiInfos.lastSeenAt).format('DD/MM/YYYY HH:mm:ss')
                  : poiInfos?.locationDate}
              </span>
            </div>
          </div>

          <div className='flex gap-2 mb-3 align-items-center'>
            <OlangItem olang='Address' />
            <strong className='pl-2'>{poiInfos?.enginAddress}</strong>
          </div>
          {/* <div className='flex gap-2 mb-3 align-items-center'>
            <div className='w-1'>
              <span className='pi pi-truck  text-black'></span>
            </div>
            {false && (
              <div className='w-11'>
                <strong className='block text-lg'>Test</strong>
              </div>
            )}
          </div> */}
        </div>
      </div>
    )
  }

  useEffect(() => {
    dispatch(fetchEngines({}))
    dispatch(fetchStatusList())
  }, [])

  useEffect(() => {
    if (Array.isArray(list)) {
      let lst = _.cloneDeep(list)
      let cp = 0
      lst?.forEach((o) => {
        o.lat = o.last_lat
        o.lng = o.last_lng
        if (o.lat == 0 || o.lng == 0) {
          // if (cp >= defaultLatLngs.length) cp = 0
          // let latlng = defaultLatLngs[cp]
          // o.lat = latlng.lat
          // o.lng = latlng.lng
          // cp++
        }
        if (o) {
          o.label = o.reference
          o.category = o.famille
          // o.image = o.engin.image
        }
        o.famille = o.category || o.famille
        // o.label = o.label || o.name || o.macAddr
      })

      setFormatedList(lst)
    } else {
      setFormatedList([])
    }
  }, [list])

  return (
    <div>
      <MapComponent
        markerNameKey={'label'}
        groups={['famille', {label: 'Zone', value: 'LocationObjectname'}]}
        groupPioBy={'status'}
        piosPosition={'topleft'}
        pios={formatedList}
        itemTemplate={itemTemplate}
        pioPopupTemplate={pioPopupTemplate}
        groupsEnter={groupEnter}
        groupsStatus={statusList}
        // itemDetailTemplate={itemDetailTemplate}
        type={type || 'main'}
      ></MapComponent>
    </div>
  )
}

export default TagMapViewComponent
