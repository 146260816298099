import _axios from './axios'
import {request as orequest} from '../../../api'

async function request(url, params) {
  return await orequest(url, params, _axios)
}

export async function _fetchMatriceList() {
  return await request('/matrix/list', {data: {id: ''}})
}

export async function _fetchMatriceItemsList(id) {
  return await request('/matrix/getMatriceItems', {data: {ID_Matrice: id}})
}

export async function _fetchTarifList() {
  return await request('/tarif/list', {data: {id: ''}})
}
export async function _fetchParamsList() {
  return await request('/tarif/getParamsNiveau')
}

export async function _fetchParametersList() {
  return await request('/tarif/getParametres')
}

export async function _fetchNiveauSrcDataList(id) {
  return await request(`/tarif/GetParamsNiveauSrcData?IdParam=${id}`)
}

export async function _fetchPrestationList() {
  return await request('/tarif/ClientGetPrestationList')
}

export async function _fetchEtatList() {
  return await request('/tarif/TarifGetEtat')
}

export async function _saveMatrix(data) {
  return await request('matrix/save', {
    method: 'post',
    data,
  })
}
export async function _savePrixMatrix(data) {
  return await request('matrix/UpdatePrixMatrice', {
    method: 'post',
    data,
  })
}

export async function _removeMatrice(id) {
  console.log('id matrice', id)
  return await request('matrix/delete', {
    method: 'POST',
    data: {id},
  })
}

export async function _saveTarif(data) {
  return await request('tarif/save', {
    method: 'post',
    data,
  })
}

export async function _saveMatrixDetails(data) {
  return await request('matrix/AddDimension', {
    method: 'post',
    data,
  })
}

export async function _fetchInvoicePendingBilling(id = 0) {
  return await request('/invoicePendingBilling/list', {data: {ID: id}})
}

export async function _saveInvoicePendingBilling(data) {
  return await request('invoicePendingBilling/save', {
    method: 'post',
    data,
  })
}
