export const CUSTOMER = 'customers'
export const GEOFENCING = 'geofencing'
export const AUTH = 'auth'
export const VEHICLE = 'vehicles'
export const STATUS = 'status'
export const PROVIDER = 'provider'
export const DEPOSIT = 'deposit'
export const STAFF = 'staffs'
export const TAG = 'tag'
export const OLANG = 'olang'
export const ALERT = 'alert'
export const INVOICE = 'invoice'
export const SITE = 'site'
export const ENGINE = 'engine'
export const CUSTOMER_SERVICE = 'costumer'
export const TEAM = 'team'
export const COMPANY = 'company'
export const FACTURE = 'facture'
export const NAVIXY = 'navixy'
export const FACTURATION = 'facturation'
export const ELEMENTFACTURABLE = 'elementFacturable'
export const SETUPINFO = 'setupInfo'
export const RFTAG = 'rfTag'
export const RFENGINE = 'rfEngine'
export const LOCATIONTAG = 'locationTag'
export const DASHBOARD = 'dashboard'
export const CONFIRM_DIALOG = 'confirmDialog'
export const PLANNING = 'planning'
export const ADDRESS_SITE = 'addressSite'
export const ADDRESS_DEPOT = 'addressDepot'
//export const ADDRESS_DEPOSIT = 'addressDeposit'
export const FAMILLE = 'famille'
export const VEHICULES = 'vehicules'
export const INVENTORY = 'inventory'
export const Chat = 'chat'
export const STATUT = 'statut'
export const COMPANYDEPOSITE = 'companyDeposite'
export const DEPOT = 'depot'
export const RAPPORT = 'rapport'
export const LOGS = 'logs'

// /
