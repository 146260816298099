import {createSlice, createAsyncThunk, createAction} from '@reduxjs/toolkit'
import {SITE as name} from '../../../store/slices/config'
import {
  _saveSite,
  _removeSite,
  _fetchCustomers,
  _fetchSitesByCodeClient,
  _fetchSites,
  _fetchGeoForSite,
  _fetchAddressPsCore,
} from '../api/api'
import _ from 'lodash'
import {_relationAdd} from '../../Tag/api/api'
import {fetchSitesClient} from '../../../store/slices/customer.slice'
import {_fetchGeofencings} from '../../shared/MapComponent/api/geofence'

export const fetchAddresses = createAsyncThunk(
  `${name}/fetchAddresses`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchAddressPsCore()

    if (!response.error) dispatch(setAddresses(response.data))

    console.log('address:', response)
  }
)

export const fetchSites = createAsyncThunk(
  `${name}/fetchSites`,
  async (_args, {getState, dispatch}) => {
    try {
      let response = await _fetchSites({IDCustomer: _args || 0})
      console.log('site list 22: ', response)
      if (!response.error) {
        let data = response?.data?.filter((x) => x.type === 'Worksite' || x.type === 'Deposit')
        console.log('data', data)
        dispatch(setSites(data))
      }
    } catch (e) {
      console.log('error fetchSites', e)
    }
  }
)
export const fetchGeoForSite = createAsyncThunk(
  `${name}/fetchGeoForSite`,
  async (id, {getState, dispatch}) => {
    let response = await _fetchGeoForSite(id)
    if (!response.error) {
      dispatch(setGeoSite(response.data))
    }
  }
)

export const fetchAllGeo = createAsyncThunk(
  `${name}/fetchAllGeo`,
  async (id, {getState, dispatch}) => {
    let response = await _fetchGeofencings()
    if (!response.error) dispatch(setListGeo(response.data))
  }
)

export const createOrUpdateSite = createAsyncThunk(
  `${name}/createOrUpdateSite`,
  async (_args, {dispatch, getState}) => {
    console.log('site selected 0:', _args)
    try {
      let {selectedSite} = getState()[name]

      let data = _.cloneDeep(selectedSite)
      console.log('site selected 1:', data)

      if (data.active == null) {
        data = {...data, active: 1}
      }

      let obj = {
        ...data,
        active: data.active == true ? 1 : 0,
        customerID: _args || data?.Customerid || data?.customerID,
      }

      let res = null

      console.log('site selected 2:', obj)

      res = await _saveSite(obj)

      console.log('yy res:', res)
      if (Array.isArray(res.data) && (res.data || [])[0]?.msg == 'Ok') {
        console.log('test ok', JSON.parse(res.data[0].worksite)[0])
        dispatch(fetchSitesClient(data?.Customerid))
        dispatch(setSelectedSite(JSON.parse(res.data[0].worksite)[0]))
        return true
      } else if (Array.isArray(res.data) && (res.data || [])[0]?.msg === 'Already exist') {
        dispatch(setExistItem(true))
      }

      return false
    } catch (e) {
      console.log('error:', e.message)
      //   return { error: true, message: e.message }
      return false
    }
  }
)

export const removeSite = createAsyncThunk(`${name}/removeSite`, async (_arg, {dispatch}) => {
  console.log('remove site:', _arg)

  let res = await _removeSite(_arg?.id)

  console.log('res:', res)

  dispatch(fetchSitesClient(_arg.Customerid))
})
export const removeGeoFromSite = createAsyncThunk(
  `${name}/removeGeoFromSite`,
  async (_arg, {dispatch}) => {
    let res = await _removeSite(_arg?.id)

    console.log('res:', res)

    dispatch(fetchSites())
  }
)

export const addGeoToSite = createAsyncThunk(
  `${name}/addGeoToSite`,
  async (_arg, {getState, dispatch}) => {
    try {
      let {selectedSite} = getState()[name]
      console.log('selectedSite', selectedSite)
      console.log('_args', _arg)

      // let data = _.cloneDeep(selectedEnginTag)
      // console.log('tag add to engine data: ', data)
      let obj = {
        src: 'worksite',
        srcId: selectedSite.id, //worksite
        objId: _arg.id, //geofence
        obj: 'geofence',
      }

      let res = null

      res = await _relationAdd(obj)

      if (Array.isArray(res.data) && (res.data || [])[0]?.result === 'Ok') {
        dispatch(fetchGeoForSite(selectedSite.id))
        return true
      }
      return false
    } catch (e) {
      console.log('error:', e.message)
      //   return { error: true, message: e.message }
      return false
    }
  }
)

// export const removeGeoFromSite = createAsyncThunk(`${name}/removeSite`, async (_arg, {dispatch}) => {
//   let res = await _removeSite(_arg?.id)

//   console.log('res:', res)

//   dispatch(fetchSites())
// })

//Actions
export const setAddresses = createAction(`${name}/fetchAddresses`)
export const setEditAddress = createAction(`${name}/setEditAddress`)
export const setSites = createAction(`${name}/fetchSites`)
export const setSelectedSite = createAction(`${name}/setSelectedSite`)
export const setEditSite = createAction(`${name}/setEditSite`)
export const setExistItem = createAction(`${name}/setExistItem`)
export const setDetailSite = createAction(`${name}/setDetailSite`)
export const setLinkTo = createAction(`${name}/setLinkTo`)
export const setShowMap = createAction(`${name}/setShowMap`)
export const setListGeo = createAction(`${name}/setListGeo`)
export const setGeoSite = createAction(`${name}/setGeoSite`)
export const setGeoSiteSelectedSite = createAction(`${name}/setGeoSiteSelectedSite`)
export const setShowMapSite = createAction(`${name}/setShowMapSite`)
export const setSelectedGeoClient = createAction(`${name}/setSelectedGeoClient`)

const SiteSlice = createSlice({
  name,
  initialState: {
    addresses: [],
    editAddress: false,
    sites: [],
    listGeo: [],
    siteGeo: [],
    siteGeoSelected: [],
    selectedSite: null,
    selectedGeoEdit: null,
    showMap: false,
    showMapSite: false,
    editSite: false,
    detail: false,
    linkTo: false,
    alreadyExist: false,
  },
  reducers: {
    setSelectedGeoEdit(state, {payload}) {
      state.selectedGeoEdit = payload
    },
  },
  extraReducers: {
    [setAddresses]: (state, {payload}) => {
      state.addresses = payload
    },
    [setEditAddress]: (state, {payload}) => {
      state.editAddress = payload
    },
    [setSites]: (state, {payload}) => {
      state.sites = payload
    },
    [setSelectedSite]: (state, {payload}) => {
      state.selectedSite = payload
    },
    [setEditSite]: (state, {payload}) => {
      state.editSite = payload
    },
    [setExistItem]: (state, {payload}) => {
      state.alreadyExist = payload
    },
    [setDetailSite]: (state, {payload}) => {
      state.detail = payload
    },
    [setLinkTo]: (state, {payload}) => {
      state.linkTo = payload
    },
    [setListGeo]: (state, {payload}) => {
      state.listGeo = payload
    },
    [setGeoSite]: (state, {payload}) => {
      state.siteGeo = payload
    },
    [setGeoSiteSelectedSite]: (state, {payload}) => {
      state.siteGeoSelected = payload
    },
    [setShowMapSite]: (state, {payload}) => {
      state.showMapSite = payload
    },
    [setShowMap]: (state, {payload}) => {
      state.showMap = payload
    },
  },
})

//selectors
export const getAddresses = (state) => state[name].addresses
export const getEditAddress = (state) => state[name].editAddress
export const getSites = (state) => state[name].sites
export const getSelectedSite = (state) => state[name].selectedSite
export const getEditSite = (state) => state[name].editSite
export const getShowMap = (state) => state[name].showMap
export const getAlreadyExist = (state) => state[name].alreadyExist
export const getDetailSite = (state) => state[name].detail
export const getLinkTo = (state) => state[name].linkTo
export const getListGeo = (state) => state[name].listGeo
export const getGeoSite = (state) => state[name].siteGeo
export const getGeoSiteSelectedSite = (state) => state[name].siteGeoSelected
export const getShowMapSite = (state) => state[name].showMapSite
export const getSelectedGeoEdit = (state) => state[name].selectedGeoEdit

export const {setSelectedGeoEdit} = SiteSlice.actions

export default SiteSlice.reducer
