import {useNavigate} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import {removeInvoice, setSelectedInvoice} from '../../Invoices/slice/invoice.slice'
import {getCustomers} from '../../../store/slices/customer.slice'
import {getStatus} from '../../Tag/slice/tag.slice'
import {Chip} from 'primereact/chip'
import {SplitButton} from 'primereact/splitbutton'
import {DatatableComponent} from '../../shared/DatatableComponent/DataTableComponent'
import {memo, useEffect, useState} from 'react'
import {SpeedDial} from 'primereact/speeddial'
import {Button} from 'react-bootstrap'
import {Dropdown} from 'primereact/dropdown'
import {
  getElementsFacture,
  getFactures,
  getOptionsConfirmation,
  getOptionsValid,
  getSelectedFacture,
  getSelectedOptionsValid,
  setElementsFacture,
  setFactures,
  setSelectedFacture,
  setSelectedOptionsValid,
} from '../slice/facture.slice'
import {DialogComponent} from '../../shared/DialogComponent/DialogComponent'
import ButtonComponent from '../../shared/ButtonComponent'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {OlangItem} from '../../shared/Olang/user-interface/OlangItem/OlangItem'
const FacturationList = () => {
  const [valueConfirmation, setValueConfirmation] = useState(null)
  const [valueValid, setValueValid] = useState(null)
  const [visible, setVisible] = useState(false)
  const [visibleValidation, setVisibleValidation] = useState(false)
  const [visibleComment, setVisibleComment] = useState(false)
  const [comment, setComment] = useState('')
  const [isDisabled, setIsDisabled] = useState(false)
  const [archive, setArchive] = useState(true)
  const [isFournisseur, setIsFournisseur] = useState(false)
  const [isClient, setIsClient] = useState(true)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  let factures = useAppSelector(getFactures)
  let optionsValid = useAppSelector(getOptionsValid)
  let selectedOptionsValid = useAppSelector(getSelectedOptionsValid)
  let selectedFacture = useAppSelector(getSelectedFacture)
  let optionsConfirmation = useAppSelector(getOptionsConfirmation)
  let elementsFacture = useAppSelector(getElementsFacture)

  const optionNonAutorise = [
    {
      id: 3,
      name: 'Action Non Autorisé',
      value: false,
    },
  ]

  const onHide = () => {
    setVisible(false)
    setVisibleComment(false)
    setVisibleValidation(false)
  }
  const save = () => {
    const updatedFactures = factures.map((facture) => {
      if (facture.identifiant === selectedFacture.identifiant) {
        return {
          ...facture,
          isValid: valueValid,
        }
      }
      return facture
    })
    dispatch(setFactures(updatedFactures))
    //setIsDisabled(true)
    setVisible(false)
    setComment('')
    setVisibleComment(valueValid === false)
  }

  const saveConfirmation = () => {
    const updatedFactures = factures.map((facture) => {
      if (facture.identifiant === selectedFacture.identifiant) {
        return {
          ...facture,
          validationFournisseur: valueConfirmation,
        }
      }
      return facture
    })
    dispatch(setFactures(updatedFactures))
    //setIsDisabled(valueValid)
    setVisibleValidation(false)
  }

  const saveComment = () => {
    const updatedFactures = factures.map((facture) => {
      if (facture.identifiant === selectedFacture.identifiant) {
        return {
          ...facture,
          comment: comment,
        }
      }
      return facture
    })
    dispatch(setFactures(updatedFactures))
    setVisibleComment(false)
  }

  const footer = (
    <div>
      <ButtonComponent label='Annuler' className='p-button-danger' onClick={onHide} />
      <ButtonComponent label='Enregistrer' onClick={save} />
    </div>
  )

  const footerValidation = (
    <div className='flex justify-content-start'>
      <ButtonComponent label='Annuler' className='p-button-danger' onClick={onHide} />
      <ButtonComponent label='Enregistrer' onClick={saveConfirmation} />
    </div>
  )
  const footerComment = (
    <div className='flex justify-content-start'>
      <ButtonComponent label='Annuler' className='p-button-danger' onClick={onHide} />
      <ButtonComponent label='Enregistrer' onClick={saveComment} />
    </div>
  )

  const addComment = (rowData) => {
    setComment(rowData.comment)
    setVisibleComment(true)
    dispatch(setSelectedFacture(rowData))
  }

  const handleChange = (e, rowData) => {
    if (isClient) {
      setValueValid(e.value)
      setVisible(true)
      dispatch(setSelectedFacture(rowData))
    }
  }

  const handleChangeValidation = (e, rowData) => {
    if (isFournisseur) {
      setValueConfirmation(e.value)
      dispatch(setSelectedFacture(rowData))
      setVisibleValidation(true)
    }
  }

  const addresseeTemplate = (rowData) => {
    return (
      <>
        <Chip
          label={rowData.date}
          className='w-7rem m-1 flex justify-content-center align-items-center'
        />
      </>
    )
  }

  const activeTemplate = (rowData) => {
    console.log('validation', rowData.validationFournisseur)
    return (
      <div>
        {rowData.validationFournisseur === 'pas' ? (
          <Dropdown
            value={rowData.validationFournisseur}
            onChange={(e) => handleChangeValidation(e, rowData)}
            options={isFournisseur ? optionsConfirmation : optionNonAutorise}
            optionLabel='name'
            placeholder={isFournisseur ? 'pas de status' : 'Action Non Autorisé'}
            className='w-full md:w-10.3rem text-red-500 '
          />
        ) : rowData.validationFournisseur == true ? (
          <Chip
            label='valide'
            icon='pi pi-check'
            className='bg-green-500'
            style={{color: `${rowData.color}`}}
          />
        ) : (
          <Chip
            label='non valide'
            icon='pi pi-times'
            className='bg-red-500'
            style={{color: `${rowData.color}`}}
          />
        )}
      </div>
    )
  }
  const actionTemplate = (rowData) => {
    actions = actions.map((_i) => ({..._i, data: rowData}))
    return (
      <div class='dropdown'>
        <button
          class='btn btn-secondary dropdown-toggle'
          type='button'
          data-bs-toggle='dropdown'
          aria-expanded='false'
        ></button>
        <ul class='dropdown-menu'>
          {actions.map((i) => (
            <li>
              <a class='dropdown-item' href='#'>
                {i.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  }

  const actionValidate = (rowData) => {
    return (
      <div>
        {rowData.isValid === null ? (
          <Dropdown
            value={rowData.isValid}
            onChange={(e) => handleChange(e, rowData)}
            options={isClient ? optionsValid : optionNonAutorise}
            optionLabel='name'
            placeholder={isClient ? 'pas de status' : 'Action Non Autorisé'}
            className='w-full md:w-10.3rem'
            onClick={() => dispatch(setSelectedFacture(rowData))}
          />
        ) : rowData.isValid == true ? (
          <Chip
            label='valide'
            icon='pi pi-check'
            className='bg-green-500'
            style={{color: `${rowData.color}`}}
            onClick={() => dispatch(setSelectedFacture(rowData))}
          />
        ) : rowData.isValid == false ? (
          <div>
            <Chip
              label='non valide'
              icon='pi pi-file-edit'
              className='bg-red-500'
              style={{color: `${rowData.color}`}}
              onClick={isClient ? () => addComment(rowData) : null}
            />{' '}
            <p>
              <Chip
                label={rowData.comment.substring(0, 15)}
                className='w-7rem m-1 flex justify-content-center align-items-center'
              />
            </p>
          </div>
        ) : (
          <Chip
            label={rowData?.status}
            icon='pi pi-times'
            className='bg-red-500'
            style={{color: `${rowData.color}`}}
            onClick={() => dispatch(setSelectedFacture(rowData))}
          />
        )}
      </div>
    )
  }

  const columns = [
    {
      header: 'Date',
      olang: 'Date',
      field: 'date',
      body: addresseeTemplate,
    },
    {
      header: 'Tourne',
      field: 'nTourne',
      olang: 'Tourne',
    },
    {
      header: 'Identifiant',
      field: 'identifiant',

      olang: 'Identifiant',
    },
    {
      header: 'Prestation',
      field: 'prestation',
      olang: 'Prestation',
    },
    {
      header: 'Adresse de changement',
      field: 'changeAdr',
      olang: 'Adresse de changement',
    },
    {
      header: 'Adresse de livraison',
      field: 'livraisonAdr',
      olang: 'Adresse de livraison',
    },
    {
      header: 'Formule',
      field: 'formule',
      olang: 'Formule',
    },
    {
      header: 'Tarif',
      olang: 'Tarif',
      field: 'tarif',
    },
  ]

  if (!isClient) {
    columns.push({
      header: 'Validation Fournisseur',
      olang: 'Validation Fournisseur',
      field: 'validationFournisseur',
      body: activeTemplate,
    })
  } else {
    columns.push({
      field: null,
      header: 'Validation Client',
      body: actionValidate,
    })
  }

  let actions = [
    {
      label: 'Supprimer',
      icon: 'pi pi-trash text-red-500',
      command: (e) => {
        //dispatch(setSelectedInvoice(e.item.data))
        //dispatch(removeInvoice(e.item.data))
      },
    },
    {
      label: 'Detail',
      icon: 'pi pi-eye text-blue-500',
      command: (e) => {
        //dispatch(setSelectedInvoice(e.item.data))
        //navigate('/detailsTest')
      },
    },

    ...(archive
      ? [
          {
            label: 'Transfere',
            icon: 'pi pi-share-alt text-blue-500',
            command: (e) => {
              dispatch(setElementsFacture(e.item.data))
            },
          },
        ]
      : []),
  ]

  console.log('elementsFactures', elementsFacture)

  const exportFields = [
    {label: 'Date', column: 'date'},
    {label: 'Tourne', column: 'nTourne'},
    {label: 'Identifiant', column: 'identifiant'},
    {label: 'Prestation', column: 'prestation'},
    {label: 'Adresse de changement', column: 'changeAdr'},
    {label: 'Adresse de livraison', column: 'livraisonAdr'},
    {label: 'Formule', column: 'formule'},
    {label: 'Tarif', column: 'tarif'},
    {label: 'Validation', column: 'validation'},
    {label: 'Remarque', column: 'remarque'},

    {label: 'Validation Client', column: 'validationClient'},
  ]

  const rowGroupTemplates = {
    Nom: (rowData) => <Chip label={rowData?.code} />,
  }

  return (
    <div>
      {/* <h3 className='card text-white bg-primary p-5 my-4'>
          <OlangItem className='card-title' olang='Factures.par.client' />
        </h3> */}
      {/* <div className='flex align-items-center mb-1'>
          <Dropdown
            filter
            value={data}
            options={dropdownOptions}
            onChange={handleCustomerChange}
            placeholder='Select a Costumer'
            className='w-full md:w-14rem m-2'
          />
          
        </div> */}

      <DialogComponent visible={visible} footer={footer} onHide={onHide}>
        <div>
          <h5>
            <OlangItem olang='Voulez Vraiment Enregistrer?' />
          </h5>
        </div>
      </DialogComponent>

      <DialogComponent visible={visibleValidation} footer={footerValidation} onHide={onHide}>
        <div>
          <h5>
            <OlangItem olang='Voulez Vraiment Enregistrer?' />
          </h5>
        </div>
      </DialogComponent>

      <DialogComponent visible={visibleComment} footer={footerComment} onHide={onHide}>
        <div>
          <h5>Ajouter une Remarque</h5>
          {/* <InputText value={comment}  onChange={(e) => setComment(e.target.value)} className="w-8 mt-2" /> */}
          <InputTextarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            rows={5}
            cols={80}
            className='w-8 mt-4'
          />
        </div>
      </DialogComponent>

      <div className='title'>
        <p className='text-4xl'>Element Facturation</p>
      </div>

      <DatatableComponent
        exportFields={exportFields}
        tableId='facturation-table'
        data={factures}
        columns={columns}
        rowGroupTemplates={rowGroupTemplates}
        contextMenuModel={actions}
        rowActions={actions}
        //onSelections={}
      />
    </div>
  )
}

export default memo(FacturationList)
