import React, {useState} from 'react'
import {DialogComponent} from '../../../shared/DialogComponent'
import {OlangItem} from '../../../shared/Olang/user-interface/OlangItem/OlangItem'
import {Dropdown} from 'primereact/dropdown'
import ButtonComponent from '../../../shared/ButtonComponent'
import {useAppDispatch, useAppSelector} from '../../../../hooks'
import {getTags, getTagsFree} from '../../../Tag/slice/tag.slice'
import {getTagVisible, setTagVisible} from '../../slice/team.slice'

const TagListDrop = () => {
  const [selectedTag, setSelectedTag] = useState(null)

  const tags = useAppSelector(getTagsFree)
  const visible = useAppSelector(getTagVisible)

  const dispatch = useAppDispatch()

  console.log('tagsDrop', tags)

  const onHide = () => {
    dispatch(setTagVisible(false))
  }

  const save = () => {}

  const footer = (
    <div>
      <ButtonComponent label='Annuler' className='p-button-danger' onClick={onHide} />
      <ButtonComponent label='Enregistrer' onClick={save} />
    </div>
  )

  return (
    <DialogComponent visible={visible} footer={footer} header='Select Tag' onHide={onHide}>
      <div className='my-3'>
        <label className='my-2'>
          <OlangItem olang='Tag.Disponible' />
        </label>
        <Dropdown
          filter
          name='tags-dropdown'
          value={selectedTag}
          options={tags}
          optionLabel='label'
          optionValue='id'
          onChange={(e) => setSelectedTag(e.value)}
          placeholder='select Tag'
          className='w-full'
        />
      </div>
    </DialogComponent>
  )
}

export default TagListDrop
