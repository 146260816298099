import FullCalendar from '@fullcalendar/react'
import timelineGridPlugin from '@fullcalendar/resource-timeline'
import frLocal from '@fullcalendar/core/locales/fr'
import React, {useEffect, useRef, useState} from 'react'
import {useAppDispatch, useAppSelector} from '../../../../hooks'
import './style.css'
import {
  fetchEngines,
  fetchEnginesWorksite,
  getEngines,
  getEnginesWorksite,
  getSelectedEngine,
  setSelectedEngine,
} from '../../../Engin/slice/engin.slice'
import {
  fetchEngineEvents,
  fetchEngineEventsWorksite,
  getEngineEvents,
  getEngineEventsWorksite,
} from '../../slice/planing.slice'
import {RadioButton} from 'primereact/radiobutton'
import {Chip} from 'primereact/chip'
import EnginMapLocation from '../../../Engin/EnginList/EnginMapLocation'
import {Dropdown} from 'primereact/dropdown'
import {OlangItem} from '../../../shared/Olang/user-interface/OlangItem/OlangItem'
import {InputText} from 'primereact/inputtext'
import {API_BASE_URL_IMAGE} from '../../../../api/config'
import {Image} from 'primereact/image'
import {fetchDepots, getDepots} from '../../../depot/slice/depot.slice'
import {Button} from 'primereact/button'
import {SplitButton} from 'primereact/splitbutton'
import {useLocalStorage} from 'primereact/hooks'
import {Tag} from 'primereact/tag'

function CalendarView() {
  const [mouvement, setMouvement] = useState('')
  const [calendarKey, setCalendarKey] = useState(0) //new
  const [key, setKey] = useState(0)
  const [daysDisplay, setDaysDisplay] = useLocalStorage(2, 'daysDisplayFull')
  const [statusFilter, setStatusFilter] = useState('all')
  const [typeFilter, setTypeFilter] = useState('all')
  const [movementFilter, setMovementFilter] = useState('all')
  const [searchText, setSearchText] = useState('')
  let selectedEngin = useAppSelector(getSelectedEngine)
  const IMAGE_BASE_URL = API_BASE_URL_IMAGE
  const calendarRef = useRef(null)
  const [option, setOption] = useState('engin')
  const [dialogVisible, setDialogVisible] = useState(false)
  const handleSetOption = (value) => {
    setOption(value)
    //setKey((prevKey) => prevKey + 1)
  }

  let engines = useAppSelector(getEngines)
  let worksites = useAppSelector(getEnginesWorksite)
  const dispatch = useAppDispatch()
  let engineevents = useAppSelector(getEngineEvents)
  let engineeventsworksite = useAppSelector(getEngineEventsWorksite)
  const depots = useAppSelector(getDepots)

  console.log('engine events', engineevents)
  console.log('worksite events', engineeventsworksite)
  console.log('xx beforeWorksites', worksites)
  worksites =
    Array.isArray(worksites) && worksites?.length > 0
      ? worksites?.filter((item) => item?.etatenginname !== 'nonactive')
      : [] //******
  console.log('xx filterWorksites', worksites)
  useEffect(() => {
    dispatch(fetchDepots())
    dispatch(fetchEngines({LocationObject: 'engin', LocationID: 0}))
    dispatch(fetchEnginesWorksite({LocationObject: 'worksiteEvents', LocationID: 0}))
    dispatch(fetchEngineEvents({LocationObject: 'engin', LocationID: 0}))
    dispatch(fetchEngineEventsWorksite({LocationObject: 'worksite', LocationID: 0})) //changé
    //setKey((prevKey) => prevKey + 1)
  }, [])

  const handleShowMap = (rowData, srcMouv = '') => {
    setMouvement(srcMouv)
    dispatch(setSelectedEngine(rowData))
    setDialogVisible(true)
  }

  const iconTemplate = (rowData) => {
    let icon = ''
    let color = ''
    if (rowData?.etatenginname === 'exit') {
      icon = 'fa-solid fa-up-from-bracket'
      color = '#D64B70'
    } else if (rowData?.etatenginname === 'reception') {
      icon = 'fa-solid fa-down-to-bracket'
      color = 'green'
    } else if (rowData?.etatenginname === 'nonactive') {
      icon = 'fa-solid fa-octagon-exclamation'
      color = 'red'
    }
    return (
      <div>
        <i
          style={{color}}
          className={`${icon} text-base rounded p-2 cursor-pointer`}
          title={`${rowData?.etatengin} ${rowData?.locationDate || 'No Date'}`}
          alt={`${rowData?.etatengin} ${rowData?.locationDate || 'No Date'}`}
          onClick={() => handleShowMap(rowData, 'entry_exit')}
        ></i>
      </div>
    )
  }

  const familleTagTemplate = (rowData) => {
    return (
      <Tag
        // className='mr-2'
        icon={rowData.familleIconTag}
        value={rowData.familleTag}
        title={rowData.tagId != 0 ? `${rowData?.tagname}  ${rowData?.tagDate}` : 'No Tag'}
      ></Tag>
      // <Chip
      //   label={rowData.familleTag}
      //   title={rowData.tagId != 0 ? `Tagged  ${rowData?.tagDate}` : 'No Tag'}
      //   alt={rowData.tagId != 0 ? `Tagged  ${rowData?.tagDate}` : 'No Tag'}
      //   icon={rowData.familleIconTag}
      //   style={{background: rowData.familleTagIconBgcolor, color: rowData.familleTagIconColor}}
      //   className='cursor-pointer text-base'
      //   onClick={() => handleShowMap(rowData, '')}
      // />
    )
  }

  const tagTemplate = (rowData) => {
    return (
      <div className='flex flex-column'>
        <div className='flex justify-content-center'>
          {rowData.tagId ? (
            familleTagTemplate(rowData)
          ) : (
            <Chip
              label='Untagged'
              className='cursor-pointer'
              onClick={() => handleShowMap(rowData, '')}
            />
          )}
        </div>
      </div>
    )
  }

  const statusTemplate = (rowData) => {
    if (rowData?.iconName) {
      return (
        <i
          title={rowData?.statuslabel}
          className={`${rowData?.iconName} text-base rounded p-2`}
          style={{color: `${rowData.statusbgColor}`}}
        ></i>
      )
    }
    return (
      <Chip
        label={rowData?.statuslabel}
        style={{background: `${rowData.statusbgColor}`, color: rowData.color ?? 'white'}}
        title={`${rowData?.statusDate}`}
      />
    )
  }

  const worksitelistMap = worksites.reduce((acc, worksite) => {
    acc[worksite.id.toString()] = worksite
    return acc
  }, {})

  const updatedWorksiteListEvents = engineeventsworksite?.map((event) => ({
    ...event,
    image: worksitelistMap[event.resourceId]?.image,
    cost: worksitelistMap[event.resourceId]?.cost,
    tagId: worksitelistMap[event.resourceId]?.tagId,
    status: worksitelistMap[event.resourceId]?.statuslabel,
    statusColor: worksitelistMap[event.resourceId]?.statusColor,
    statusbgColor: worksitelistMap[event.resourceId]?.statusbgColor,
    currency: worksitelistMap[event.resourceId]?.currency,
    etatenginname: worksitelistMap[event.resourceId]?.etatenginname,
    label: worksitelistMap[event.resourceId]?.label,
    vin: worksitelistMap[event.resourceId]?.vin,
  }))

  let events = option === 'engin' ? engineevents : updatedWorksiteListEvents
  let ressources = option === 'engin' ? engines : worksites

  const uniqueStatusOptions = () => {
    if (!Array.isArray(ressources)) return []
    return [...new Set(ressources?.map((resource) => resource.statuslabel))]
  }

  const uniqueTypeOptions = () => {
    if (!Array.isArray(ressources)) return []
    return [...new Set(ressources?.map((resource) => resource.types))]
  }

  const handlePrevClick = () => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.prev()
  }

  // Custom handler for the "next" button
  const handleNextClick = () => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.next()
  }

  // const uniqueTypeOptions = [...new Set(ressources?.map((resource) => resource.types))]

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.value)
    //setKey((prevKey) => prevKey + 1)
  }

  const handleTypeFilterChange = (e) => {
    setTypeFilter(e.value)
    //setKey((prevKey) => prevKey + 1)
  }

  const handleMovementFilterChange = (e) => {
    setMovementFilter(e.target.value)
    //setKey((prevKey) => prevKey + 1)
  }

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value)
    //setKey((prevKey) => prevKey + 1)
    // Add any additional logic you need
  }

  const filteredRessources =
    Array.isArray(ressources) &&
    ressources.length > 0 &&
    ressources?.filter((resource) => {
      const statusFilterMatch = statusFilter === 'all' || resource.statuslabel === statusFilter
      const typeFilterMatch = typeFilter === 'all' || resource.types === typeFilter
      const movementFilterMatch =
        movementFilter === 'all' || resource.etatenginname === movementFilter
      const searchTextMatch =
        resource?.title?.toLowerCase().includes(searchText?.toLowerCase()) ||
        resource?.statuslabel?.toLowerCase().includes(searchText?.toLowerCase()) ||
        resource?.etatengin?.toLowerCase().includes(searchText?.toLowerCase()) ||
        (resource?.tagId == 0
          ? 'No Tag'.toString()?.toLowerCase().includes(searchText?.toLowerCase())
          : resource?.tagId.toString()?.toLowerCase().includes(searchText?.toLowerCase())) ||
        resource?.vin?.toLowerCase().includes(searchText?.toLowerCase())

      return statusFilterMatch && typeFilterMatch && movementFilterMatch && searchTextMatch
    })

  const familleTemplate = ({famille, familleIcon, familleBgcolor, familleColor}) => {
    return (
      <Tag
        value={famille}
        icon={familleIcon}
        style={{background: familleBgcolor, color: 'white'}}
      />

      // <Chip
      //   label={famille}
      //   icon={familleIcon}
      //   style={{background: familleBgcolor, color: 'white'}}
      // />
    )
  }

  const renderEnginsRessources = [
    {
      field: 'title',
      headerContent: <OlangItem olang='engin.list' />,
      cellContent: (r) => {
        console.log('r', r.resource._resource.extendedProps)
        const {image, tagId, label, reference, tagname, famille, familleIcon, familleBgcolor, vin} =
          r.resource._resource.extendedProps || {}

        return (
          <>
            {window.innerWidth > 768 ? (
              <div className=''>
                <div className='d-flex'>
                  <div className=''>
                    <Image
                      src={`${IMAGE_BASE_URL}${image}`}
                      alt='EngineImage'
                      width='60'
                      height='60'
                      preview
                      imageStyle={{objectFit: 'cover', borderRadius: '10px'}}
                    />
                  </div>
                  <div className=''>
                    <div className='flex flex-column mx-2'>
                      <div className='event-title font-semibold text-lg font-medium'>
                        {reference}
                      </div>
                      <div className='text-xs'>{tagname}</div>
                      {/* Removed commented code */}
                      <div className='flex flex-column xl:flex-row lg:flex-row align-items-center'>
                        <div className='flex flex-wrap xl:flex-row lg:flex-row gap-1'>
                          {/* Uncomment and add your templates here if needed */}
                          <div className='engin-icon-content'>
                            {iconTemplate(r.resource._resource.extendedProps)}
                          </div>
                          <div className='status-content'>
                            {statusTemplate(r.resource._resource.extendedProps)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  maxWidth: '90%',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}
              >
                <strong className='text-xs'>{reference}</strong>
              </div>
            )}
          </>
        )
      },
    },
  ]

  const renderWorksitesRessources = [
    {
      field: 'title',
      headerContent: (
        <p>
          <OlangItem olang='WorksiteList' />
        </p>
      ),
      cellContent: (r) => {
        return <strong>{r.fieldValue} </strong>
      },
    },
  ]

  const renderRessources = option === 'engin' ? renderEnginsRessources : renderWorksitesRessources

  useEffect(() => {
    setCalendarKey((prevKey) => prevKey + 1)
  }, [statusFilter, typeFilter, movementFilter, searchText, option]) // new

  return (
    <>
      <div className=''>
        <div className='flex align-items-center flex-wrap gap-3'>
          <div className='flex flex-row'>
            <Button
              style={{height: '40px'}}
              icon='pi pi-angle-left'
              onClick={handlePrevClick}
            ></Button>
            <Button
              style={{height: '40px'}}
              className='ml-2'
              icon='pi pi-angle-right'
              onClick={handleNextClick}
            ></Button>
            <SplitButton
              style={{height: '40px'}}
              icon='pi pi-calendar'
              className='ml-2'
              model={[
                {label: '1 jour', command: () => setDaysDisplay(1)},
                {label: '2 jours', command: () => setDaysDisplay(2)},
                {label: '5 jours', command: () => setDaysDisplay(5)},
                {label: '10 jours', command: () => setDaysDisplay(10)},
              ]}
              onClick={() => setDaysDisplay(1)}
            ></SplitButton>
          </div>
          <div className='flex align-items-center'>
            <RadioButton
              inputId='Engin'
              name='engin'
              value='engin'
              onChange={(e) => handleSetOption(e.value)}
              checked={option === 'engin'}
            />
            <label htmlFor='Engin' className='ml-2'>
              Engin
            </label>
          </div>
          <div className='flex align-items-center'>
            <RadioButton
              inputId='Worksite'
              name='worksite'
              value='worksite'
              onChange={(e) => handleSetOption(e.value)}
              checked={option === 'worksite'}
            />
            <label htmlFor='Worksite' className='ml-2'>
              Worksite
            </label>
          </div>
          <div className='flex align-items-center my-4'>
            <div className='flex align-items-center'>
              <InputText
                id='searchInput'
                type='text'
                placeholder='search'
                onChange={handleSearchTextChange}
              />
            </div>
          </div>
          {option === 'engin' && (
            <div className='flex space-x-4 items-center my-4'>
              <div className='flex items-center mx-1'>
                <div className='inputgroup p-inputgroup flex-1'>
                  <span className='p-inputgroup-addon'>
                    <OlangItem olang='Status' />
                  </span>
                  <Dropdown
                    id='statusFilter'
                    value={statusFilter}
                    options={[
                      {label: 'All', value: 'all'},
                      ...(Array.isArray(uniqueStatusOptions)
                        ? uniqueStatusOptions.map((status) => ({
                            label: status,
                            value: status,
                          }))
                        : []),
                    ]}
                    onChange={handleStatusFilterChange}
                  />
                </div>
              </div>
              <div className='flex items-center mx-1'>
                <div className='inputgroup p-inputgroup flex-1'>
                  <span className='p-inputgroup-addon'>
                    <OlangItem olang='Mouvement' />
                  </span>
                  <Dropdown
                    id='movementFilter'
                    value={movementFilter}
                    options={[
                      {label: 'All', value: 'all'},
                      {label: 'Entrée', value: 'reception'},
                      {label: 'Sortie', value: 'exit'},
                    ]}
                    onChange={handleMovementFilterChange}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <FullCalendar
        key={calendarKey}
        ref={calendarRef}
        height={'800px'}
        eventBackgroundColor='#F2F2F2'
        eventBorderColor='#67529D'
        eventTextColor='black'
        aspectRatio={3.5}
        plugins={[timelineGridPlugin]}
        headerToolbar={{
          left: 'today',
          center: 'title',
          right: 'resourceTimelineTenDays',
        }}
        initialView={'resourceTimelineTenDays'}
        views={{
          resourceTimelineTenDays: {
            type: 'resourceTimeline',
            duration: {days: daysDisplay},
            buttonText: `${daysDisplay} days`,
          },
        }}
        // editable={true}
        // droppable={true}
        resources={filteredRessources}
        resourceAreaWidth={window.innerWidth < 768 ? '30%' : '13%'}
        resourceAreaColumns={renderRessources}
        eventMinWidth={5}
        events={events}
        eventContent={(r) => {
          return (
            <div
              style={{height: '30px'}}
              className='p-2 w-12 w-12 flex align-items-center justify-content-end  fc-event-title-container  shadow-2 bg-blue-600 text-white text-end'
              title={r.event._def.title}

              // title={r.event._def.title}
            >
              <span> {r.event._def.title}</span>
            </div>
          )
        }}
        locales={[frLocal]}
        locale='fr'
      />
      <EnginMapLocation
        dialogVisible={dialogVisible}
        setDialogVisible={() => setDialogVisible((prev) => !prev)}
        historySrc={{
          srcId: selectedEngin?.uid,
          srcObject: 'engin',
          srcMovement: mouvement,
        }}
      />
    </>
  )
}

export default CalendarView
