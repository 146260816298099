import { useState, useEffect } from 'react'

export const useWindowResize = () => {
    const [windowSize, setWindowSize] = useState({ width: window.innerWidth, height: window.innerHeight })

    useEffect(() => {
        const handleResize = _ => setWindowSize({ width: window.innerWidth, height: window.innerHeight })

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })
    return windowSize
}
