import React from 'react'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import {
  fetchSitesClient,
  getDetailSiteClient,
  getSelectedSiteClient,
  setDetailSiteClient,
  setSelectedSiteClient,
} from '../../../store/slices/customer.slice'
import SiteList from '../../Site/user-interface/SiteList/SiteList'
// import SiteDetail from '../../Site/user-interface/SiteDetail/SiteDetail'
// import SiteAndGeo from '../../Site/user-interface/SiteDetail/SiteAndGeo'
import SiteDetailWithLinks from '../../Site/user-interface/SiteEditor/SiteDetailWithLinks'
import ButtonComponent from '../../shared/ButtonComponent/ButtonComponent'
import {OlangItem} from '../../shared/Olang/user-interface/OlangItem/OlangItem'
import {
  getSelectedSite,
  setDetailSite,
  setGeoSite,
  setGeoSiteSelectedSite,
  setSelectedSite,
} from '../../Site/slice/site.slice'
import {Divider} from 'primereact/divider'

const SiteClientComponent = () => {
  const dispatch = useAppDispatch()
  let showDetail = useAppSelector(getDetailSiteClient)
  const selectedSiteClient = useAppSelector(getSelectedSite)
  console.log('selectedSiteClient', selectedSiteClient)

  console.log('showDetail', showDetail)

  // const onHide = () => {
  //   dispatch(setDetailSiteClient(false))
  //   dispatch(setSelectedSiteClient(null))
  // }

  const onHide = () => {
    dispatch(setGeoSite([]))
    dispatch(setGeoSiteSelectedSite([]))
    dispatch(setGeoSiteSelectedSite([]))
    dispatch(setDetailSiteClient(false))
    dispatch(setSelectedSite(null))
    //dispatch(fetchSitesClient())
  }

  return (
    <>
      {showDetail ? (
        <>
          <div className='flex flex-row justify-content-between align-items-center'>
            <div className='text-xl text-800 font-semibold'>
              <span>
                <OlangItem olang='Site' /> :
              </span>
              <span className='ml-1 text-2xl text-800  font-semibold'>
                {selectedSiteClient?.label}
              </span>
            </div>
            <ButtonComponent className='bg-red-600' onClick={onHide}>
              <i class='fa-solid fa-share fa-flip-horizontal text-white'></i>
              <div className='ml-2 text-base font-semibold'>
                <OlangItem olang='btn.back' />
              </div>
            </ButtonComponent>
          </div>
          <Divider />
          <SiteDetailWithLinks />
        </>
      ) : (
        <SiteList filter={true} detailView='Detail' client={true} />
      )}
    </>
  )
}

export default SiteClientComponent

{
  /* <SiteDetail selectedSite={selectedSiteClient} client={true} onShow={onHide} /> */
}
