import {createSlice, createAsyncThunk, createAction} from '@reduxjs/toolkit'
import {LOCATIONTAG as name} from '../../../store/slices/config'

export const setEnginsInside = createAction(`${name}/setEnginsInside`)
export const setEnginsOutSide = createAction(`${name}/setEnginsOutSide`)
export const setTagInside = createAction(`${name}/setTagInside`)
export const setTagOutSide = createAction(`${name}/setTagOutSide`)

const locationTagSlice = createSlice({
  name,
  initialState: {
    enginsInside: [],
    enginsOutSide: [],
    tagInside: [],
    tagOutSide: [],
  },
  reducers: {},
  extraReducers: {
    [setEnginsInside]: (state, {payload}) => {
      state.enginsInside = payload
    },
    [setEnginsOutSide]: (state, {payload}) => {
      state.enginsOutSide = payload
    },
    [setTagInside]: (state, {payload}) => {
      state.tagInside = payload
    },
    [setTagOutSide]: (state, {payload}) => {
      state.tagOutSide = payload
    },
  },
})

export const getEnginsInside = (state) => state[name].enginsInside
export const getEnginsOutSide = (state) => state[name].enginsOutSide
export const getTagInside = (state) => state[name].tagInside
export const getTagOutSide = (state) => state[name].tagOutSide

export default locationTagSlice.reducer
