import React, {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {
  getDetailChat,
  getDetailMessage,
  saveConversation,
  setDetailChat,
  setDetailMessage,
  userRead,
} from '../../slice/Chat.slice'
import {Divider} from 'primereact/divider'
import {Button} from 'primereact/button'
import {getCurrentUser} from '../../../../../../components/User/slice/user.slice'
import Message from './Message'
import {InputText} from 'primereact/inputtext'
import {InputTextarea} from 'primereact/inputtextarea'
import {useDispatch} from 'react-redux'
import {ScrollPanel} from 'primereact/scrollpanel'
import moment from 'moment'

const DetailMessage = () => {
  const [value, setValue] = useState('')
  const [focus, setFocus] = useState(false)

  const detailMsg = useSelector(getDetailMessage)
  const showMsg = useSelector(getDetailChat)
  const currentUser = useSelector(getCurrentUser)
  const dispatch = useDispatch()

  const messageContainerRef = useRef()

  const bottomRef = useRef()

  function sendMessage() {
    let currentDate = new Date()
    currentDate = moment(currentDate).format('LT')
    let obj = {
      id: Date.now().toString(),
      Object: detailMsg?.[0].Object || '',
      message: value,
      to: '',
      from: currentUser.userName,
      subject: detailMsg[0]?.Object,
      image: detailMsg[0]?.image,
      Read: 1,
      datecom: currentDate,
    }

    console.log('oooo:')

    dispatch(saveConversation(obj)).then(() => {
      if (showMsg) {
        let newMsg = {
          id: Date.now().toString(),
          Object: detailMsg?.[0].Object,
          message: value,
          to: '',
          from: currentUser.userName,
          datecom: obj.datecom,
        }
        const newDetailMsg = [...detailMsg, newMsg]
        dispatch(setDetailMessage(newDetailMsg))
        setValue('')
      }
    })
  }

  function onClose() {
    dispatch(userRead())
    dispatch(setDetailChat(false))
  }

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  useEffect(() => {
    if (messageContainerRef.current?.scrollHeight) {
      console.log(
        'messageContainerRef.current.scrollHeight:',
        messageContainerRef.current.scrollHeight
      )
      setTimeout(() => {
        messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight
      }, 1000)
    }
  }, [detailMsg, messageContainerRef.current?.scrollHeight])

  useEffect(() => {
    bottomRef.current.scrollIntoView()
  }, [detailMsg])

  return (
    <div className='w-full flex-1 relative'>
      <div className='w-full p-2 flex flex-row justify-content-between items-center'>
        <div className='text-2xl font-semibold'>{detailMsg?.[0].Object}</div>
        <Button
          onClick={onClose}
          icon='pi pi-times'
          rounded
          text
          severity='danger'
          aria-label='Cancel'
        />
      </div>
      <Divider />
      <ScrollPanel ref={messageContainerRef} style={{width: '100%', height: '70vh'}}>
        <div className='flex flex-column flex-1 p-3'>
          {detailMsg?.map((msg) => (
            <Message in={currentUser.userName === msg.from} id={msg.id} {...msg} />
          ))}
        </div>
        <div ref={bottomRef} />
      </ScrollPanel>
      <div
        style={{
          bottom: '30px',
          position: 'absolute',
          width: '100%',
          backgroundColor: 'white',
          zIndex: '5',
        }}
      >
        <Divider />
        <div
          className={`flex border-round-xl shadow-2 flex-wrap p-2 w-100 flex-row justify-content-between align-items-center gap-2  w-full border-2 ${
            focus ? 'border-blue-400' : 'border-blue-100'
          }`}
        >
          <InputTextarea
            value={value}
            onChange={(e) => setValue(e.target.value)}
            rows={focus ? 3 : 1}
            className={`${focus ? 'w-12' : 'w-9'} p-0 border-0 focus-no-border no-resize`}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            placeholder='Envoyer un message...'
            onKeyDown={onEnterPress}
          />
          <div
            className='flex gap-3 align-items-center justify-content-end px-2'
            style={{width: focus ? '100%' : '100px'}}
          >
            {focus && (
              <div>
                <span className='pi pi-paperclip'></span>
              </div>
            )}
            <Button
              size='small'
              icon='pi pi-send'
              label='Envoyer'
              severity='primary'
              onClick={sendMessage}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailMessage
