import React, {memo, useEffect, useRef, useState} from 'react'
import {
  FeatureGroup,
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  ZoomControl,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import HistoryList from '../../shared/HistoryComponent/HistoryList'
import GeofenceListSelectedSiteComponent from '../../Site/user-interface/SiteDetail/Map/List/GeofenceListSelectedSiteComponent'
import HistoryListComponent from '../../shared/HistoryComponent/HistoryListComponent'
import {Message} from 'primereact/message'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import {setAlertParams} from '../../../store/slices/alert.slice'
import {Toast} from 'primereact/toast'
import {getGeoByIdPos, getShowHistory, setSelectedHistory} from '../slice/engin.slice'
import CardHistory from './CardHistory'
import L from 'leaflet'
import * as turf from '@turf/turf'
import BaseMapLayerComponent from '../../shared/BaseMapLayerComponent/BaseMapLayerComponent'
function MapComponent(props) {
  const toast = useRef(null)
  const showHistoryList = useAppSelector(getShowHistory)
  const selectedGeoPos = useAppSelector(getGeoByIdPos)
  const [polygonCoordinates, setPolygonCoordinates] = useState(null)

  const [zoom, setZoom] = useState(20)
  const ref = useRef(null)
  const geometryFeature = useRef()
  const dispatch = useAppDispatch()
  const [center, setCenter] = useState({
    lat: props.position.last_lat || 46.8182,
    lng: props.position.last_lng || 8.2275,
  })
  const [enginCenter, setEnginCenter] = useState({
    lat: props.position.last_lat || 46.8182,
    lng: props.position.last_lng || 8.2275,
  })

  const onHandleOnClickLayer = (e, index) => {
    if (e.satlat == 0 || e.satlng == 0) {
      return
    }
    let latitude = +e?.satlat || +e.lat
    let longitude = +e?.satlng || +e.lng
    ref.current.setView({lat: latitude, lng: longitude}, 20) //{lat: +e.satlat, lng: +e.satlng}
    setEnginCenter({lat: latitude, lng: longitude})
    geometryFeature.current.clearLayers()
    // if (Array.isArray(e?.locationGeometry?.coordinates)) {
    //   if (!geometryFeature.current) return
    //   geometryFeature.current.clearLayers()
    //   let geoJson = {
    //     type: 'Feature',
    //     properties: {},
    //     geometry: e?.locationGeometry,
    //   }

    //   let feature = L.geoJson(geoJson)

    //   geometryFeature.current.addLayer(feature)
    //   ref.current.fitBounds(feature.getBounds())
    // } else {
    //   setPolygonCoordinates(null)
    // }
    dispatch(setSelectedHistory(index))
  }

  const calculateBounds = (polygon) => {
    const bounds = turf.bbox(polygon)
    const leafletBounds = L.latLngBounds([
      [bounds[1], bounds[0]],
      [bounds[3], bounds[2]],
    ])
    return leafletBounds
  }

  const calculateCenter = (polygon) => {
    const centroid = turf.centroid(polygon)
    return centroid.geometry.coordinates
  }

  const displayGeoLog = (selectedGeoPos) => {
    if (selectedGeoPos?.coordinates) {
      let geoJson = {
        type: 'Feature',
        properties: {},
        geometry: selectedGeoPos,
      }
      let feature = L.geoJson(geoJson)
      geometryFeature.current.addLayer(feature)
      ref.current.fitBounds(feature.getBounds())
    } else if (selectedGeoPos?.length) {
      const polygon = selectedGeoPos[0]?.geometry
      if (!geometryFeature.current) return
      geometryFeature.current.clearLayers()
      let polygonGeoJson = {
        type: 'Feature',
        properties: {},
        geometry: polygon?.geometry,
      }
      let feature = L.geoJson(polygonGeoJson)
      geometryFeature.current.addLayer(feature)
      const bounds = calculateBounds(polygon)
      const center = calculateCenter(polygon)
      if (ref.current && center) {
        ref.current.fitBounds(bounds, {padding: [50, 50], maxZoom: 24})
      }
    }
  }

  // useEffect(() => {

  // },[])
  useEffect(() => {
    displayGeoLog(selectedGeoPos)
  }, [selectedGeoPos])

  return (
    <div style={{display: 'flex', position: 'relative', width: '100%'}}>
      <div
        style={{
          position: 'absolute',
          width: '400px',
          height: '75vh',
          zIndex: 10,
          padding: '3px',
          top: '0.5rem',
          right: '0.5rem',
        }}
      >
        <HistoryListComponent
          mapRef={ref}
          allGeo={props.locationHistory}
          history={true}
          onDisplayGeo={(e) => {
            console.log('onDisplayGeo:', e)
          }}
          handleOnClickLayer={(e, index) => {
            console.log('handleOnClickLayer:', e)
            onHandleOnClickLayer(e, index)
          }}
        />
      </div>
      <MapContainer
        zoomControl={true} // Enable the default zoom control
        scrollWheelZoom={true}
        zoom={zoom}
        ref={ref}
        center={center}
        className=''
        style={{
          width: props.width ? props.width : '100%',
          height: props.height ? props.height : '70vh',
          ...(props?.mapStyle || {}),
        }}
      >
        {/* <ZoomControl  /> */}
        {/* <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy openstreetmap'
        /> */}
        <BaseMapLayerComponent top={60} right={15} />
        <FeatureGroup ref={geometryFeature} />
        {enginCenter?.lat != 0 && (
          <Marker position={enginCenter} icon={props.icon}>
            {props?.popupTitle === '' ? <Popup>No tag</Popup> : <Popup>{props.popupTitle}</Popup>}
          </Marker>
        )}
        {Array.isArray(polygonCoordinates) && <Polygon positions={polygonCoordinates} />}
      </MapContainer>
      <Toast ref={toast} />
    </div>
  )
}

export default MapComponent
