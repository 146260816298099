import _axios from './axios'
import {request as orequest, psCoreRequest} from '../../../../api'

async function request(url, params) {
  return await orequest(url, params, _axios)
}

export async function _fetchObject() {
  return await request('types/typeItemsList', {data: {src: 'StatusTypes'}})
}

export async function _fetchStatus(args) {
  console.log({ssss: args})
  return await request('types/trcStatusList', {data: {id: 0, src: args.name}})
}
