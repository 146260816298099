import {useEffect, useState, memo} from 'react'
import {Button} from 'primereact/button'
import {DatatableComponent} from '../../shared/DatatableComponent/DataTableComponent'
import {Chip} from 'primereact/chip'
import {Avatar} from 'primereact/avatar'
import {useAppDispatch, useAppSelector} from '../../../hooks'

import {OlangItem} from '../../shared/Olang/user-interface/OlangItem/OlangItem'
import {
  getEnginsInside,
  getEnginsOutSide,
  setEnginsInside,
  setEnginsOutSide,
} from '../slice/locationTag.slice'
import {fetchEngines, getEngines} from '../../Engin/slice/engin.slice'
import DetailEngin from './detailTag/DetailEngin'

const RFLocationTag = () => {
  const [dialogVisible, setDialogVisible] = useState(false)
  const [active, setActive] = useState()

  const dispatch = useAppDispatch()
  let allEngins = useAppSelector(getEngines)
  console.log('**rb all Engins', allEngins)

  const enginsInside = useAppSelector(getEnginsInside)
  const enginsOutSide = useAppSelector(getEnginsOutSide)

  console.log('**rb enginsInside', enginsInside)
  console.log('**rb enginsOutSide', enginsOutSide)

  const enginsCondition = [
    {label: 'In Warehouse', code: 'enginInWarehouse', quantity: enginsInside?.length},
    {label: 'With Client', code: 'enginWithClient', quantity: enginsOutSide?.length},
  ]

  const enginName = (rowData) => (
    <Chip
      label={<OlangItem olang={`${rowData?.code}`} />}
      icon={'pi pi-power-off'}
      style={{backgroundColor: rowData?.code == 'enginInWarehouse' ? '#523F8D' : '#D64B70'}}
      className='text-white'
    />
  )
  const enginQuantity = (rowData) => {
    return (
      <Avatar
        label={rowData?.quantity != 0 ? rowData?.quantity : '0'}
        style={{backgroundColor: rowData?.code == 'enginInWarehouse' ? '#523F8D' : '#D64B70'}}
        className='text-white text-2xl font-bold mr-2'
        size='large'
        shape='circle'
      />
    )
  }

  const showDetail = (e) => {
    setDialogVisible(true)
    setActive(e)
  }

  const buttonShow = (rowData) => {
    console.log('**rbrowData', rowData)
    return (
      <Button
        label={<OlangItem olang='Show' />}
        icon='pi pi-external-link'
        style={{backgroundColor: '#D64B70', color: 'white', border: '1px solid white'}}
        onClick={() => showDetail(rowData)}
        rounded
      />
    )
  }

  const columns = [
    {
      header: 'Status',
      field: 'code',
      olang: 'Status',
      body: enginName,
    },
    {
      header: 'Quantity',
      field: 'quantity',
      olang: 'Quantity',
      body: enginQuantity,
    },
    {
      header: 'Show',
      field: null,
      olang: 'Show',
      body: buttonShow,
    },
  ]

  const exportFields = ['code', 'quantity']

  useEffect(() => {
    dispatch(fetchEngines({}))
  }, [])

  useEffect(() => {
    dispatch(setEnginsInside(allEngins?.filter((it) => it.etatenginname == 'reception')))
    dispatch(setEnginsOutSide(allEngins?.filter((it) => it.etatenginname == 'exit')))
  }, [allEngins])

  return (
    <div className='card'>
      <div className='py-3 flex flex-row align-items-center'>
        <h1 className='text-700'>
          <OlangItem olang={'Situation.engintag'} />
        </h1>
      </div>
      <DetailEngin
        dialogVisible={dialogVisible}
        tags={allEngins}
        setDialogVisible={() => setDialogVisible((prev) => !prev)}
        active={active}
      />
      <DatatableComponent
        tableId='tagsLocation-table'
        data={enginsCondition}
        columns={columns}
        //exportFields={exportFields}
        onSelections={(r) => console.log('data:', r)}
      />
    </div>
  )
}

export default memo(RFLocationTag)
