import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {ELEMENTFACTURABLE as name} from '../../../store/slices/config'
import {_fetchInvoicePendingBilling, _saveInvoicePendingBilling} from '../api'
import _ from 'lodash'

export const fetchInvoicePendingBilling = createAsyncThunk(
  `${name}/fetchInvoicePendingBilling`,
  async (id = 0, {getState, dispatch}) => {
    let response = await _fetchInvoicePendingBilling(id)
    console.log('invoice pending billing list :  ', response)
    if (response.status === 200) dispatch(setInvoicePendingBilling(response.data))
  }
)

export const createOrUpdatePendingBilling = createAsyncThunk(
  `${name}/createOrUpdatePendingBilling`,
  async (_, { dispatch, getState }) => {
    try {
      console.log('args nn:', getState()[name].factureId);

      const selectedInvoices = getState()[name].selectedInvoices;
      const factureId = getState()[name].factureId;

      const successResponses = [];

      for (const invoice of selectedInvoices) {
        const obj = {
          id: parseInt(invoice.id || invoice.uid),
          customerId: parseInt(invoice.customerId),
          invoiceId: factureId,
        };

        console.log('object PendingBilling : ', obj);

        const res = await _saveInvoicePendingBilling(obj);
        console.log('yy res:', res);

        if (
          (Array.isArray(res.data) && ['added', 'updated'].includes(res.data[0]?.msg)) ||
          (Array.isArray(res.data) && res.data[0]?.msg === 'Existe déjà ')
        ) {
          successResponses.push(res.data);
        }
      }

      if (successResponses.length > 0) {
        dispatch(fetchInvoicePendingBilling());
        return true;
      }

      return false;
    } catch (e) {
      console.log('error:', e.message);
      return false;
    }
  }
);


//Actions
export const setInvoicePendingBilling = createAction(`${name}/fetchInvoicePendingBilling`)
export const setSelectedInvoices = createAction(`${name}/setSelectedInvoices`)
export const setFactureId = createAction(`${name}/setFactureId`)

const ElementFacturableSlice = createSlice({
  name,
  initialState: {
    invoicePendingBilling: [],
    selectedInvoices : null,
    factureId: null,
  },
  reducers: {},
  extraReducers: {
    [setInvoicePendingBilling]: (state, {payload}) => {
      state.invoicePendingBilling = payload
    },
    [setSelectedInvoices]: (state, {payload}) => {
      state.selectedInvoices = payload
    },
    [setFactureId]: (state , {payload}) => {
      state.factureId = payload;
    }
  },
})

export const getInvoicePendingBilling = (state) => state[name].invoicePendingBilling
export const getSelectedInvoices = (state) => state[name].selectedInvoices
export const getFactureId = (state) => state[name].factureId
export default ElementFacturableSlice.reducer
