import React from 'react'

const Message = (props) => {
  return (
    <div
      key={props.id}
      style={{alignItems: props.in ? 'flex-end' : 'flex-start'}}
      className='flex flex-column my-2'
    >
      <div
        style={{ minWidth: '50px', maxWidth: '90%'}}
        className={`inline-block p-3 border-round-xl ${props.in ? 'bg-purple-400' : 'bg-indigo-400'}`}
        //'#D64B70' : '#523F8D'
      >
        <div className='text-white text-xl'>{props.message}</div>
      </div>
      <div style={{alignSelf: props.in ? 'flex-end' : 'flex-start'}} className='flex flex-row p-1'>
        <div className='mr-2'>{props.from}</div>
        <div>{props.datecom}</div>
      </div>
    </div>
  )
}

export default Message
