import React from 'react'
import {OlangItem} from '../../shared/Olang/user-interface/OlangItem/OlangItem'
import CardHistoStatus from '../../shared/CardHistoStatus/CardHistoStatus'
import {DialogComponent} from '../../shared/DialogComponent'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import {getStatusVisible, setStatusVisible} from '../slice/engin.slice'
import {ScrollPanel} from 'primereact/scrollpanel'

const StatusHistoric = () => {
  const visible = useAppSelector(getStatusVisible)

  const dispatch = useAppDispatch()

  const onHide = () => {
    dispatch(setStatusVisible(false))
  }

  const data = [
    {
      stationName: 'Charger',
      chipLabel: 'Pt taille',
      user: 'Martin',
      date: '15/05/2022',
      vehicle: 'FORD',
      location: '116, Chemin de la Rueyre, Renens, Ouest lausannois, Suisse',
      status: {
        color: 'green-500',
        icon: 'fa-solid fa-check',
      },
    },
    {
      stationName: 'PowerHub',
      chipLabel: 'Large station',
      user: 'Alice',
      date: '22/07/2023',
      vehicle: 'TESLA',
      location: '45, Route de Genève, Nyon, Suisse',
      status: {
        color: 'yellow-500',
        icon: 'fa-solid fa-exclamation-circle',
      },
    },
    {
      stationName: 'FastCharge',
      chipLabel: 'Quick stop',
      user: 'John',
      date: '03/01/2021',
      vehicle: 'BMW',
      location: '78, Rue de Lausanne, Geneva, Suisse',
      status: {
        color: 'red-500',
        icon: 'fa-solid fa-ban',
      },
    },
    {
      stationName: 'EcoCharge',
      chipLabel: 'Eco-friendly',
      user: 'Sophie',
      date: '11/11/2023',
      vehicle: 'AUDI',
      location: '23, Boulevard des Tranchées, Geneva, Suisse',
      status: {
        color: 'blue-500',
        icon: 'fa-solid fa-info-circle',
      },
    },
    {
      stationName: 'SuperVolt',
      chipLabel: 'High Power',
      user: 'Chris',
      date: '30/09/2022',
      vehicle: 'MERCEDES',
      location: '10, Avenue de la Gare, Lausanne, Suisse',
      status: {
        color: 'purple-500',
        icon: 'fa-solid fa-bolt',
      },
    },
  ]

  return (
    <DialogComponent visible={visible} header='Engin 1' onHide={onHide}>
      <div className='my-3'>
        <label className='my-2'>
          <OlangItem olang='Status.Historic' />
        </label>
        <ScrollPanel style={{width: '100%', height: '500px'}}>
          <div className='flex flex-column gap-3'>
            {Array.isArray(data) &&
              data.length > 0 &&
              data.map((item, index) => (
                <CardHistoStatus
                  key={index}
                  status={item.stationName}
                  date={item.date}
                  iconStatus={item.chipLabel}
                  user={item.user}
                  address={item.location}
                  statusColor={item.status.color}
                  statusIcon={item.status.icon}
                  stat={item.vehicle}
                />
              ))}
          </div>
        </ScrollPanel>
      </div>
    </DialogComponent>
  )
}

export default StatusHistoric
