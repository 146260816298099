import {FormattedDisplayName} from 'react-intl'
import {
  setChatMessages,
  setUserRead,
} from '../_metronic/partials/layout/drawer-messenger/slice/Chat.slice'
import {
  setLastEnginUpdates,
  setSocketEnterOrExit,
  setSocketStatusChange,
  setUpdatedEngin,
  updateEnginLastSeen,
} from '../components/Engin/slice/engin.slice'
import {setMessage} from '../components/Planning/slice/planing.slice'
import {setLogs, setNewLogs} from '../components/LogsTracking/slice/logs.slice'
export const socketEvents = {
  connect: (data) => console.log('socket connected:', data),
  disconnect: () => console.log('disconnect'),
  plan_message: (data, dispatch) => {
    console.log('data2:', data)
    dispatch(setMessage(data))
  },
  tag_status_changed: (data, dispatch) => {},
  engin_status_changed: (data, dispatch) => {
    const check = data.data[0].typeMsg
    if (check == 'success') {
      dispatch(setSocketEnterOrExit(data))
    }
  },
  engin_state_changed: (data, dispatch) => {
    dispatch(setSocketStatusChange(data))
  },
  data_message: (data, dispatch, socketId) => {
    console.log('new chat ooo:', data, socketId)
    if (data?.socket == socketId) return
    dispatch(setUserRead(1))
    dispatch(setChatMessages(data))
  },
  new_location: (data) => {
    console.log('new engin', data)
  },
  new_engin: (data) => {
    console.log('new engn', data)
  },
  new_location_push: (data, dispatch) => {
    console.log('new Logg', data)
    dispatch(setLogs(data))
  },
  updated_engins: (data, dispatch) => {
    console.log('updated_engins', data)
    if (Array.isArray(data?.list) && data?.list?.length > 0) {
      console.log('updated_engins list', data?.list)
      // dispatch(setUpdatedEngin(data?.list))
    }
  },
  new_tags_logs: (data, dispatch) => {
    dispatch(setNewLogs(data.data))
    console.log('new flespi logs:', data)
  },
  engin_last_seen_at: (data, dispatch) => {
    console.log(' new_updated_engins last seen at:', data)
    dispatch(updateEnginLastSeen(data.engins))
  },
  new_updated_engins(data, dispatch) {
    console.log('data new new_updated_engins:', data)
    dispatch(setLastEnginUpdates(data?.engins))
  },
  worksite_statistiques(data, dispatch) {
    console.log('worksite_statistiques:', data)
  },
}
