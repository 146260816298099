import {request as orequest, psCoreRequest} from '../../../../../api'
import _axios from './axios'

async function request(url, params) {
  return await orequest(url, params, _axios)
}

export async function _fetchConversationList(data) {
  return await request('Communication/list', {
    method: 'POST',
    data,
  })
}
export async function _saveConversation(data) {
  return await request('Communication/save', {
    method: 'POST',
    data,
  })
}

export async function _fetchMessagesList() {
  return await request('Communication/mainList')
}

export async function _getUserRead() {
  return await request('Communication/isReadAll')
}

export async function _readMsg(data) {
  return await request('Communication/isRead', {
    method: 'POST',
    data,
  })
}
