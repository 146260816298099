import {createSlice, createAsyncThunk, createAction} from '@reduxjs/toolkit'
import {STATUS as name} from '../../../store/slices/config'
import {_fetchObject, _fetchStatus} from '../api/api'
import _ from 'lodash'
import {_fetchObjectFamilles} from '../../Famillies/api'

export const fetchStatus = createAsyncThunk(
  `${name}/fetchStatus`,
  async (_args, {getState, dispatch}) => {
    console.log('fetchStatus args', _args)
    let response = await _fetchStatus(_args)
    console.log('status', response)
    if (!response.error) dispatch(setStatus(response.data))
  }
)

export const fetchObject = createAsyncThunk(
  `${name}/fetchObject`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchObject()
    console.log('object data', response)
    if (!response.error) dispatch(setObject(response.data))
  }
)

export const fetchObjectFamilles = createAsyncThunk(
  `${name}/fetchObjectFamilles`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchObjectFamilles()
    console.log('object familles data', response)
    if (!response.error) dispatch(setObjectFamilles(response.data))
  }
)

//Actions
export const setStatus = createAction(`${name}/setStatus`)
export const setShow = createAction(`${name}/setShow`)
export const setSelectedStatus = createAction(`${name}/setSelectedStatus`)
export const setEditStatus = createAction(`${name}/setEditStatus`)
export const setObject = createAction(`${name}/setObject`)
export const setObjectFamilles = createAction(`${name}/setObjectFamilles`)
export const setSelectedObject = createAction(`${name}/setSelectedObject`)

const statusslice = createSlice({
  name,
  initialState: {
    status: [],
    show: true,
    selectedStatus: null,
    editStatus: false,
    objects: [],
    objectFamilles: [],
    selectedObject: null,
  },
  reducers: {},
  extraReducers: {
    [setStatus]: (state, {payload}) => {
      state.status = payload
    },
    [setShow]: (state, {payload}) => {
      state.show = payload
    },
    [setSelectedStatus]: (state, {payload}) => {
      state.selectedStatus = payload
    },
    [setEditStatus]: (state, {payload}) => {
      state.editStatus = payload
    },
    [setObject]: (state, {payload}) => {
      state.objects = payload
    },
    [setObjectFamilles]: (state, {payload}) => {
      state.objectFamilles = payload
    },
    [setSelectedObject]: (state, {payload}) => {
      state.selectedObject = payload
    },
  },
})

//selectors
export const getStatus = (state) => state[name].status
export const getShow = (state) => state[name].show
export const getSelectedStatus = (state) => state[name].selectedStatus
export const getEditStatus = (state) => state[name].editStatus
export const getObject = (state) => state[name].objects
export const getObjectFamilles = (state) => state[name].objectFamilles
export const getSelectedObject = (state) => state[name].selectedObject

export default statusslice.reducer
