export const EXTRA_MENU = [
    {
        "ID": 409523,
        "Text": "gateway",
        "Link": "gateway/index",
        "Name": "GATEWAY",
        "icon": "fa fa-signal-stream",
        "color": "",
        "hasChildren": 0,
        "subMenu": []
    },
    {
        "ID": 409523,
        "Text": "rapport",
        "Link": "rapport/index",
        "Name": "Raports de presence",
        "icon": "fa-solid fa-laptop-file",
        "color": "",
        "hasChildren": 0,
        "subMenu": []
    }
]