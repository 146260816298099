import moment from 'moment';
import { Dialog } from 'primereact/dialog';
import  { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Chip } from 'primereact/chip';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Checkbox } from 'primereact/checkbox';
import { Divider } from 'primereact/divider';
console.log('lodash:', _)
const dayBoxWidth = 70
const dayBoxHeight = 50

let days = ["Lun", "Mar" , "Mer" , 'Jeu' , 'Ven' , 'Sam' , 'Dim']
let months = moment.monthsShort()
let uEvents = [
     {
        fullname: "Dodji AKAKPO" , badge: 'AD' , 
        date: "2024-08-19",
        weekPresence:{
            Lun: {
                start: "08:00",
                end: "18:00",
                events: [
                    {from: '08:00' , to: '13:30' , label: 'Entrée' , bgColor: 'orange'},
                    {from: '13:30' , to: '14:20' , label: 'Sortie' , bgColor: 'gray'},
                    {from: '14:20' , to: '17:30' , label: 'Entrée' , bgColor: 'blue'},
                ]
            },
            Mar: {
                start: "08:00",
                end: "18:00",
                events: [
                    {from: '08:10' , to: '12:00' , label: 'Entrée' , bgColor: 'red'},
                    {from: '12:00' , to: '14:40' , label: 'Sortie' , bgColor: 'green'},
                    {from: '14:40' , to: '18:00' , label: 'Entrée' , bgColor: 'teal'},
                ]
            },
            Mer: {
                start: "08:00",
                end: "18:00",
                events: [
                    {from: '09:00' , to: '12:30' , label: 'Entrée' , bgColor: 'orange'},
                    {from: '12:30' , to: '13:10' , label: 'Sortie' , bgColor: 'gray'},
                    {from: '13:10' , to: '18:30' , label: 'Entrée' , bgColor: 'indigo'},
                ]
            },
            Jeu: {
                start: "08:00",
                end: "18:00",
                events: [
                    {from: '08:10' , to: '12:20' , label: 'Entrée' , bgColor: 'red'},
                    {from: '12:20' , to: '13:00' , label: 'Sortie' , bgColor: 'green'},
                    {from: '13:00' , to: '17:00' , label: 'Entrée' , bgColor: 'teal'},
                ]
            },
            Ven: {
                start: "08:00",
                end: "18:00",
                events: [
                    {from: '07:10' , to: '11:00' , label: 'Entrée' , bgColor: 'orange'},
                    {from: '11:00' , to: '14:10' , label: 'Sortie' , bgColor: 'gray'},
                    {from: '14:10' , to: '17:30' , label: 'Entrée' , bgColor: 'indigo'},
                ]
            }
        }
     },
     {
        fullname: "Dodji AKAKPO" , badge: 'AD' , 
        date: "2024-08-26",
        weekPresence:{
            Lun: {
                start: "08:00",
                end: "18:00",
                events: [
                    {from: '08:40' , to: '09:00' , label: 'Entrée' , bgColor: 'orange'},
                    // {from: '13:30' , to: '14:10' , label: 'Sortie' , bgColor: 'gray'},
                    // {from: '14:10' , to: '17:10' , label: 'Entrée' , bgColor: 'blue'},
                ]
            }
        }
     },
     {fullname: "Saad BENNANI" , badge: 'SB',date: "2024-08-19",},
     {fullname: "Zakaria RAHALI" , badge: 'ZR',date: "2024-08-19",},
     {fullname: "Rada SADIKI" , badge: 'RD',date: "2024-08-19",},
]
let users = ["Dodji AKAKPO", "Saad BENNANI" , "Zakaria RAHALI" , "Rada SADIKI"]
console.log('monnn:', months)
const NewRepportComponent = () => {
  let [currentMoment , setCurrentMoment] = useState(null)
  let [dateObject , setDateObject] = useState(null)
  let [ showUserInfoFor , setShowUserInfoFor] = useState('')
  let [currentUsersEvents , setCurrentUsersEvents] = useState([])
  let [selectedStaffNames , setSelectedStaffNames] = useState(['Tout'])
  let [usersEvents , setUsersEvents] = useState(uEvents);

  let usersRef = useRef()
  const goToWeek = (type)=> {
        try{
            if(!['prev' , 'next'].includes(type) || !isCurrentDateValid()) return
            console.log('ttttt')
            let current = moment(currentMoment)[type == 'prev' ? 'subtract' : 'add'](1, 'weeks').format('YYYY-MM-DD HH:mm:ss')
            console.log('current:', current)
            setCurrentMoment(current)
        }catch(e){
            console.log('eee:', e.message)
        }
  }

  const isCurrentDateValid = ()=> {
     return  currentMoment
  }

  const itemTemplate = (data)=>{
    let dayPresence = data.weekPresence ? Object.entries(data.weekPresence).reduce((c , [day , evt])=> {
            c[day] = evt.events.filter( o => o.label == 'Entrée').reduce( (_c , _evt)=>{
                _c += moment(_evt.to, 'HH:mm').diff(moment(_evt.from, 'HH:mm' ) , 'minutes', true)
                return _c
            },0)

            let hours = Math.floor(c[day]/60)
            c[day] = hours+':'+c[day]%60

            return c
    },{}) : {};

    console.log('dayPresence:', dayPresence)
    return (
        <div>
            <div className='flex border-top justify-content-between bg-gray-200'>
                <div onClick={()=> setShowUserInfoFor(showUserInfoFor == data.fullname ? '' : data.fullname)} style={{gap: 10}} className='flex w-10 p-2 align-items-center cursor-pointer hover:bg-blue-100 justify--center'>
                    <div style={{width: '30px' , height: '30px' , borderRadius: '50%'}} className='bg-blue-400 text-white flex align-items-center justify-content-center'>
                    { data.badge }
                    </div>
                    <div>
                        {data.fullname}
                    </div>
                </div>
                <div className='flex justify-content-between'>
                    {
                        days.map( l => (
                            <div className='hover:bg-blue-50 cursor-pointer flex align-items-center justify-content-center' 
                                style={{width: '70px' , height: '50px' , border: '1px solid gray'}}>
                                {dayPresence[l] || 0}
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                showUserInfoFor == data.fullname &&  weekPresenceTemplate(data)
            }
        </div>
    )
  }

  const weekPresenceTemplate = (data)=> {
     let weekPresence = data.weekPresence;
     if(_.isPlainObject(weekPresence)){
        let events = Object.entries(weekPresence)
        events = events.map( ([day , ev]) => {
            let f = ev.events[0]
            let e = ev.events[ev.events.length - 1]
            let start = moment(f.from, 'HH:mm')
            let end = moment(e.to, 'HH:mm')
            let diff = end.diff(start, 'seconds' , true)
            ev.events.forEach( _ev => {
                let diff2 = moment(_ev.to, 'HH:mm').diff(moment(_ev.from, 'HH:mm' ) , 'seconds', true)
                _ev.percentWidth = (diff2/diff)*100
            })

            return [day , ev]
        })

        console.log('evnets:', events)
        
        return (
            <div className='flex justify-content-end mb-2'>
                <div style={{ width: `${7*70 + 35}px`, paddingLeft: '5px'}} className='shadow'>
                    {
                        events.map(([day , _events])=> (
                            <div className='flex align-items-center justify-content-between border-top'>
                                <strong>{day}</strong>
                                <div style={{ width: `${7*70}px` , height: `${35}px`}} className='bg-blue-200 flex'>
                                  {
                                    _events.events.map( o => (
                                        <div title={`${o.from} - ${o.to}(${o.label})`} className='p-2 text-white cursor-pointer text-center' style={{fontSize: '8px' ,height: '100%' , overflow: 'hidden', background: o.bgColor,  width: `${o.percentWidth}%`}}>
                                                <strong>{o.from} - {o.to}</strong>
                                                <div>{o.label}</div>
                                        </div>
                                    ))
                                  }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        )
     }

     return null
  }

  const toggleOverlay = (ref , e)=>{
    if(!ref?.current) return
    ref.current.toggle(e)
  }

  const toggleSelectUser = (id)=> {
    setSelectedStaffNames( prev => {
        let t = _.cloneDeep(prev)
        if(!Array.isArray(t)) t= [];

        if(t.includes(id))
          t = id == 'Tout' ? [] : t.filter((i) => i != id)
        else{
            if(id == 'Tout') t = ['Tout',...users]
            else t.push(id)
        }
          
        return t
    })
  }

  const updateEvents = ()=>{
      
  }
  useEffect(()=> {
        setCurrentMoment(moment().format('YYYY-MM-DDTHH:mm:ss'));
  }, [])


  useEffect(()=> {
    if(!isCurrentDateValid()) return
    let start = moment(currentMoment);
    let fWeek = start.clone().weekday(1) 
    let eWeek = start.clone().weekday(6) 
    console.log('start:', start)
    let obj = days.reduce((c , v , idx)=> {
        let date = start.clone().weekday(idx+1)
        c[v] = {
            day: date.date(),
            moment: date,
            month: months[date.month()]
        }
        return c
    },{})


    let eventFiltered = usersEvents.filter( usrEvt => {
        return moment(usrEvt.date,'YYYY-MM-DD').isBetween(fWeek , eWeek, 'day' , '[]')
    })
    console.log('usersEvents:', usersEvents)
    setDateObject(obj)
    setCurrentUsersEvents(eventFiltered)
  }, [currentMoment , usersEvents])


  useEffect(()=>{
        let timer = setInterval(()=> {
            updateEvents();
        },3000)
        return ()=> clearInterval()
  },[])


  return (
    <div>
        <div className='flex shadow p-3 justify-content-between align-items-center'>
           <strong className='text-xl text-blue-400'>Feuille présence |</strong>
           <div className='flex align-items-center' style={{gap: 10}}>
                <div>
                 <Chip icon={'pi pi-file'} label={'Exporter'}
                    onClick={(e) => alert('Export')}
                    className="cursor-pointer bg-orange-300 text hover:bg-blue-100 p-button-text text-sm bg-blue-50" style={{ height: 'auto' }} />
                </div>
                <div>
                    <Chip icon={'pi pi-users'} label={selectedStaffNames.includes('Tout') ? 'Tout' : selectedStaffNames.join(',') ||'Agents'}
                        onClick={(e) => toggleOverlay(usersRef , e)}
                        className="cursor-pointer hover:bg-blue-100 p-button-text text-sm bg-blue-50" style={{ height: 'auto' }} />
                    <OverlayPanel ref={usersRef} className="p-0">
                        <div className='' style={{maxHeight: '300px' , overflow: 'auto' , minWidth: '150px'}}>
                            {
                                ['Tout',...users].map( (s ,i) => (
                                    <div key={s} onClick={(e)=> {
                                        toggleSelectUser(s)
                                        // toggleOverlay(usersRef , e)
                                    }} className={`p-2 flex gap-2 align-items-center hover:bg-blue-100 border-top-${i != 0 && 1} border-gray-200 cursor-pointer` }>
                                        <Checkbox  checked={selectedStaffNames.includes(s)} />
                                        <span> {s} </span>
                                    </div>
                                ))
                            }
                        </div>
                    </OverlayPanel>
                </div>
                <Divider layout='vertical' />
                <div style={{width: '200px' , border: '1px solid #eee' , borderRadius: '3px'}} className='p-3 flex align-items-center justify-content-around'>
                        <span onClick={()=> goToWeek('prev')} className='fa-solid fa-arrow-left'></span>
                        <strong>
                        <span>{dateObject?.['Lun']?.day} {dateObject?.['Lun']?.month}</span>  
                        <span className='mx-2'>au</span>  
                        <span>{dateObject?.['Dim']?.day} {dateObject?.['Dim']?.month}</span>  
                        </strong>
                        <span  onClick={()=> goToWeek('next')}  className='fa-solid fa-arrow-right'></span>
                </div>
           </div>
           
        </div>
        <div className='mt-3 shadow p-2'>
            <div className='flex justify-content-between'>
                <strong>Agent</strong>
                <div className='flex justify-content-between'>
                    {
                        days.map( l => (
                            <div style={{width: '70px'}} className='text-center text-sm'>
                                <span>{l} {dateObject?.[l]?.day}</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                currentUsersEvents.length > 0 ?
                   currentUsersEvents.filter(ev =>  selectedStaffNames.includes(ev.fullname) ||  selectedStaffNames.includes('Tout')).map(itemTemplate):
                   (
                        <div className='pt-7 pb-2 flex justify-content-center'>
                            {/* <span className='pi pi-tag'></span> */}
                            <strong className='text-xl text-gray-500'>Aucun evenement de presence</strong>
                        </div>
                   )
            }
        </div>
        <div>
            
        </div>
    </div>
  )
}

export default NewRepportComponent