import {createSlice, createAsyncThunk, createAction} from '@reduxjs/toolkit'
import {DASHBOARD as name} from '../../../store/slices/config'
import _ from 'lodash'
import {_fetchDashboard, _fetchDashboardDetail} from '../api/index'

export const fetchDashboard = createAsyncThunk(
  `${name}/fetchDashboard`,
  async (_args, {getState, dispatch}) => {
    let response = await _fetchDashboard()
    console.log('dashboard data: ', response)
    if (!response.error) dispatch(setDashboard(response.data))
  }
)

export const fetchDashboardDetail = createAsyncThunk(
  `${name}/fetchDashboardDetail`,
  async (code, {getState, dispatch}) => {
    console.log('args: ', code)
    let response = await _fetchDashboardDetail(code)
    console.log('dashboard detail data: ', response)
    if (!response.error) {
      dispatch(setDashboardDetail(response.data))
      return true
    }
    return false
  }
)

//Actions
export const setDashboard = createAction(`${name}/fetchDashboard`)
export const setEditDashboard = createAction(`${name}/setEditDashboard`)
export const setSelectedDashboard = createAction(`${name}/setSelectedDashboard`)
export const setDashboardDetail = createAction(`${name}/fetchDashboardDetail`)
export const setSelectedDashboardDetail = createAction(`${name}/setSelectedDashboardDetail`)

const dashboardslice = createSlice({
  name,
  initialState: {
    dashboard: [],
    editDashboard: false,
    selectedDashboard: null,
    dashboardDetail: [],
    selectedDashboardDetail: null,
    cardSelected: null,
    loadingCard: false,
    selectedMode: 'card',
  },
  reducers: {
    setCardSelected: (state, {payload}) => {
      state.cardSelected = payload
    },
    setLoadingCard: (state, {payload}) => {
      state.loadingCard = payload
    },
    setSelectedMode: (state, {payload}) => {
      state.selectedMode = payload
    },
  },
  extraReducers: {
    [setDashboard]: (state, {payload}) => {
      state.dashboard = payload
    },
    [setEditDashboard]: (state, {payload}) => {
      state.editDashboard = payload
    },
    [setSelectedDashboard]: (state, {payload}) => {
      state.selectedDashboard = payload
    },
    [setDashboardDetail]: (state, {payload}) => {
      state.dashboardDetail = payload
    },
    [setSelectedDashboardDetail]: (state, {payload}) => {
      state.selectedDashboardDetail = payload
    },
  },
})

//selectors
export const getDashboard = (state) => state[name].dashboard
export const getEditDashboard = (state) => state[name].editDashboard
export const getSelectedDashboard = (state) => state[name].selectedDashboard
export const getDashboardDetail = (state) => state[name].dashboardDetail
export const getSelectedDashboardDetail = (state) => state[name].selectedDashboardDetail
export const getCardSelected = (state) => state[name].cardSelected
export const getLoadingCard = (state) => state[name].loadingCard
export const getSelectedMode = (state) => state[name].selectedMode

export const {setCardSelected, setLoadingCard, setSelectedMode} = dashboardslice.actions
export default dashboardslice.reducer
