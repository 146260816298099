

export const columnsTarif = [
    {
    header: "Code" ,
    olang: 'Code',
    field: 'code',
  },
  {
    header : "Description",
    field:"description",
    olang:"description "
  },
  {
    header : "Etat",
    field:"etat",
    olang:"Etat"
  },
  {
    header : "Prestation",
    field:"prestation",
    olang:"Prestation "
  },
  {
    header : "Id filter section",
    field:"id_tarif_filter_selection",
    olang:"Id filter section "
  },
  {
    header : "Valeur Filter",
    field:"valeur_filter_selection",
    olang:"Valeur Filter"
  },
  {
    header : "Formule condition",
    field:"formule_condition",
    olang:"Formule condition"
  },
  {
    header : "Formule calculé",
    field:"formule_calcule",
    olang:"formule calculé"
  },
]

export const columnsMatrice = [
    {
    header: "Code" ,
    olang: 'Code',
    field: 'Code',
  },
  {
    header : "Description",
    field:"Description",
    olang:"Description "
  },
  {
    header : "Dimension",
    field:"Dimension",
    olang:"Dimension "
  },
  {
    header : "Field name",
    field:"XFieldName",
    olang:"Field name"
  },
  {
    header : "XUnit",
    field:"XUnit",
    olang:"XUnit"
  },
  {
    header : "YField name",
    field:"YFieldName",
    olang:"YField name"
  },
  {
    header : "YUnit",
    field:"YUnit",
    olang:"YUnit"
  },
]

export const ColumnsNiveauSrcDataDossier = [
  {
  header: "OTID" ,
  olang: 'OTID',
  field: 'OTID',
},
{
  header: "OTNoBL" ,
  olang: 'OTNoBL',
  field: 'OTNoBL',
},
]

export const ColumnsNiveauSrcDataClient = [
  {
  header: "ClientID" ,
  olang: 'ClientID',
  field: 'ClientID',
},
{
  header: "Nom" ,
  olang: 'Nom',
  field: 'Nom',
},
]

export const ColumnsPrestation = [
  {
    header: "Produit" ,
    olang: 'Produit',
    field: 'Produit',
  },

]

export const ColumnsEtat = [
  {
    header: "Etat" ,
    olang: 'Etat',
    field: 'Etat',
  },
  {
    header: "Description" ,
    olang: 'Description',
    field: 'Description',
  },
]

export const ColumnsParameters = [
  {
    header: "Field Name" ,
    olang: 'Field Name',
    field: 'FieldName',
  },
  {
    header: "Unit" ,
    olang: 'Unit',
    field: 'Unit',
  },
  {
    header: "Description" ,
    olang: 'Description',
    field: 'Description',
  },
]

