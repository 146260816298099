import {Dialog} from 'primereact/dialog'
import MapComponent from '../EnginDetail/MapComponent'
import logoBlack from '../assets/LOGITAGBLACK.png'
import logoColor from '../assets/LOGITAGCMYK.png'
import L, {Icon} from 'leaflet'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import {
  fetchEnginListHistory,
  getEnginListHistory,
  getSelectedEngine,
  setGeoByIdSite,
  setSelectedEngine,
  setShowHistory,
} from '../slice/engin.slice'
import {Card} from 'primereact/card'
import {OlangItem} from '../../shared/Olang/user-interface/OlangItem/OlangItem'
import {useEffect} from 'react'

const EnginMapLocation = ({dialogVisible, setDialogVisible, historySrc}) => {
  const selectedEngin = useAppSelector(getSelectedEngine)
  const customIcon = new Icon({
    iconUrl: selectedEngin?.tagId != 0 ? logoColor : logoBlack,
    iconSize: [30, 40],
  })
  const dispatch = useAppDispatch()

  const enginesHistory = useAppSelector(getEnginListHistory)

  console.log('ccc enginesHistory', enginesHistory)

  const dialogTemplate = () => {
    return (
      <div className='flex'>
        <div>
          <p className='text-2xl'>{selectedEngin?.reference || selectedEngin?.label}</p>
        </div>
      </div>
    )
  }

  const onHide = () => {
    try {
      setDialogVisible(false)
      dispatch(setShowHistory(true))
      dispatch(setGeoByIdSite(null))
      // dispatch(setSelectedEngine(null))
    } catch (error) {
      console.log('error', error)
    }
  }

  // useEffect(() => {
  //   dispatch(fetchEnginListHistory(historySrc))
  // }, [])

  return (
    <Dialog
      header={dialogTemplate}
      visible={dialogVisible}
      style={{
        width: '78vw',
        '@media screen and (max-width: 960px)': {width: '75vw'},
        '@media screen and (max-width: 641px)': {width: '100vw', padding: '50px'},
        '@media screen and (max-width: 320px)': {width: '100vw', padding: '50px'},
      }}
      onHide={onHide}
      position='right'
    >
      <div style={{height: '75vh', width: '100%'}} className='flex justify-content-center'>
        {/* <Card
          className='w-full'
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
            borderRadius: '15px',
            height: '75vh',
          }}
        > */}
        <MapComponent
          position={{last_lat: selectedEngin?.last_lat, last_lng: selectedEngin?.last_lng}}
          icon={customIcon}
          popupTitle={
            selectedEngin?.labeltag === null ||
            selectedEngin?.labeltag === '' ||
            selectedEngin?.labeltag == undefined
              ? selectedEngin?.tagname
              : selectedEngin?.labeltag
          }
          locationHistory={enginesHistory}
        />
        {/* </Card> */}
      </div>
    </Dialog>
  )
}

export default EnginMapLocation
