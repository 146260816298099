import React, {useEffect, useState} from 'react'
import {DatatableComponent} from '../../shared/DatatableComponent/DataTableComponent'
import {useAppDispatch, useAppSelector} from '../../../hooks'

import {
  getInvoicePendingBilling,
  fetchInvoicePendingBilling,
  createOrUpdatePendingBilling,
  getSelectedInvoices,
  setSelectedInvoices,
  getFactureId,
  setFactureId
} from '../slice/elementFacturable.slice'
import ButtonComponent from '../../shared/ButtonComponent'
import {DialogComponent} from '../../shared/DialogComponent'
import {Dropdown} from 'primereact/dropdown'
import {fetchCustomers, getCustomers} from '../../../store/slices/customer.slice'
import {InputNumber} from 'primereact/inputnumber'

const ElementFacturable = () => {
  const [visible, setVisible] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  // const [selectedInvoices , setSelectedInvoices] = useState(null)
  const [visibleInput, setVisibleInput] = useState(false)
  // const [factureId, setFactureId] = useState(null)
  const [isTableEmpty , setIsTableEmpty] = useState(true)
  const dispatch = useAppDispatch()
  const [isSaved , setIsSaved] = useState(false)

  const selectedInvoices = useAppSelector(getSelectedInvoices)
  const factureId = useAppSelector(getFactureId)

  const onHide = () => {
    setVisible(false)
  }
  const onHideInput = () => {
    setVisibleInput(false)
  }
  const handleChange = (e) => {
    setSelectedCustomer(e.target.value)
  }


  const handleSaveFacture = () => {
    
    dispatch(createOrUpdatePendingBilling()).then((res) => {
        dispatch(fetchInvoicePendingBilling(selectedCustomer.id))
        dispatch(setFactureId(null))
        dispatch(setSelectedInvoices(null))
    })
    setVisibleInput(false)
    setVisible(false)
    // console.log("object save",obj)
  }
  console.log('factureId', factureId)

  const handleCancel = () => {
    setFactureId(null)
    setVisibleInput(false)
  }

  useEffect(() => {
    dispatch(fetchInvoicePendingBilling(selectedCustomer?.id))
    dispatch(fetchCustomers())
    
  }, [isSaved])

  let elementFacturable = useAppSelector(getInvoicePendingBilling)
  let customers = useAppSelector(getCustomers)
  console.log('elementFacturable', elementFacturable)

  const columns = [
    {
      header: 'Uid',
      olang: 'Uid',
      field: 'uid',
      //body: addresseeTemplate
    },
    {
      header: 'ID',
      field: 'id',
      olang: 'ID',
    },
    {
      header: 'srcObject',
      field: 'srcObject',
      olang: 'srcObject',
    },
    {
      header: 'srcId',
      field: 'srcId',
      olang: 'srcId',
    },
    // {
    //   header: 'invoiceId',
    //   field: 'invoiceId',
    //   olang: 'invoiceId',
    // },
    // {
    //   header: 'invoiceLineId',
    //   field: 'invoiceLineId',
    //   olang: 'invoiceLineId',
    // },
    {
      header: 'creaDate',
      olang: 'creaDate',
      field: 'creaDate',
    },
    {
      header: 'creaUser',
      olang: 'creaUser',
      field: 'creaUser',
      //body: activeTemplate,
    },
    {
      header: 'customerId',
      field: 'customerId',
      olang: 'customerId',
    },
    {
      header: 'worksiteId',
      field: 'worksiteId',
      olang: 'worksiteId',
    },
    {
      header: 'beginDate',
      field: 'beginDate',
      olang: 'beginDate',
    },
    {
      header: 'endDate',
      field: 'endDate',
      olang: 'endDate',
    },
    {
      header: 'sysStatus',
      field: 'sysStatus',
      olang: 'sysStatus',
    },
    {
      header: 'sysMode',
      field: 'sysMode',
      olang: 'sysMode',
    },
    {
      header: 'sysActive',
      field: 'sysActive',
      olang: 'sysActive',
    },
    {
      header: 'sysversion',
      field: 'sysversion',
      olang: 'sysversion',
    },
    {
      header: 'remarque',
      field: 'remarque',
      olang: 'remarque',
    },
  ]

  let actions = [
    {
      label: 'Supprimer',
      icon: 'pi pi-trash text-red-500',
      command: (e) => {
        //dispatch(setSelectedInvoice(e.item.data))
        //dispatch(removeInvoice(e.item.data))
      },
    },
    {
      label: 'Detail',
      icon: 'pi pi-eye text-blue-500',
      command: (e) => {
        //dispatch(setSelectedInvoice(e.item.data))
        //navigate('/detailsTest')
      },
    },
  ]

  const headear = (
    <div>
      <ButtonComponent label='Facturer' onClick={() => setVisible(true)} />
    </div>
  )
  const footerFactureId = () => {
    return (
      <div className='flex justify-content-end'>
        <ButtonComponent label='OK' onClick={handleSaveFacture} />
        <ButtonComponent label='Annuler' onClick={handleCancel} />
      </div>
    )
  }

  const handleSearch = (customer) => {
    console.log('handleSearch', customer)
    const customerId = customer?.id
    dispatch(fetchInvoicePendingBilling(customerId))
    setIsTableEmpty(false)
  }

  const handleSelection = (selectedInv) => {
    dispatch(setSelectedInvoices(selectedInv))
  }
  console.log('selected invs:', selectedInvoices)

  return (
    <div>
      {/* <p className='text-4xl font-medium'>Element Facturable</p> */}
      <div className='card bg-gray-100 mt-5'>
        <p className='text-xl pt-3 pl-3'>Client</p>
        <div className='flex pl-3 pb-3'>
          {/* <InputText className=' h-3rem w-3' value={client} onChange={(e) => setClient(e.target.value)} /> */}
          <Dropdown
            placeholder='Select a customer'
            className=' h-3rem w-3'
            value={selectedCustomer}
            options={customers}
            onChange={handleChange}
          />
          <ButtonComponent
            label='Rechercher'
            className='ml-3 h-3rem'
            onClick={() => handleSearch(selectedCustomer)}
          />
          <ButtonComponent icon='pi pi-search' className='ml-3 h-3rem' />
        </div>
      </div>
      <DatatableComponent
        //exportFields={exportFields}
        tableId='element-facture'
        data={isTableEmpty? [] : elementFacturable}
        columns={columns}
        onSelections={handleSelection}
        rowActions={actions}
        //rowGroupTemplates={rowGroupTemplates}
        //contextMenuModel={actions}
        // isDataSelectable={true}
        // rowActions={actions}
        extraHeader={headear}
      />
      {/* Pour facturer les elements selectionne */}
      <DialogComponent
        header='Facturer les elements selectionné'
        visible={visible}
        onHide={onHide}
        className='md:w-4 right-0'
        position='right'
      >
        <ButtonComponent label='Créer une nouvelle facture' className='mr-4' onClick={handleSaveFacture} />
        <ButtonComponent
          label='Ajouter à une facture existante'
          onClick={() => setVisibleInput(true)}
        />
      </DialogComponent>

      {/* input de l'id de facture deja existante */}

      <DialogComponent
        header="Entrer l'id de la facture"
        visible={visibleInput}
        onHide={onHideInput}
        className='md:w-22rem right-0'
        footer={footerFactureId}
      >
        <div className='flex justify-content-center'>
          <InputNumber
            inputId='valeur'
            value={factureId}
            onValueChange={(e) => dispatch(setFactureId(e.value))}
            className='w-9 h-3rem'
          />
        </div>
      </DialogComponent>
    </div>
  )
}

export default ElementFacturable
