import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import {LOGS as name} from '../../../store/slices/config'
import {_fetchEnginByTag, _fetchGeoById, _fetchLogList, _fetchStaffList} from '../api'
import _ from 'lodash'
import {setToastParams} from '../../../store/slices/ui.slice'

export const fetchLogList = createAsyncThunk(
  `${name}/fetchLogList`,
  async (_args, {getState, dispatch}) => {
    try {
      console.log('fetchLogList args', _args)
      let response = await _fetchLogList(_args)

      console.log('fetchLogList res', response)
      if (!response.error) {
        //el.originalDate}_${el.UserID}`  
        dispatch(setLogList(response.response))
      }
    } catch (error) {
      console.log('error', error)
    }

    return 
  }
)

export const fetchStaffList = createAsyncThunk(
  `${name}/fetchStaffList`,
  async (_args, {getState, dispatch}) => {
    try {
      let response = await _fetchStaffList()
      console.log('fetchStaffList res', response)
      if (!response.error) {
        dispatch(setStaffList(response.data))
        return true
      }
    } catch (error) {
      console.log('error', error)
      return false
    }
  }
)

export const fetchGeoLogById = createAsyncThunk(
  `${name}/fetchGeoLogById`,
  async (_args, {getState, dispatch}) => {
    try {
      let response = await _fetchGeoById(_args)
      console.log('fetchGeoLogById res', response)
      if (!response.error) {
        dispatch(setGeoLogById(response.data))
        return true
      }
      return false
    } catch (error) {
      console.log('error', error)
      return false
    }
  }
)

export const fetchEnginByTag = createAsyncThunk(
  `${name}/fetchEnginByTag`,
  async (_args, {getState, dispatch}) => {
    try {
      console.log('fetchEnginByTag args', _args)
      let response = await _fetchEnginByTag({tags: _args})
      console.log('fetchEnginByTag res', response)
      if (!response.error && Array.isArray(response.data)) {
        let data = _.uniqBy(response.data, 'reference')
        dispatch(setEnginTagLogs(data))
        return true
      }
      // else if (!response.error && response?.data?.length === 0) {
      //   dispatch(
      //     setToastParams({
      //       show: true,
      //       severity: 'warn',
      //       summary: 'Warning',
      //       detail: 'Aucun engin correspondant',
      //       position: 'top-right',
      //     })
      //   )
      //   return false
      // }
      return false
    } catch (error) {
      console.log('error', error)
      return false
    }
  }
)

const logSlice = createSlice({
  name,
  initialState: {
    logs: [],
    listDetail: [],
    logList: [],
    enginTagLog: [],
    listTagLogs: [],
    staffList: [],
    geoLogById: null,
    showDetail: false,
  },
  reducers: {
    setLogs: (state, {payload}) => {
      let logs = [...state.logs]
      logs.unshift(payload)
      state.logs = logs
    },
    setShowDetail: (state, {payload}) => {
      state.showDetail = payload
    },
    setListTagLogs: (state, {payload}) => {
      state.listTagLogs = payload
    },
    setNewLogs(state , {payload}){
      if(!Array.isArray(payload)) return
      state.logList = [...payload , ...state.logList]
    },
    setGeoLogById: (state, {payload}) => {
      state.geoLogById = payload
    },
    setLogList: (state, {payload}) => {
      state.logList = payload
    },
    setStaffList: (state, {payload}) => {
      state.staffList = payload
    },
    setListDetail: (state, {payload}) => {
      state.listDetail = payload
    },
    setEnginTagLogs: (state, {payload}) => {
      state.enginTagLog = payload
    },
  },
})

export const getLogsTrack = (state) => state[name].logs
export const getShowDetail = (state) => state[name].showDetail
export const getListDetail = (state) => state[name].listDetail
export const getLogList = (state) => state[name].logList
export const getEnginTagLogs = (state) => state[name].enginTagLog
export const getStaffList = (state) => state[name].staffList
export const getListTagLogs = (state) => state[name].listTagLogs
export const getGeoLogById = (state) => state[name].geoLogById

export const {
  setLogs,
  setShowDetail,
  setGeoLogById,
  setListDetail,
  setLogList,
  setEnginTagLogs,
  setStaffList,
  setListTagLogs,
  setNewLogs
} = logSlice.actions
export default logSlice.reducer
