import AddressDetail from './Company/user-interface/AddressDetail/AddressDetail'
import CompanyComponent from './Company/user-interface/Company.component'
import CompanyDepositForm from './CompanyDeposit/CompanyDepositForm'
import CustomerComponent from './Customer/CustomerComponent'
import ClientDetail from './Customer/CustomerList/ClientDetail'
import DashboardComponent from './Dashboard/user-interface/DashboardComponent'
import EnginDetail from './Engin/EnginDetail/EnginDetail'
import MapComponent from './Engin/EnginDetail/MapComponent'
import EnginInactive from './Engin/EnginList/EnginInactive'
import EngineComponent from './Engin/EngineComponent'
import ElementFacturable from './Facturation/user-interface/ElementFacturable'
import ElementFacture from './Facturation/user-interface/ElementFacture'
import Facturation from './Facturation/user-interface/Facturation'
import FacturationList from './Facturation/user-interface/FacturationList'
import FamillesComponent from './Famillies/user-interface/FamillesComponent'
import { GatewayComponent } from './Gateway/ui/GatewayComponent'
import { GatewayList } from './Gateway/ui/GatewayList/GatewayList'
import GatewayRoutes from './Gateway/ui/GatewayRoutes'
import GeofencingComponent from './Geofencing/GeofencingComponent'
import InventoryComponent from './Inventory/InventoryComponent'
import InventoryList from './Inventory/InventoryList'
import InvoiceComponent from './Invoices/user-interface/InvoiceComponent'
import InvoiceDetail from './Invoices/user-interface/InvoiceDetails/InvoiceDetail'
import RFLocationTag from './LocationTag/user-interface/RFLocationTag'
import LogsTrackingList from './LogsTracking/user-interface/LogsTrackingList'
import CalendarView from './Planning/CalendarView/user-interface/CalendarView'
import PlanningComponent from './Planning/PlanningComponent'
import RFEngineComponent from './RFEngine/user-interface/RFEngineComponent'
import RfTag from './RFTag/user-interface/RFTagComponent'
import NewRepportComponent from './Repports/user-interface/NewRepportComponent'
import RapportComponent from './Repports/user-interface/RapportComponent'
import SetupInfo from './Setup_Info/user-interface/Setup.info'
import SiteComponent from './Site/user-interface/SiteComponent'
import SiteDetail from './Site/user-interface/SiteList/SiteDetail'
import StatusComponent from './Status/user-interface/StatusComponent'
import StatutComponent from './Statut/user-interface/StatutComponent'
import TagComponent from './Tag/user-interface/TagComponent'
import TagDetail from './Tag/user-interface/TagDetail/TagDetail'
import TagMapViewComponent from './TagMapView/user-interface/TagMapViewComponent'
import TeamComponent from './Teams/user-interface/TeamComponent'
import VehicleMapView from './Vehicle/user-interface/VehicleMapView'
import VehiculesComponent from './Vehicules/user-interface/VehiculesComponent'
import DepotComponent from './depot/user-interface/DepotComponent'
import VehComponent from './veh/vehComponent'
import VehList from './veh/vehList'
// import WorkSiteList from "./WorkSite/WorkSiteList"
// test2
const components = {
  /** dadev */
  geofencing: GeofencingComponent,

  /** zakaria */
  'view/engin/index': EngineComponent,
  'tag/index': TagComponent,
  'customer/index': CustomerComponent,
  'view/staff/index': TeamComponent,
  'worksite/index': SiteComponent,
  'Invoice/index': InvoiceComponent,
  'tour/index': TagMapViewComponent,
  'tag/detail': TagDetail,
  'Company/index': CompanyComponent,
  'menu/setup': SetupInfo,
  'Geofence/index': GeofencingComponent,
  'facture/client': FacturationList,
  // 'facture/index': ElementFacturable,
  'facture/fournisseur': Facturation,
  'facture/client': FacturationList,
  'statutactuelrftag/index': RfTag,
  'sommairerftags/index': RFEngineComponent,
  'equipmentInventory/index': RFLocationTag,
  'timeline/index': CalendarView,
  'Status/index': StatusComponent,
  'tagdashboard/index': DashboardComponent,
  'famille/index': FamillesComponent,
  'situationtags/index': RfTag,
  'vehicule/index': VehList,
  'inventory/index': InventoryComponent,
  'vehicule/index': VehComponent,
  'deposit/index': DepotComponent,
  'rapports/index': RapportComponent,
  'EnginNoActive/index': EnginInactive,
  'LOGS/index': LogsTrackingList,
  'gateway/index': GatewayComponent,
  'rapport/index': NewRepportComponent
  /** Rabi */
}

export default components
