import {memo, useEffect, useState} from 'react'
import {DatatableComponent} from '../../../shared/DatatableComponent/DataTableComponent'
import {useAppDispatch, useAppSelector} from '../../../../hooks'

import {SplitButton} from 'primereact/splitbutton'
import {
  fetchTags,
  getSelectedTag,
  getTags,
  setEditTag,
  setSelectedTag,
  removeTag,
  fetchStatus,
  getStatus,
  setShow,
  setTagLocationShow,
  setTagLocation,
  fetchTagHistory,
  setTagHistoryShow,
} from '../../slice/tag.slice'
import {Chip} from 'primereact/chip'
import {OlangItem} from '../../../shared/Olang/user-interface/OlangItem/OlangItem'
import {useNavigate} from 'react-router-dom'
import {setAlertParams} from '../../../../store/slices/alert.slice'
import GeocodingComponent from '../../../shared/GeocodingComponent/GeocodingComponent'
import {fetchValidator} from '../../../Inventory/slice/inventory.slice'
import {ProgressSpinner} from 'primereact/progressspinner'
import {setToastParams} from '../../../../store/slices/ui.slice'
import Loader from '../../../shared/Loader/Loader'

const TagList = ({titleShow, detailView, tags}) => {
  let status = useAppSelector(getStatus)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  let icon = detailView === 'Detail' ? 'pi-eye' : 'pi-sliders-v'
  const [isLoadingButton, setIsLoadingButton] = useState(false)

  let actions = [
    {
      label: 'Supprimer',
      icon: 'pi pi-trash text-red-500',
      confirm: 'test',
      command: (e) => {
        dispatch(setSelectedTag(e.item.data))
        dispatch(
          setAlertParams({
            title: 'Supprimer',
            message: 'Voulez-vous vraiment supprimerce tag?',
            acceptClassName: 'p-button-danger',
            visible: true,
            accept: () => {
              dispatch(removeTag(e.item.data))
            },
          })
        )
      },
    },
    {
      label: `${detailView}`,
      icon: `pi ${icon} text-blue-500`,
      command: (e) => {
        if (detailView === 'Detail' || !detailView) {
          setIsLoading(true)
          dispatch(fetchValidator('tagupdate'))
            .then(() => {
              dispatch(setSelectedTag(e.item.data))
              dispatch(setShow(false))
            })
            .finally(() => {
              setIsLoading(false)
            })
        } else if (detailView === 'Edit') {
          dispatch(setSelectedTag(e.item.data))
          dispatch(setEditTag(true))
        }
      },
    },
  ]

  const showLocationAddress = (rowData) => {
    if (!rowData?.lat || !rowData?.lng) {
      dispatch(
        setToastParams({
          show: true,
          severity: 'error',
          summary: 'Warning',
          detail: 'No Location Found',
          position: 'top-right',
        })
      )
      return
    }
    // dispatch(setTagHistoryShow(true))
    dispatch(fetchTagHistory(rowData?.id))
    let obj = {
      enginName: rowData?.enginName || '',
      image: rowData?.image || 'import/uploads/nopicture.png',
      tagName: rowData?.name,
      latitude: rowData?.lat,
      longitude: rowData?.lng,
      icon: rowData?.familleIcon,
      iconBgColor: rowData?.familleBgcolor,
      famille: rowData?.famille,
    }
    dispatch(setTagLocation(obj))
    dispatch(setTagLocationShow(true))
  }

  const activeTemplate = (rowData) => (
    <Chip
      label={rowData?.active == 1 ? 'Actif' : 'Inactif'}
      icon={rowData?.active == 1 ? 'pi pi-check' : 'pi pi-times'}
      style={{backgroundColor: `${rowData?.activeColor}`, color: 'white'}}
    />
  )
  const statusTemplate = (rowData) => {
    if (rowData?.iconName) {
      return (
        <i
          title={rowData?.status}
          className={`${rowData?.iconName} text-2xl rounded p-2`}
          style={{color: `${rowData.statusbgColor}`}}
        ></i>
      )
    }
    return (
      <Chip
        label={rowData?.status}
        style={{background: `${rowData.statusbgColor}`, color: rowData.color ?? 'white'}}
        title={`${rowData?.statusDate}`}
      />
    )
  }

  const familleTemplate = ({famille, familleIcon, familleBgcolor, familleColor}) => {
    return (
      <Chip
        label={famille}
        icon={familleIcon}
        style={{background: familleBgcolor, color: 'white'}}
      />
    )
  }

  const addresseeTemplate = (rowData) => {
    const {tagAddress} = rowData
    return (
      <>
        {
          <div>
            {tagAddress ? (
              <Chip
                label={tagAddress}
                className='w-11rem m-1 flex justify-content-center align-items-center cursor-pointer'
                onClick={() => showLocationAddress(rowData)}
              />
            ) : (
              'No address found.'
            )}
          </div>
        }
      </>
    )
  }

  const columns = [
    {
      header: 'ID Tag',
      field: 'name',
      olang: 'ID Tag',
      filter: true,
    },
    {
      header: 'Label',
      field: 'label',
      olang: 'label',
    },
    {
      header: 'Famille',
      field: 'famille',
      olang: 'Famille',
      visible: true,
      body: familleTemplate,
    },
    {
      header: 'ADRESSE',
      olang: 'ADRESSE',
      field: 'adresse',
      body: addresseeTemplate,
    },
    {
      header: 'Satus',
      olang: 'Status',
      field: 'status',
      body: statusTemplate,
    },

    {header: 'ACTIF', field: 'ACTIF', olang: 'ACTIF', body: activeTemplate},
  ]

  const exportFields = [
    {label: 'Nom', column: 'name'},
    {label: 'Label', column: 'label'},
    {label: 'Famille', column: 'famille'},
    //{label: 'Adresse', column: 'adresse'},
    {label: 'Satus', column: 'status'},
  ]

  const allowedGroupFields = ['famille', 'status']

  const rowGroupTemplates = {
    famille: (rowData) => familleTemplate(rowData),
    status: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.status} />
    ),
  }

  let create = () => {
    setIsLoadingButton(true)
    dispatch(fetchValidator('tagadd'))
      .then(() => {
        dispatch(setEditTag(true))
        dispatch(setSelectedTag(null))
      })
      .finally(() => {
        setIsLoadingButton(false)
      })
  }

  useEffect(() => {
    setIsLoading(true)
    Promise.all([dispatch(fetchTags()), dispatch(fetchStatus())]).finally(() => {
      setIsLoading(false)
    })
  }, [])

  return (
    <>
      <div className='py-3 flex flex-row align-items-center'>
        <h1 className='text-700'>
          <OlangItem olang={'tag.list'} />
        </h1>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <DatatableComponent
          tableId='tag-table'
          data={tags}
          columns={columns}
          exportFields={exportFields}
          onNew={create}
          isLoading={isLoadingButton}
          rowGroupTemplates={rowGroupTemplates}
          contextMenuModel={actions}
          allowedGroupFields={allowedGroupFields}
          onSelections={(r) => console.log('data:', r)}
          rowActions={actions}
          sortField={'id'}
          sortOrder={-1}
        />
      )}
    </>
  )
}

export default memo(TagList)
