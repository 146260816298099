import _axios  from './axios'
import { request as orequest} from '../../../api/index.js';

async function request(url, params) {
    return await orequest(url , params , _axios)
}



export async function _login(credentials) {
    return await request(`login`, {
      method: 'post',
      data: credentials,
    })
  }
  
  export async function _signin(credentials) {
    return await request(`signin`, {
      method: 'post',
      data: credentials,
    })
  }
  
  export async function _logOut(userId) {
    return await request(`logout`)
  }
  
  export async function _checkUser(token) {
    return await request(`checkUserToken`, {
      method: 'post',
      data: {token},
    })
  }

  export async function _getPointAttachement(user){
    return await request(`getPointAttachement`, {
      method: 'post',
      data: {user},
    })
  }