import React, {useCallback, useEffect, useRef, useState} from 'react'
import L from 'leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import 'leaflet/dist/leaflet.css'
import {
  MapContainer,
  useMapEvent,
  TileLayer,
  ZoomControl,
  Popup,
  FeatureGroup,
  Polygon,
  Marker,
} from 'react-leaflet'
import GeomanComponent from './GeomanComponent/GeomanComponent'
import {Checkbox} from 'primereact/checkbox'
import {InputText} from 'primereact/inputtext'
import ButtonComponent from '../../ButtonComponent/ButtonComponent'
import tagIconImg from '../../../../assets/icons/tag-icon.gif'
import locationIcon from '../assets/icons/location.png'
import _ from 'lodash'
import './style.css'
import GeofenceEditorComponent from './Editors/GeofenceEditorComponent'
import {useDispatch} from 'react-redux'
import {saveGeofencing} from '../../../../store/slices/geofencing.slice'
import GeofenceListComponent from './List/GeofenceListComponent'
import {useAppSelector} from '../../../../hooks'
import IconMap from '../assets/icons/marker.png'
import RedMarker from '../assets/icons/redMarker.png'
import assetConfigs from '../../../../configs/index'

import {
  fetchGeofencings,
  getGeofences,
  getSelectedGeoClient,
  getSelectedGeofenceId,
} from '../slice/geofencing.slice'
import {Chip} from 'primereact/chip'
import {OverlayPanel} from 'primereact/overlaypanel'
import * as turf from '@turf/turf'
import {
  fetchEngines,
  getLastEnginsUpdates,
  getSelectedEnginMap,
  setSelectedEnginMap,
} from '../../../Engin/slice/engin.slice'
import {fetchPointsGeo} from '../slice/navixy.slice'
import {fetchSites, getSelectedSite, getSites} from '../../../Site/slice/site.slice'
import GeocodingComponent from '../../GeocodingComponent/GeocodingComponent'
import {OlangItem} from '../../Olang/user-interface/OlangItem/OlangItem'
import BaseMapLayerComponent from '../../BaseMapLayerComponent/BaseMapLayerComponent'
import {useLocalStorage} from 'primereact/hooks'
import {Image} from 'primereact/image'
import moment from 'moment'
import {getSelectedAddress} from '../../../Company/slice/company.slice'

const layers = [
  'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
  'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
  'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
]

let tagIcon = new L.Icon({
  iconUrl: locationIcon,
  // iconSize: [30, 30],
  // shadowSize: [50, 64],
  // iconAnchor: [22, 94],
  // shadowAnchor: [4, 62],
  // popupAnchor: [-15, -90],
  // iconSize: [30, 30],
  // shadowSize:   [50, 64],
  // iconAnchor:   [22, 94],
  // shadowAnchor: [4, 62],
  // popupAnchor:  [-15, -90]
})

const customIcon = new L.Icon({
  iconUrl: IconMap,
  iconSize: [60, 60],
})
const redIcon = new L.Icon({
  iconUrl: RedMarker,
  iconSize: [50, 50],
  iconAnchor: [25, 25],
})

let dvIcon = (options) => {
  return L.divIcon({
    html: `
         <div className="bg-transparent" style="display: ${
           options?.showMarker !== false ? 'flex' : 'block'
         } ; gap: 7px; align-items: center">
            ${
              options?.showMarker !== false
                ? `<img src="${IconMap}" width="70" height="70" alt="icon"/>`
                : ''
            }
            <div class="p-1 bg-blue-500 text-white shadow-2 " style="${options?.style}">
              <strong >${options.label}</strong>
            </div>
         </div>
      `,
    iconAnchor: [7, 15],
    shadowAnchor: [4, 62],
    popupAnchor: [0, -15],
    iconSize: [15, 15],
    className: '',
  })
}

const createClusterCustomIcon = (cluster) => {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: 'my-custom-cluster', // Apply custom CSS styles
    iconSize: L.point(50, 50, true), // Set the size of the cluster icon
  })
}
const MapComponent = ({
  layers,
  actions,
  showToolbar,
  markerNameKey,
  itemDetailTemplate,
  itemTemplate,
  children,
  groups,
  groupsEnter,
  groupsStatus,
  pios,
  piosPosition,
  toolbarOptions,
  groupPioBy,
  onSaveGeofence,
  type,
  pioPopupTemplate,
}) => {
  const [showGeoman, setShowGeoman] = useState(true)
  const [showEditForm, setShowEditForm] = useState(false)
  const [showGeofences, setShowGeofences] = useState(false)
  const [showFilterOptions, setShowFilterOptions] = useState(false)
  const [currentLayer, setCurrentLayer] = useState(null)
  const [piosList, setPiosList] = useState([])
  const [showPios, setShowPios] = useState(true)
  const [currentHoveredItem, setCurrentHoveredItem] = useState(null)
  const [selectedPioIds, setSelectedPioIds] = useState([])
  const [selectedPioGroups, setSelectedPioGroups] = useState([])
  const [geoNavixy, setGeoNavixy] = useState([])
  const [geoLayers, setGeoLayers] = useState({})
  const sites = useAppSelector(getSites)
  const [geofences, setGeofences] = useState([])
  const [groupBy, setGroupBy] = useState('')
  const [groupByName, setGroupByName] = useState('')
  const [filterPioBy, setFilterPioBy] = useState('')
  const [filterByName, setFilterByName] = useState('')
  const [filterEtat, setFilterEtat] = useLocalStorage('ALL', 'etat')
  const [filterStatus, setFilterStatus] = useLocalStorage('ALL', 'statu')
  const [featureFilterType, setFeatureFilterType] = useState('')
  const [inputFilter, setInputFilter] = useState('')
  const [pointFilterLatLng, setPointFilterLatLng] = useState('')
  const [polygonFilterLayer, setPolygonFilterLayer] = useState([])
  const [filteredPiosList, setFilteredPiosList] = useState([])
  const [enginMarker, setEnginMarker] = useState(null)
  const [navCenter, setNavCenter] = useState(null)
  const [mapZoom, setMapZoom] = useState(false)
  const [zoom, setZoom] = useState(0)
  const selectedGeofence = useAppSelector(getSelectedGeoClient)
  const [selectedGeofencePropreties, setSelectedGeofencePropreties] = useState(null)

  const [realList, setRealList] = useState([])
  const mapRef = useRef(null)
  const piosRef = useRef(null)
  const editorRef = useRef(null)
  const pioDetailMap = useRef(null)
  const groupOverlayRef = useRef(null)
  const geoClustersRef = useRef(null)
  const filterOverlayRef = useRef(null)
  const filterOverRefEtat = useRef(null)
  const filterOverRefStatus = useRef(null)
  const dispatch = useDispatch()

  const [poiInfos, setPioInfos] = useState(null)
  const [selectedPio, setSelectedPio] = useState(null)
  const [geofencesClusters, setGeofencesClusters] = useState([])

  const selectedEnginMap = useAppSelector(getSelectedEnginMap)
  const lastEnginsUpdates = useAppSelector(getLastEnginsUpdates)
  const addressCompany = useAppSelector(getSelectedAddress)

  const [centerMap, setCenterMap] = useState([addressCompany?.lat || 0, addressCompany?.lng || 0])

  const MapEvents = () => {
    useMapEvent('click', (e) => {
      console.log('featureFilterType:', featureFilterType)
      if (featureFilterType == 'point') {
        setPointFilterLatLng(e.latlng)
        mapRef.current.flyTo(e.latlng)
      } else if (featureFilterType == 'polygon') {
      }
    })
    useMapEvent('zoom', (e) => {
      console.log('e.target._zoom:', e.target._zoom)
      setZoom(e.target._zoom)
    })
    useMapEvent('overlayadd', (e) => {
      console.log('event:', e)
    })
    useMapEvent('pm:create', (e) => {
      console.log('pm:create:', e)
      if (featureFilterType !== 'polygon') {
        setShowEditForm(true)
        setCurrentLayer(e)
      } else {
        setTimeout(() => {
          setPolygonFilterLayer(e)
        }, 10)

        setTimeout(() => {
          toggleButtons()
        }, 1000)
        e.layer.on('pm:remove', (e) => {
          console.log('removedddd')
          setPolygonFilterLayer(null)
        })
      }
    })
    useMapEvent('pm:globaleditmodetoggled', (e) => {
      console.log('globaleditmodetoggled:', e.target)
      if (!e.enabled) {
        setShowEditForm(true)
        // setCurrentLayer(e)
        setSelectedGeofencePropreties({})
      }
    })
    useMapEvent('pm:globalremovalmodetoggled', (e) => {
      console.log('globaleditmodetoggled:', e)
    })
  }

  const getPiosInPolygon = () => {
    console.log('polygonFilterLayer:', polygonFilterLayer)
    if (typeof polygonFilterLayer?.layer?.toGeoJSON != 'function') return _.cloneDeep(realList)

    let geojsonLayer = polygonFilterLayer?.layer?.toGeoJSON()
    let layer = polygonFilterLayer?.layer
    let options = {
      type: polygonFilterLayer.shape.toLowerCase(),
      radius: layer?.options?.radius,
      center: layer?._latlng,
    }

    let containedPios = findPointsInLayer(_.cloneDeep(realList), geojsonLayer, options)

    console.log('containedPios:', containedPios)

    return containedPios
  }

  function isPointInLayer(latlng, layer, options) {
    latlng = turf.point(Array.isArray(latlng) ? latlng : [latlng.lng, latlng.lat])
    let isInside = false

    if (!/^circle$/.test(options?.type || '')) {
      isInside = turf.inside(latlng, layer)
    } else {
      if (options?.center) {
        // let circle = turf.circle(turf.point(Array.isArray(options?.center) ? options?.center :[options?.center?.lng , options?.center?.lat]) , options?.radius);
        let center = turf.point(
          Array.isArray(options?.center)
            ? options?.center
            : [options?.center?.lng, options?.center?.lat]
        )
        isInside = turf.distance(center, latlng, {units: 'meters'}) <= options?.radius // turf.inside(latlng , circle)
        console.log('circle:', isInside, options?.radius)
      }
    }
    return isInside
  }

  function findPointsInLayer(featuresPoint, layer, options) {
    let insides = []
    for (let point of featuresPoint) {
      if (isPointInLayer(point, layer, options)) insides.push(point)
    }
    return insides
  }

  const onFilter = (text) => {
    console.log('text:', text)

    setInputFilter(text)
  }

  const toggleSelectPio = (id, add, item) => {
    setSelectedPioIds((prev) => {
      if (add) return [...prev, id]
      return prev.filter((pio) => pio != id)
    })
  }

  const toggleSelectPioGroup = (group, select) => {
    console.log('xxxx group:', group, select)
    let groupItem = piosList.find((f) => f.label == group)
    console.log('xxxx groupItem:', groupItem)
    if (groupItem) {
      let ids = groupItem.items.map((o) => o.id)
      setSelectedPioIds((prev) => {
        if (select) return [...prev, ...ids]
        return prev.filter((pio) => !ids.includes(pio))
      })

      setSelectedPioGroups((prev) => {
        if (select) return [...prev, group]
        return prev.filter((pio) => pio != group)
      })
    }
  }

  const filterByState = (stat) => {
    if (!Array.isArray(piosList)) return
    let labelDisplay = stat === 'reception' ? 'Enter' : stat === 'exit' ? 'Exit' : 'All'
    setFilterEtat(labelDisplay)
    if (stat === 'All') {
      let all = piosList?.[0]?.items.map((o) => o.uid)
      // const allIds = piosList[0].items.map((o) => o.uid)
      setSelectedPioIds(all)
      // setSelectedPioIds(allIds)
      return
    }
    if (filterStatus.label === 'All') {
      const filteredItems = piosList[0].items.filter((item) => item.etatenginname === stat)
      const filteredIds = filteredItems.map((item) => item.uid)
      setSelectedPioIds(filteredIds)
    } else {
      console.log('filterStatus:', filterStatus)
      const filteredItems = piosList[0].items.filter(
        (item) => item.etatenginname === stat && item.sysStatus == filterStatus.status
      )
      const filteredIds = filteredItems.map((item) => item.uid)
      setSelectedPioIds(filteredIds)
    }
  }

  const filterByStatus = (status) => {
    console.log('stat status:', status)
    if (!Array.isArray(piosList)) return
    let labelDisplay = typeof status?.label === 'object' ? 'All' : status?.label
    setFilterStatus({label: labelDisplay, status: status?.status})
    if (status.status === 'All') {
      let all = piosList?.[0]?.items.map((o) => o.uid)
      setSelectedPioIds(all)
      return
    }
    // if (stat === 'All') {
    //   const allIds = piosList[0].items.map((o) => o.uid)
    //   setSelectedPioIds(allIds)
    //   return
    // }
    if (filterEtat === 'All') {
      const filteredItems = piosList[0].items.filter((item) => item.sysStatus == status?.status)
      const filteredIds = filteredItems.map((item) => item.uid)
      setSelectedPioIds(filteredIds)
    } else {
      console.log('yyyy selectedPioIds:', selectedPioIds)
      let etat = filterEtat === 'Enter' ? 'reception' : 'exit'
      const getPiosSelected = piosList[0].items.filter((item) => item.etatenginname === etat)
      const filteredItems = getPiosSelected.filter((item) => item.sysStatus == status?.status)
      const filteredIds = filteredItems.map((item) => item.uid)
      setSelectedPioIds(filteredIds)
    }
  }

  const filterList = (list, options) => {
    if (!Array.isArray(list || realList)) return
    let filtredList = []
    let filter = filterPioBy || 'label'
    console.log('filtredList', filter)

    switch (options?.type) {
      case 'text':
        filtredList = (list || realList).filter(
          (pio) =>
            options?.val == '' ||
            (pio?.[filter] || '').toLowerCase().includes(options?.val?.toLowerCase())
        )
        break
      default:
        filtredList = null
    }

    console.log('filtredList:', filtredList)
    return filtredList
    setSelectedPioIds((prev) => filtredList.filter((o) => prev.includes(o.id)).map((o) => o.id))
    setPiosList(group(filtredList))
  }

  const group = (_list) => {
    let list = _.cloneDeep(_list)
    if (!Array.isArray(list)) list = []
    let isPointFilter =
      (featureFilterType == 'point' && pointFilterLatLng != null) ||
      (featureFilterType == 'polygon' && polygonFilterLayer != null)
    list.forEach((o) => {
      o[groupBy] =
        o[groupBy] == undefined || isPointFilter
          ? 'Non groupé' // groupBy !== 'Tout' && groupByName && !isPointFilter ? groupByName+ ' inconnu(e)': 'Principal'
          : o[groupBy]
    })
    let group = _.groupBy(list, groupBy)
    let groupList = []

    if (Object.keys(group).length == 0)
      group = {
        Principal: list,
      }

    for (let [k, v] of Object.entries(group)) {
      groupList.push({
        label: k,
        items: v,
      })
    }

    const noGroup = groupList.find((g) => g.label == 'Non groupé')
    if (noGroup) {
      groupList = groupList.filter((g) => g.label != 'Non groupé').concat([noGroup])
    }
    console.log('groupp:', groupList)
    return groupList
  }

  function displayGeofences() {
    if (Array.isArray(geofences)) {
      setGeoLayers({})
      if (geoClustersRef.current) geoClustersRef.current.clearLayers()
      setTimeout(() => {
        try {
          if (editorRef.current) editorRef.current.clearLayers()
          let locals = geofences.filter((geo) => geo.idnavixy == null)
          let layers = {}
          for (let o of locals) {
            let _layer = null
            if ((o.type || '').toLowerCase() == 'circle') {
              _layer = L.circle([...o.geometry.geometry.coordinates].reverse(), {
                radius: o.geometry?.properties?.radius,
                color: 'red',
                weight: 0.7,
                id: o.id,
                type: 'circle',
              })

              editorRef.current.addLayer(_layer)
              layers[o.id] = _layer
              continue
            }
            let geo = L.geoJSON(o.geometry, {color: 'red', weight: 0.7, id: o.id})
            geo.eachLayer((layer) => {
              _layer = layer
            })
            if (!_layer) continue
            _layer.on('pm:edit', (e) => {
              setCurrentLayer(e)
              // setShowEditForm(true)
            })
            _layer.on('pm:change', (e) => {
              if (Array.isArray(geofences)) {
                let dt = geofences.find((o) => o.id == e.layer?.options?.id)
                let id = dt.geoid
                dt = _.cloneDeep(dt)
                dt = dt?.properties
                if (dt) {
                  dt.id = id
                  if (typeof dt?.tags == 'string') dt.tags = dt.tags.split('|')
                }
                setSelectedGeofencePropreties(dt)
              }
              setCurrentLayer(e)
              // setShowEditForm(true)
            })
            _layer.on('pm:remove', (e) => {
              console.log('remove:', e)
            })
            //   editorRef.current.addLayer(_layer)
            layers[o.id] = _layer

            // mapRef.current.fitBounds(_layer.getBounds())
          }
          setGeoLayers(layers)
        } catch (e) {
          console.log('Error displaying geofences:' + e.message)
        }
      }, 300)
    }
  }

  const saveGeofenceEdit = useCallback(
    (data) => {
      console.log('data:', data, currentLayer)

      let _layer = currentLayer?.layer

      console.log('_layer:', _layer)

      if (!_layer?.toGeoJSON) return

      let layer = _layer.toGeoJSON()
      layer.properties = {...data, type: currentLayer.shape.toLowerCase()}

      if (Array.isArray(data.tags)) layer.properties.tags = data.tags.join('|')
      if (currentLayer.shape.toLowerCase() == 'circle') {
        layer.properties.radius = _layer?.options?.radius
      }

      console.log('layer:', layer)

      // return
      dispatch(saveGeofencing(layer)).then(({payload}) => {
        console.log('xx layer ', payload)
        if (!payload?.error) {
          _layer.remove()
          setShowEditForm(false)
          setCurrentLayer(null)
          dispatch(fetchGeofencings())
        }
      })
      if (typeof onSaveGeofence == 'function') {
        onSaveGeofence(layer, data)
      }
    },
    [onSaveGeofence, currentLayer]
  )

  const cancelGeofenceEdit = useCallback(
    (data) => {
      setShowEditForm(false)
      if (typeof currentLayer?.layer?.remove == 'function') currentLayer.layer.remove()
      setCurrentLayer(null)
    },
    [currentLayer]
  )
  //test
  const toggleOverlay = (ref, e) => {
    console.log('xxxxx ref:', ref, ' xxxxx e:', e)
    ref.current.toggle(e)
    console.log('xxxxx toggle:', ref.current.toggle(e))
  }

  const sortByNearest = (list) => {
    let lst = _.cloneDeep(list || realList)
    if (pointFilterLatLng == null) return lst

    lst.forEach((l) => {
      let distance = turf.distance([l.lat, l.lng], [pointFilterLatLng.lat, pointFilterLatLng.lng], {
        units: 'meters',
      })
      l.realDistance = distance
      l.distanceTo = distance >= 1000 ? (distance / 1000).toFixed(2) : distance.toFixed(2)
      l.distanceUnit = distance >= 1000 ? 'km' : 'm'
    })
    lst.sort((a, b) => a.realDistance - b.realDistance)
    return lst
  }

  const groupPiosBy = (group, name) => {
    setGroupBy(group)
    setGroupByName(name)
  }

  const toggleFeatureFilterType = (type) => {
    setFeatureFilterType((prev) => (prev == type ? '' : type))
  }

  const displayGeoClusters = () => {
    let clusters = []
    let i = 0
    console.log('vvvv:', Object.entries(geoLayers))
    for (let [k, v] of Object.entries(geoLayers)) {
      let cpt = 0
      console.log('filteredPiosListV:', filteredPiosList)
      let _pioIds = []
      let _pios = []
      for (let o of filteredPiosList) {
        console.log('oiNside:', o)
        if (
          isPointInLayer([o.lng, o.lat], v.toGeoJSON(), {
            type: v?.options?.type,
            center: v?.options?.type == 'circle' ? v?._latlng : null,
            radius: v?.options?.radius,
          })
        ) {
          cpt++
          _pioIds.push(o.id)
          _pios.push(o)
        }
      }
      if (typeof v.getBounds == 'function') {
        console.log('vvvvv:', i, v)
        let latlng = v?._latlng || v.getBounds().getCenter()
        clusters.push({
          latlng: latlng,
          count: cpt,
          layer: k,
          poiIds: _pioIds,
          pios: _pios,
        })
      }
      i++
    }

    setGeofencesClusters(clusters)
  }
  const familleTagTemplate = (rowData) => {
    return (
      <Chip
        title={rowData.tagId != 0 ? `Tagged  ${rowData?.tagDate}` : 'No Tag'}
        alt={rowData.tagId != 0 ? `Tagged  ${rowData?.tagDate}` : 'No Tag'}
        icon={rowData.familleIconTag}
        style={{background: rowData.familleTagIconBgcolor, color: rowData.familleTagIconColor}}
      />
    )
  }

  useEffect(() => {
    displayGeofences()
  }, [geofences, editorRef.current])

  const fetchNavixy = async (idnavixy, onFetch) => {
    let res = await dispatch(fetchPointsGeo(idnavixy))
    if (res.meta.requestStatus === 'fulfilled') {
      const points = res.payload.list
      console.log('pointsTest:', points)
      let coordinates = points?.map((point) => [point.lng, point.lat])
      coordinates?.push([points[0]?.lng, points[0]?.lat])
      let polygon = turf.polygon([coordinates])
      polygon = L.polygon(coordinates)
      polygon.pm.disable(true)
      setGeoLayers((prev) => ({...prev, [selectedGeofence?.id]: polygon}))
      if (typeof onFetch == 'function') onFetch(polygon)
    }
  }

  useEffect(() => {
    setShowGeoman(
      !showEditForm &&
        showToolbar !== false &&
        (type != 'main' || (type == 'main' && featureFilterType == 'polygon'))
    )
  }, [showEditForm, showToolbar, type, featureFilterType])
  useEffect(() => {
    if (Array.isArray(realList) && realList?.length > 0) {
      let list = getPiosInPolygon()
      list = filterList(list, {type: 'text', val: inputFilter})
      list = sortByNearest(list)
      list = group(list)
      setPiosList(list)
      setMapZoom(true)
    } else {
      setPiosList([])
      setMapZoom(false)
    }
  }, [realList, groupBy, polygonFilterLayer, pointFilterLatLng, inputFilter])

  useEffect(() => {
    setGroupBy(groupPioBy || '')
  }, [groupPioBy])

  useEffect(() => {
    if (mapRef.current && piosRef.current && mapZoom) {
      setTimeout(() => {
        mapRef.current.fitBounds(piosRef.current.getBounds())
      }, 1000)
    }
  }, [mapZoom, filterStatus, filterEtat])

  useEffect(() => {
    if (featureFilterType !== 'polygon' && polygonFilterLayer != null) {
      polygonFilterLayer?.layer?.remove()
      setPolygonFilterLayer(null)
    }
    if (featureFilterType !== 'point' && pointFilterLatLng) {
      setPointFilterLatLng(null)
    }
  }, [featureFilterType, polygonFilterLayer, pointFilterLatLng])

  useEffect(() => {
    if (Array.isArray(pios)) {
      let lst = _.cloneDeep(pios)
      setRealList(lst)
    } else {
      setRealList([])
    }
  }, [pios])

  useEffect(() => {
    if (poiInfos) {
      let eng = lastEnginsUpdates.find((o) => o.id == poiInfos?.id)
      if (eng && eng.lastSeenAt != poiInfos?.lastSeenAt) {
        setPioInfos((prev) => ({...prev, lastSeenAt: eng.lastSeenAt}))
      }
    }
  }, [lastEnginsUpdates, poiInfos])

  useEffect(() => {
    localStorage.setItem('selected-engin-in-map', poiInfos?.id || 0)
  }, [poiInfos])

  function getDetailEngin(o) {
    setPioInfos(o)
    dispatch(setSelectedEnginMap(null))
    if (o.lat != (0 || -1) && mapRef.current) {
      setEnginMarker([o.lat, o.lng])
      mapRef.current.flyTo([o.lat, o.lng], 18)
    }
  }

  useEffect(() => {
    dispatch(fetchGeofencings())
    dispatch(fetchSites())
  }, [])

  const toggleButtons = () => {
    let circleElement = document.querySelector(
      '[title="Draw Circle"] > .leaflet-buttons-control-button'
    )
    let polygonElement = document.querySelector(
      '[title="Draw Polygons"] > .leaflet-buttons-control-button'
    )
    console.log('featureFilterType:', featureFilterType, circleElement, polygonElement)

    if (circleElement) {
      if (featureFilterType == 'polygon') {
        circleElement.classList.add('bg-blue-300')
      } else {
        circleElement.classList.remove('bg-blue-300')
      }
    }
    if (polygonElement) {
      if (featureFilterType == 'polygon') {
        polygonElement.classList.add('bg-blue-300')
      } else {
        polygonElement.classList.remove('bg-blue-300')
      }
    }
  }

  useEffect(() => {
    toggleButtons()
  }, [featureFilterType])

  useEffect(() => {
    if (selectedGeofence && mapRef.current && editorRef.current) {
      let idnavixy = selectedGeofence?.geofence?.[0]?.idnavixy
      if (idnavixy !== 0 && !geoLayers?.[selectedGeofence?.id]) {
        fetchNavixy(idnavixy, (po) => {
          if (po) {
            editorRef.current.clearLayers()
            editorRef.current.addLayer(po)
            mapRef.current.fitBounds(po.getBounds())
          }
        })
      } else {
        let layer = geoLayers?.[selectedGeofence?.id]
        if (layer) {
          editorRef.current.clearLayers()
          editorRef.current.addLayer(layer)
          mapRef.current.fitBounds(layer.getBounds())
        }
      }
    }
  }, [selectedGeofence])

  useEffect(() => {
    const filter = sites
      ?.filter((o) => {
        return o?.geofence.length > 0
      })
      .map((o) => ({...o?.geofence[0]?.geometry, id: o.id, geoid: o?.geofence[0]?.id}))
    setGeofences(filter)
  }, [sites])

  useEffect(() => {
    displayGeoClusters()
  }, [geoLayers, filteredPiosList])

  useEffect(() => {
    if (selectedEnginMap !== null && piosList.length > 0) {
      const findObject = piosList[0]?.items?.find((o) => o.uid === selectedEnginMap.uid)
      const index = piosList[0]?.items?.findIndex((o) => o.uid === selectedEnginMap.uid)
      if (index !== -1 && findObject) {
        const updatedPiosList = [
          findObject,
          ...(piosList[0]?.items?.slice(0, index) || []),
          ...(piosList[0]?.items?.slice(index + 1) || []),
        ]

        setPiosList([{...piosList[0], items: updatedPiosList}, ...piosList.slice(1)])
      }
    }
  }, [selectedEnginMap])

  useEffect(() => {
    let timer = setTimeout(() => {
      let groups = piosList.map((o) => o.label)
      let infos = piosList.reduce(
        (c, v) => {
          c.ids = [...c.ids, ...v.items.map((t) => t.id)]
          c.list = [...c.list, ...v.items]
          return c
        },
        {ids: [], list: []}
      )
      let etatConvert = filterEtat === 'Exit' ? 'exit' : 'reception'
      let filtredId = infos.list.filter((o) => o?.etatenginname == etatConvert).map((o) => o.id)
      setSelectedPioGroups(groups)
      setSelectedPioIds(filtredId)
      setFilteredPiosList(infos.list)
    }, 100)

    return () => clearTimeout(timer)
  }, [piosList])

  // useEffect(() => {
  //   if (addressCompany && addressCompany?.lat) {
  //     const lat = addressCompany?.lat || 46.933295342561046
  //     const lng = addressCompany?.lng || 7.454324562997108
  //     setCenterMap([lat, lng])
  //     if (mapRef.current) {
  //       mapRef.current.setView([lat, lng], 18)
  //     }
  //   }
  // }, [mapRef.current])

  return (
    <div className='relative'>
      <div
        style={{
          position: 'absolute',
          height: '400px',
          minWidth: '100%',
          width: 'auto',
          boxSizing: 'border-box',
        }}
      >
        {showEditForm && (
          <div
            style={{
              position: 'absolute',
              height: '400px',
              minWidth: '100%',
              top: '50px',
              width: 'auto',
              boxSizing: 'border-box',
              right: '7px',
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                zIndex: 10,
                padding: '3px',
                top: '0.5rem',
                right: '0.5rem',
              }}
            >
              <GeofenceEditorComponent
                _inputs={selectedGeofencePropreties}
                onSave={saveGeofenceEdit}
                onCancel={cancelGeofenceEdit}
              />
            </div>
          </div>
        )}
        {!showEditForm && (
          <div
            style={{
              position: 'absolute',
              height: '400px',
              minWidth: '100%',
              top: showGeoman ? '4rem' : '0.5rem',
              width: 'auto',
              boxSizing: 'border-box',
              right: '0.5rem',
            }}
          >
            <div
              className=''
              style={{
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                zIndex: 10,
                padding: '3px',
                top: '0.5rem',
                right: '0.5rem',
              }}
            >
              <div
                className={
                  showGeofences ? 'bg-white text-right p-2 border-bottom-1 border-gray-100' : ''
                }
              >
                {showGeofences && (
                  <span
                    title='Cacher'
                    className='pi pi-align-right'
                    onClick={() => setShowGeofences(false)}
                  ></span>
                )}
                {!showGeofences && (
                  <ButtonComponent
                    title='Liste des sites'
                    icon={`pi pi-list`}
                    onClick={() => setShowGeofences(true)}
                  />
                )}
              </div>
              {showGeofences && <GeofenceListComponent />}
            </div>
          </div>
        )}
        {Array.isArray(piosList) && Array.isArray(pios) && (
          <div
            className='p-2'
            style={{
              position: 'absolute',
              transition: 'all 1s',
              cursor: 'auto',
              width: 'auto',
              height: '800px',
              zIndex: 4,
              top: '0.5rem',
              left: '0.5rem',
            }}
          >
            {showPios ? (
              <div className='flex gap-2'>
                <div className='bg-white' style={{width: '400px'}}>
                  <div className=' p-2 border-bottom-1 border-gray-100 py-2 flex align-items-center justify-content-between'>
                    <span
                      className='pi pi-align-left'
                      onClick={() => setShowPios((prev) => !prev)}
                      title='Cacher liste'
                    ></span>
                    <span
                      onClick={() => setShowFilterOptions((p) => !p)}
                      className='pi pi-cog'
                      title='Options de filtre'
                    ></span>
                  </div>
                  {showFilterOptions && (
                    <div>
                      <div className='flex gap-2 p-2 filter-options border-bottom-1 border-gray-100'>
                        <div className='border-right-1 pr-2 border-gray-300'>
                          <Chip
                            onClick={(e) => toggleOverlay(groupOverlayRef, e)}
                            label={`Grouper par: ${groupByName}`}
                            icon='pi pi-user'
                            className='cursor-pointer'
                          />
                          <OverlayPanel className='p-0' ref={groupOverlayRef}>
                            <div className={`-m-3`} style={{minWidth: '100px'}}>
                              {[{label: 'Pas de groupe', value: 'Tout'}, ...(groups || [])].map(
                                (s, i) => (
                                  <div
                                    onClick={(e) => {
                                      groupPiosBy(s?.value || s, s?.label || s)
                                      toggleOverlay(groupOverlayRef, e)
                                    }}
                                    className={`p-2 flex gap-2 align-items-center bg-blue-${
                                      groupBy == (s?.value || s) && 100
                                    } hover:bg-blue-100 border-top-${
                                      i != 0 && 1
                                    } border-gray-200 cursor-pointer`}
                                  >
                                    <span
                                      className={`pi pi-circle-fill text-${
                                        s?.label == 'Pas de groupe' ? 'red' : 'blue'
                                      }-300`}
                                    ></span>
                                    <span> {s?.label || s} </span>
                                  </div>
                                )
                              )}
                            </div>
                          </OverlayPanel>
                        </div>
                        <div className='flex gap-3 align-items-center'>
                          <span
                            title="Permet de filter par point le plus proche. Choisissez l'option puis cliquer sur la carte"
                            onClick={(e) => toggleFeatureFilterType('point')}
                            className={`pi pi-map-marker text-xl text-blue-${
                              featureFilterType == 'point' && 400
                            }`}
                          ></span>
                          <span
                            title="filtrer par point a l'interieur d'une zone. dessiner un polygon/cercle sur la carte"
                            onClick={(e) => toggleFeatureFilterType('polygon')}
                            className={`pi pi-map text-xl text-blue-${
                              featureFilterType == 'polygon' && 400
                            }`}
                          ></span>
                        </div>
                      </div>
                      <div className='flex flex-row gap-2 p-2 filter-options border-bottom-1 border-gray-100'>
                        <div className='border-right-1 pr-2 border-gray-300'>
                          <Chip
                            onClick={(e) => toggleOverlay(filterOverlayRef, e)}
                            label={`Filtrer par: ${filterByName}`}
                            icon='pi pi-user'
                            className='cursor-pointer'
                          />
                          <OverlayPanel className='p-0 ' ref={filterOverlayRef}>
                            <div className={`-m-3`} style={{minWidth: '100px'}}>
                              {[{label: 'Libélé', value: ''}, ...(groups || [])].map((s, i) => (
                                <div
                                  onClick={(e) => {
                                    setFilterPioBy(typeof s == 'string' ? s : s?.value)
                                    setFilterByName(typeof s == 'string' ? s : s?.label)
                                    toggleOverlay(filterOverlayRef, e)
                                  }}
                                  className={`p-2 flex gap-2 align-items-center bg-blue-${
                                    groupBy == (s?.value || s) && 100
                                  } hover:bg-blue-100 border-top-${
                                    i != 0 && 1
                                  } border-gray-200 cursor-pointer`}
                                >
                                  <span className={`pi pi-circle-fill text-blue-300`}></span>
                                  <span>{s?.label || s} </span>
                                </div>
                              ))}
                            </div>
                          </OverlayPanel>
                        </div>
                        <div className='flex gap-3 align-items-center'>
                          <Chip
                            onClick={(e) => toggleOverlay(filterOverRefEtat, e)}
                            label={<OlangItem olang={filterEtat} />}
                            icon='fas fa-regular fa-filter-list'
                            className='cursor-pointer'
                          />
                          <OverlayPanel className='p-0 ' ref={filterOverRefEtat}>
                            <div className={`-m-3`} style={{minWidth: '100px'}}>
                              {[
                                {label: <OlangItem olang='All' />, value: 'All', icon: 'fa-ban'},
                                ...(groupsEnter || []),
                              ].map((s, i) => (
                                <div
                                  key={i}
                                  onClick={(e) => {
                                    filterByState(s?.value || s)
                                    toggleOverlay(filterOverRefEtat, e)
                                  }}
                                  className={`p-2 flex gap-2 align-items-center bg-blue-${
                                    groupBy == (s?.value || s) && 100
                                  } hover:bg-blue-100 border-top-${
                                    i != 0 && 1
                                  } border-gray-200 cursor-pointer`}
                                >
                                  <i
                                    className={`fas fa-solid ${s?.icon} ${
                                      'text-' + s?.color + '-300'
                                    }`}
                                  ></i>
                                  {/* <span className={`pi pi-circle-fill text-blue-300`}></span> */}
                                  <span>{s?.label || s} </span>
                                </div>
                              ))}
                            </div>
                          </OverlayPanel>
                        </div>
                        <div className='flex gap-3 align-items-center'>
                          <Chip
                            onClick={(e) => toggleOverlay(filterOverRefStatus, e)}
                            label={<OlangItem olang={filterStatus.label} />}
                            icon='fas fa-regular fa-filter-list'
                            className='cursor-pointer'
                          />
                          <OverlayPanel className='p-0 ' ref={filterOverRefStatus}>
                            <div className={`-m-3`} style={{minWidth: '100px'}}>
                              {[
                                {label: <OlangItem olang='All' />, status: 'All', icon: 'fa-ban'},
                                ...(groupsStatus || []),
                              ].map((s, i) => (
                                <div
                                  key={i}
                                  onClick={(e) => {
                                    filterByStatus(s)
                                    toggleOverlay(filterOverRefStatus, e)
                                  }}
                                  className={`p-2 flex gap-2 align-items-center bg-blue-${
                                    groupBy == (s?.label || s) && 100
                                  } hover:bg-blue-100 border-top-${
                                    i != 0 && 1
                                  } border-gray-200 cursor-pointer`}
                                >
                                  <i
                                    style={{color: s?.backgroundColor}}
                                    className={`fas fa-solid ${s?.icon}`}
                                  ></i>
                                  {/* <span className={`pi pi-circle-fill text-blue-300`}></span> */}
                                  <span>{s?.label || s} </span>
                                </div>
                              ))}
                            </div>
                          </OverlayPanel>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className='p-2 border-bottom-1 border-gray-100 py-2'>
                    <span className='p-input-icon-right p-input-icon-left  w-full '>
                      <i className='pi pi-search' />
                      <InputText
                        onChange={(e) => onFilter(e.target.value)}
                        className='w-full border-0'
                        placeholder='Search'
                      />
                    </span>
                  </div>
                  <div style={{maxHeight: '800px', overflow: 'auto'}}>
                    {piosList.map((_pio) => (
                      <div>
                        <div className='px-2 py-2 hover:bg-blue-100   flex gap-2 align-items-center justify-content-between  bg-blue-50'>
                          <div className='flex gap-2 align-items-center '>
                            <Checkbox
                              onChange={(e) =>
                                toggleSelectPioGroup(
                                  _pio.label,
                                  !selectedPioGroups.includes(_pio.label)
                                )
                              }
                              checked={selectedPioGroups.includes(_pio.label)}
                            />
                            <strong style={{fontSize: '20px'}} className='text-blue-500'>
                              {_pio.label}
                            </strong>
                          </div>
                          <div>
                            <span className='pi pi-chevron-down'></span>
                          </div>
                        </div>
                        <div className=''>
                          {(_pio.items || []).map((pio) => (
                            <div
                              //onMouseEnter={(e) => setCurrentHoveredItem(pio.id)}
                              //onMouseLeave={(e) => setCurrentHoveredItem(null)}
                              className='px-3 border-left-3 border-blue-300  py-3 flex gap-2 align-items-center justify-content-between'
                              style={{
                                marginBottom: '1px',
                                backgroundColor:
                                  (selectedEnginMap?.uid || poiInfos?.uid) == pio?.uid
                                    ? 'rgba(82, 63, 141, 0.4)'
                                    : '',
                                borderRadius:
                                  (selectedEnginMap?.uid || poiInfos?.uid) == pio?.uid ? '5px' : '',
                                borderColor:
                                  (selectedEnginMap?.uid || poiInfos?.uid) == pio?.uid
                                    ? '#523F8D'
                                    : '',
                                border:
                                  (selectedEnginMap?.uid || poiInfos?.uid) == pio?.uid
                                    ? '1px solid #523F8D'
                                    : '',
                              }}
                            >
                              <div
                                className='flex gap-2 align-items-center'
                                style={{minWidth: '50%'}}
                              >
                                {/* checkbox */}
                                <div className='checkbox'>
                                  <Checkbox
                                    onChange={(e) =>
                                      toggleSelectPio(pio.id, !selectedPioIds.includes(pio.id))
                                    }
                                    checked={selectedPioIds.includes(pio.id)}
                                  />
                                </div>
                                {/* image */}
                                <div className='image'>
                                  <div
                                    style={{fontSize: '15px', width: '180px'}}
                                    onClick={(e) =>
                                      toggleSelectPio(pio.id, !selectedPioIds.includes(pio.id))
                                    }
                                  >
                                    {typeof itemTemplate == 'function'
                                      ? itemTemplate(pio)
                                      : pio.label}
                                  </div>
                                </div>
                                {/* mouvement status */}
                                <div className='mouvement-status mx-1 '>
                                  <i
                                    title={`${pio?.etatengin} ${pio?.locationDate}`}
                                    className={pio.etatIconName + ' text-2xl'}
                                    style={{color: pio.etatbgColor}}
                                  />
                                </div>
                                {/* Status engin */}
                                <div className='status-engin mx-1'>
                                  <i
                                    title={pio?.statuslabel}
                                    className={pio.iconName + ' text-2xl'}
                                    style={{color: pio.statusbgColor}}
                                  />
                                </div>
                                {/* famille tag */}
                                <div className='tag-famille mx-1'>
                                  {pio.tagId ? (
                                    <i
                                      title={pio?.familleTag}
                                      className={pio.familleIconTag + ' text-2xl'}
                                      style={{color: pio.familleTagIconBgcolor}}
                                    />
                                  ) : (
                                    <span className='badge badge-secondary border-radius-2'>
                                      <OlangItem olang='No.tag' />
                                    </span>
                                  )}
                                </div>
                                <div class='mx-2 flex'>
                                  {/* distance */}
                                  {!isNaN(pio?.distanceTo) && (
                                    <span className='mr-2 text-sm '>
                                      {pio?.distanceTo} {pio?.distanceUnit}
                                    </span>
                                  )}
                                </div>
                              </div>
                              {/* detail engin */}
                              <div className='flex gap-4'>
                                <span
                                  className='pi pi-info-circle cursor-pointer'
                                  onClick={() => getDetailEngin(pio)}
                                ></span>
                                {/* <span className='pi pi-ellipsis-v'></span> */}
                              </div>
                              {/* <div>
                                {!isNaN(pio?.distanceTo) && (
                                  <span className='mr-2 text-sm '>
                                    {pio?.distanceTo} {pio?.distanceUnit}
                                  </span>
                                )}
                                {currentHoveredItem == pio.id ? (
                                  <div className='flex gap-4'>
                                    <span
                                      className='pi pi-info-circle'
                                      onClick={() => getDetailEngin(pio)}
                                    ></span>
                                    <span className='pi pi-ellipsis-v'></span>
                                  </div>
                                ) : (
                                  <span className='pi pi-circle-on text-blue-500'></span>
                                )}
                              </div> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                {poiInfos && (
                  <div className='pio-detail-component relative'>
                    {typeof itemDetailTemplate == 'function' ? (
                      itemDetailTemplate(poiInfos)
                    ) : (
                      <div>
                        <span
                          className='pi pi-times absolute text-red-500 cursor-pointer'
                          onClick={(e) => {
                            setPioInfos(null)
                            setSelectedPio(null)
                          }}
                          style={{top: '10px', right: '10px', fontSize: '20px', zIndex: 10}}
                        ></span>
                        <div className=' bg-white' style={{width: '400px'}}>
                          <div>
                            {/* <MapContainer
                              minZoom={1}
                              maxZoom={22}
                              zoom={15}
                              zoomControl={false}
                              center={[poiInfos.lat, poiInfos.lng]}
                              ref={pioDetailMap}
                              style={{width: '100%', height: '170px'}}
                            >
                              <TileLayer
                                maxNativeZoom={18}
                                minZoom={1}
                                maxZoom={22}
                                attribution='&copy openstreetmap'
                                url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                                subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                              />
                            </MapContainer> */}
                            <div className='flex w-full justify-content-center align-items-center'>
                              <Image
                                src={assetConfigs.asset_server_url + poiInfos.image}
                                alt='EngineImage'
                                width='200'
                                height='200'
                                preview
                                imageStyle={{
                                  objectFit: 'cover',
                                  borderRadius: '10px',
                                }}
                              />
                            </div>
                          </div>
                          <div className='p-2'>
                            <div className='flex justify-content-between'>
                              <strong>{poiInfos?.reference}</strong>
                              <div>
                                <span className='pi pi-cog'></span>
                              </div>
                            </div>
                            <div className='flex gap-4 flex-wrap mt-4'>
                              <div className='flex gap-2 align-items-center'>
                                <i
                                  title={poiInfos?.statuslabel}
                                  className={poiInfos.iconName + ' text-xl'}
                                  style={{color: poiInfos.statusbgColor}}
                                />
                                <strong>{poiInfos?.statuslabel}</strong>
                              </div>
                              <div className='flex gap-2  align-items-center'>
                                <i className='fa-solid fa-battery-full text-green-500'></i>
                                <strong>
                                  {poiInfos?.batteries > 100 ? '100%' : poiInfos?.batteries + '%'}
                                </strong>
                              </div>
                              <div className='flex gap-2 align-items-center'>
                                <span className='pi pi-wifi  text-green-500'></span>
                                <strong>{'Good'}</strong>
                              </div>
                              {!poiInfos?.sysMode === 'gateway' && (
                                <div className='flex gap-2 align-items-center'>
                                  <span className='pi pi-user text-green-500'></span>
                                  <strong>{poiInfos?.lastUser}</strong>
                                </div>
                              )}
                            </div>
                            <div className='mt-4'>
                              <div className='flex gap-2 mb-3 align-items-center'>
                                <div className='w-1'>
                                  <i
                                    title={poiInfos?.etatengin}
                                    className={poiInfos.etatIconName + ' text-2xl'}
                                    style={{color: poiInfos.etatbgColor}}
                                  ></i>
                                </div>
                                <div className='w-11'>
                                  <strong className='text-lg'>
                                    {poiInfos?.LocationObjectname}
                                  </strong>
                                  <span className='text-gray-600 pl-2'>
                                    {poiInfos?.lastSeenAt
                                      ? moment(poiInfos.lastSeenAt).format('DD/MM/YYYY HH:mm:ss')
                                      : poiInfos?.locationDate}
                                  </span>
                                </div>
                              </div>
                              <div className='flex gap-2 mb-3 align-items-center'>
                                <strong className='pl-2'>
                                  <OlangItem olang='Mode' /> {poiInfos?.lastDetectionMode}
                                </strong>
                                <span
                                  className={`${
                                    poiInfos?.lastDetectionMode == 'mob'
                                      ? 'pi pi-mobile text-lg text-green-500 pl-2'
                                      : null
                                  }`}
                                ></span>
                              </div>
                              <div className='flex gap-2 mb-3 align-items-center'>
                                <OlangItem olang='Address' />
                                <strong className='pl-2'>{poiInfos?.enginAddress}</strong>
                              </div>
                              <div className='flex gap-2 mb-3 align-items-center'>
                                {/* <div className='flex gap-2 align-items-center'>
                                  <strong>Mode: mobile</strong>
                                  <span className='pi pi-mobile text-lg text-green-500'></span>
                                </div> */}
                                {false && (
                                  <div className='w-11'>
                                    <strong className='block text-lg'>Test</strong>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <ButtonComponent
                icon='pi pi-align-left'
                onClick={() => setShowPios((prev) => !prev)}
                title='Liste des engins'
              />
            )}
          </div>
        )}
      </div>
      <MapContainer
        ref={mapRef}
        minZoom={1}
        maxZoom={22}
        zoom={10}
        zoomControl={false}
        center={[addressCompany?.lat || 0, addressCompany?.lng || 0]}
        style={{width: '100%', height: '100vh'}}
      >
        <MapEvents />
        {/* <TileLayer
          maxNativeZoom={18}
          minZoom={1}
          maxZoom={22}
          attribution='&copy openstreetmap'
          url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'
          subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
        /> */}

        <BaseMapLayerComponent top={60} right={15} />
        <FeatureGroup ref={editorRef}></FeatureGroup>

        <MarkerClusterGroup
          ref={piosRef}
          maxClusterRadius={150}
          iconCreateFunction={createClusterCustomIcon}
        >
          {realList?.map((pio) => {
            if (
              pio &&
              pio?.lat != (0 || -1) &&
              !isNaN(pio?.lat) &&
              !isNaN(pio?.lng) &&
              selectedPioIds.includes(pio.id)
            )
              return (
                <Marker
                  eventHandlers={{
                    click: (e) => {
                      dispatch(setSelectedEnginMap(pio))
                    },
                  }}
                  position={{lat: pio?.lat, lng: pio?.lng}}
                  // icon={dvIcon({label: pio[markerNameKey] || pio?.label})}
                  icon={redIcon}
                >
                  <Popup>
                    {typeof pioPopupTemplate == 'function'
                      ? pioPopupTemplate(pio)
                      : pio[markerNameKey] || pio?.label}
                  </Popup>
                </Marker>
              )
            else return null
          })}
        </MarkerClusterGroup>
        {navCenter ? <Polygon positions={geoNavixy} /> : null}
        {enginMarker ? <Marker position={enginMarker} icon={customIcon} /> : null}
        <FeatureGroup>
          {/* <Polygon positions={[polygonFilterLatLngs]} /> */}
          {pointFilterLatLng && <Marker position={pointFilterLatLng} icon={tagIcon} />}
        </FeatureGroup>
        <GeomanComponent
          actions={Array.isArray(actions) ? actions : ['polygon']}
          show={showGeoman}
        />
        <ZoomControl position='bottomright' />
        {children}
      </MapContainer>
    </div>
  )
}

export default MapComponent
