import React, {useEffect, useMemo, useRef, useState} from 'react'
import CardLogsList from './CardLogsList'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import {
  fetchEnginByTag,
  fetchLogList,
  fetchStaffList,
  getLogList,
  getLogsTrack,
  getStaffList,
  setListDetail,
  setListTagLogs,
  setShowDetail,
} from '../slice/logs.slice'
import DetailLogs from './DetailLogs/DetailLogs'
import {Calendar} from 'primereact/calendar'
import moment from 'moment'

import _ from 'lodash'

import {MultiSelect} from 'primereact/multiselect'
import LogMapDetail from './LogMapDetail/LogMapDetail'

import './style.css'
import Subheader from '../../shared/Subheader/Subheader'
import {Button} from 'primereact/button'
import {Chip} from 'primereact/chip'
import {OverlayPanel} from 'primereact/overlaypanel'
import {Checkbox} from 'primereact/checkbox'
import {Divider} from 'primereact/divider'
import {DialogComponent} from '../../shared/DialogComponent/DialogComponent'
import {Dialog} from 'primereact/dialog'
import {InputNumber} from 'primereact/inputnumber'
import ButtonComponent from '../../shared/ButtonComponent/ButtonComponent'
import {Dropdown} from 'primereact/dropdown'
import {InputSwitch} from 'primereact/inputswitch'
import {fetchEngines, getEngines} from '../../Engin/slice/engin.slice'
const LogsTrackingList = () => {
  const [loading, setLoading] = useState(false)
  const [loadingDetail, setLoadingDetail] = useState(false)
  const [selectedStaff, setSelectedStaff] = useState([])
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [filter, setFilter] = useState({})

  const indexClick = useRef(null)
  let [entries, setEntries] = useState([])
  let [selected, setSelected] = useState(null)
  let [sources, setSources] = useState([])
  const [pageIndex, setPageIndex] = useState(0)
  let [selectedSources, setSelectedSources] = useState([])
  let [selectedSourceNames, setSelectedSourceNames] = useState([])
  let [showSettings, setShowSettings] = useState(false)
  const [selectedEngins, setSelectedEngins] = useState([])
  let [selectedEnginNames, setSelectedEnginNames] = useState([])

  const usersRef = useRef()
  const dateFromRef = useRef()
  const dateToRef = useRef()
  const enginRef = useRef()

  const listLogs = useAppSelector(getLogList)
  const staffList = useAppSelector(getStaffList)
  const engins = useAppSelector(getEngines)

  const dispatch = useAppDispatch()

  console.log('listLogs:', listLogs)

  const onShowDetail = (list, index) => {
    indexClick.current = index
    setLoadingDetail(true)
    dispatch(setListDetail(list))
    const uniqueTg = _.uniqBy(list, 'macAddr')
    dispatch(setListTagLogs(uniqueTg))
    const tags = list?.map((o) => {
      return {
        macAddr: o.macAddr,
      }
    })
    dispatch(fetchEnginByTag(tags)).then(({payload}) => {
      if (payload) {
        dispatch(setShowDetail(true))
        setLoadingDetail(false)
      } else {
        setLoadingDetail(false)
      }
    })
    // dispatch(setListDetail(list))
    // dispatch(setShowDetail(true))
  }

  const onSearch = () => {
    setLoading(true)
    let users = (selectedStaff || [])
      .filter((o) => o)
      .map((o) => {
        return {userId: o}
      })
    let options = {userFilter: users || [], maxSecond: 0}
    if (filter.from && typeof filter.from != 'string')
      options.begDate = moment(filter.from).format('YYYY-MM-DD HH:mm:ss')
    if (filter.to && typeof filter.to != 'string')
      options.endDate = moment(filter.to).format('YYYY-MM-DD HH:mm:ss')

    console.log('obj search:', options)
    dispatch(fetchLogList(options)).then(() => {
      setLoading(false)
      setPageIndex(0)
    })
  }

  const toggleSelectSource = (id) => {
    setSelectedSources((prev) => {
      let t = _.cloneDeep(prev)
      if (!Array.isArray(t)) t = []

      if (t.includes(id)) t = id === '' ? [] : t.filter((i) => i != id)
      else {
        if (id === '') t = [...sources.map((o) => o.id), id]
        else t.push(id)
      }
      return t
    })
  }

  const toggleSelectEngin = (id) => {
    setSelectedEngins((prev) => {
      let t = _.cloneDeep(prev)
      if (!Array.isArray(t)) t = []
      console.log('selectedEngins iddd:', id)
      if (t.includes(id)) t = id === '' ? [] : t.filter((i) => i != id)
      else {
        if (id === '') t = [...engins.map((o) => +o.id), id]
        else t.push(+id)
      }
      return t
    })
  }

  useEffect(() => {
    const today = moment().format('YYYY-MM-DDTHH:mm:ss')
    const fiveDaysBefore = moment().subtract(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss')
    let obj = {
      maxSecond: 0,
      begDate: fiveDaysBefore,
      endDate: today,
    }
    setLoading(true)
    dispatch(fetchLogList(obj)).then((payload) => {
      setLoading(false)
    })
    dispatch(fetchStaffList())
    dispatch(fetchEngines())
  }, [])

  const toggleOverlay = (ref, e) => {
    if (!ref?.current) return
    ref.current.toggle(e)
  }

  const onInput = (key, value) => {
    let prevFilter = _.cloneDeep(filter)
    if (!_.isPlainObject(prevFilter)) prevFilter = {}
    setFilter({...prevFilter, [key]: value})
  }

  const saveSettings = () => {
    setShowSettings(false)
  }
  const settingsModalFooter = (
    <div>
      <Divider />
      <Button onClick={saveSettings}>Enregistrer</Button>
    </div>
  )

  useEffect(() => {
    if (Array.isArray(listLogs) && listLogs?.length > 0) {
      let dt = _.cloneDeep(listLogs)
      let $filter =
        selectedSources?.length == 0 || !Array.isArray(selectedSources)
          ? dt
          : dt.filter((log) => selectedSources.includes(+log.userID || log.gateway))
      if (Array.isArray(selectedEngins) && selectedEngins?.length > 0)
        $filter = $filter.filter((log) => selectedEngins.includes(+log.enginId))
      $filter = $filter.sort((a, b) => b.date - a.date)
      const groupedByDate = _.groupBy($filter, (s) => `${s.date}_${s.userID}`)
      setEntries(Object.entries(groupedByDate).filter(([, val]) => val.length > 0))
    } else {
      setEntries([])
    }
  }, [selectedSources, selectedEngins, listLogs])

  useEffect(() => {
    setSelectedSourceNames(
      sources.filter((o) => selectedSources.includes(o.id)).map((o) => o.label)
    )
  }, [selectedSources, sources])

  useEffect(() => {
    setSelectedEnginNames(
      Array.isArray(engins)
        ? engins?.filter((o) => selectedEngins?.includes(+o.id)).map((o) => o?.reference)
        : []
    )
  }, [selectedEngins, engins])

  useEffect(() => {
    let dt = _.cloneDeep(listLogs)
    console.log('list logs:', dt)
    if (!Array.isArray(dt)) dt = []
    let gateways = dt.filter((o) => o.gateway).map((o) => o.gateway)
    gateways = _.uniq(gateways).map((o) => ({
      id: o,
      type: 'gateway',
      label: o,
    }))

    let users = staffList.map((o) => ({
      id: +o.userID,
      type: 'user',
      label: (o.firstname + ' ' + o.lastname).trim(),
    }))
    setSources([...users, ...gateways])
  }, [staffList, listLogs])

  return (
    <div
      className='flex flex-column w-full lg:flex-row xl:flex-row'
      style={{gap: 3, height: '85vh'}}
    >
      <Dialog
        //  modal={false}
        footer={settingsModalFooter}
        onHide={() => setShowSettings(false)}
        visible={showSettings}
      >
        <div className='mt-2 ' style={{width: '400px', minHeight: '200px'}}>
          <div
            className='flex mb-5 align-items-center justify-content-center text-orange-200'
            style={{gap: 10}}
          >
            <span className='fas fa-info text-xxl'></span>
            <strong className='text-gray-500'>Fonctionnalité en cours de développement</strong>
          </div>
          <div className='flex flex-column' style={{gap: 10}}>
            <div className='flex ' style={{gap: 10}}>
              <span className='w-5 block'>Nombre d'element a afficher</span>
              <InputNumber className='w-12' />
            </div>
            <div className='flex ' style={{gap: 10}}>
              <span className='w-5 block'>Traker uniquement les engins</span>
              <Dropdown showFilterClear filter className='w-12' />
            </div>
            <div className='flex' style={{gap: 10}}>
              <span className='w-5 block'>Desactiver les elements sans engin</span>
              <div className='w-12'>
                <InputSwitch className='' />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
      <Subheader>
        <div className='h-3rem p-1 d-flex justify-content-end align-items-center' style={{gap: 6}}>
          <div>
            <span
              onClick={() => setShowSettings(true)}
              className='pi pi-cog text-xl text-gray-600 cursor-pointer hover:text-blue-300'
            ></span>
          </div>
          <Divider layout='vertical' style={{height: '100%'}} />
          <div>
            <Chip
              title={selectedEnginNames.join(',') || 'Engins'}
              icon={'fas fa-engine-warning'}
              label={
                selectedEngins.includes('')
                  ? 'Tout'
                  : selectedEnginNames.slice(0, 4).join(',') || 'Engins'
              }
              onClick={(e) => toggleOverlay(enginRef, e)}
              className='cursor-pointer hover:bg-blue-100 p-button-text text-sm bg-blue-50'
              style={{height: 'auto'}}
            />
            <OverlayPanel ref={enginRef} className='p-0'>
              <div className='' style={{maxHeight: '300px', overflow: 'auto', minWidth: '150px'}}>
                {[{label: 'Tout', id: '', bgColor: 'orange'}, ...(engins || [])].map((s, i) => (
                  <div
                    key={s.id}
                    onClick={(e) => {
                      toggleSelectEngin(s.id == '' ? s.id : +s.id)
                      // toggleOverlay(usersRef , e)
                    }}
                    className={`p-2 flex gap-2 align-items-center hover:bg-blue-100 border-top-${
                      i != 0 && 1
                    } border-gray-200 cursor-pointer`}
                  >
                    <Checkbox checked={selectedEngins.includes(s.id == '' ? '' : +s.id)} />
                    <div className='flex align-items-center' style={{gap: 5}}>
                      <span> {s.reference} </span>
                    </div>
                  </div>
                ))}
              </div>
            </OverlayPanel>
          </div>
          <div>
            <Chip
              title={selectedSourceNames.join(',') || 'Source'}
              icon={'fas fa-boxes-stacked'}
              label={
                selectedSources.includes('')
                  ? 'Tout'
                  : selectedSourceNames.slice(0, 4).join(',') || 'Source'
              }
              onClick={(e) => toggleOverlay(usersRef, e)}
              className='cursor-pointer hover:bg-blue-100 p-button-text text-sm bg-blue-50'
              style={{height: 'auto'}}
            />
            <OverlayPanel ref={usersRef} className='p-0'>
              <div className='' style={{maxHeight: '300px', overflow: 'auto', minWidth: '150px'}}>
                {[{label: 'Tout', id: '', bgColor: 'orange'}, ...sources].map((s, i) => (
                  <div
                    onClick={(e) => {
                      toggleSelectSource(s.id)
                      // toggleOverlay(usersRef , e)
                    }}
                    className={`p-2 flex gap-2 align-items-center hover:bg-blue-100 border-top-${
                      i != 0 && 1
                    } border-gray-200 cursor-pointer`}
                  >
                    <Checkbox checked={selectedSources.includes(s.id)} />
                    <div className='flex align-items-center' style={{gap: 5}}>
                      <span
                        className={`fa fa-${
                          s.type == 'gateway' ? 'signal-stream' : s.type == 'user' ? 'user' : ''
                        }  text-${s.type == 'user' ? 'gray' : 'blue'}-400`}
                      ></span>
                      <span> {s.label} </span>
                    </div>
                  </div>
                ))}
              </div>
            </OverlayPanel>
          </div>
          <div>
            <Chip
              icon={'pi pi-calendar'}
              label={filter.from ? moment(filter.from).format('DD/MM/YYYY HH:mm') : 'Début'}
              onClick={(e) => toggleOverlay(dateFromRef, e)}
              className='cursor-pointer hover:bg-blue-100 p-button-text text-sm bg-blue-50'
              style={{height: 'auto'}}
            />
            <OverlayPanel ref={dateFromRef} className='p-0'>
              <div>
                <Calendar
                  showButtonBar
                  showTime
                  dateFormat='24'
                  value={filter.from}
                  onChange={(e) => {
                    onInput('from', e.value)
                    // toggleOverlay(dateFromRef , e)
                  }}
                  inline
                />
              </div>
            </OverlayPanel>
          </div>
          <div>
            <Chip
              icon={'pi pi-calendar'}
              label={filter.to ? moment(filter.to).format('DD/MM/YYYY HH:mm') : 'Fin'}
              onClick={(e) => toggleOverlay(dateToRef, e)}
              className='cursor-pointer hover:bg-blue-100 p-button-text text-sm bg-blue-50'
              style={{height: 'auto'}}
            />
            <OverlayPanel ref={dateToRef} className='p-0'>
              <div className='-m-3'>
                <Calendar
                  hourFormat='24'
                  showTime
                  showButtonBar
                  value={filter.to}
                  onChange={(e) => {
                    onInput('to', e.value)
                    // toggleOverlay(dateToRef , e)
                  }}
                  inline
                />
              </div>
            </OverlayPanel>
          </div>
          <Button
            loading={loading}
            disabled={loading}
            icon='pi pi-search'
            outlined
            rounded
            style={{width: '30px', height: '30px'}}
            className='p-1'
            onClick={onSearch}
          />
        </div>
      </Subheader>
      <div className='w-full lg:w-3 xl:w-4'>
        {/* <Divider /> */}
        <div style={{maxHeight: '97%', overflowY: 'auto'}}>
          {entries
            .slice(1, 100)
            .filter(([, val]) => val.length > 0)
            .map(([key, value], index) => (
              <div
                onClick={() => {
                  setSelectedGroup(value)
                  setSelected(key)
                }}
                key={index}
              >
                <CardLogsList
                  key={index}
                  items={value}
                  selected={selected == key}
                  address={value?.[0]?.address || 'Address not found'}
                  date={value?.[0]?.dateFormated || 'Date not found'}
                  lengthScan={_.uniqBy(value, 'macAddr').length || 0}
                  userName={value?.[0]?.user || 'User not found'}
                  image={value?.[0]?.imageImage || 'Image not found'}
                  first={index === 0}
                  last={index === entries?.length - 1}
                  onShowDetail={() => setSelectedGroup(value) /*onShowDetail(value, index)*/}
                  loading={index === indexClick.current && loadingDetail}
                />
              </div>
            ))}
        </div>
      </div>
      <div className='w-full border-left-1 border-gray-200' style={{height: '100%'}}>
        <LogMapDetail
          items={selectedGroup}
          className='relative'
          style={{height: '100%', overflow: 'hidden'}}
        />
      </div>
    </div>
  )
}

export default LogsTrackingList
