import _axios from './axios'
import {request as orequest, psCoreRequest} from '../../../api'

async function request(url, params) {
  return await orequest(url, params, _axios)
}

// start olang

export async function _fetchEnginesRapport(data) {
  return await request('engin/list', {
    method: 'GET',
    data,
  })
}

export async function _buildRapport(data) {
  return await request('engin/rapport', {
    method: 'post',
    data,
  })
}

export async function _fetchStatusRapport(data) {
  return await request('fileGenerator/getStatus', {
    method: 'post',
    data,
  })
}

export async function _getListRpt(data) {
  return await request('engin/rapportGet', {
    method: 'post',
    data,
  })
}

export async function _deleteRapport(data) {
  return await request('engin/rapportDelete', {
    method: 'post',
    data,
  })
}

export async function _fetchListRpt(data) {
  return await request('engin/rapportList', {
    method: 'post',
    data,
  })
}
