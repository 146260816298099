import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {RAPPORT as name} from '../../../store/slices/config'
import {_fetchEngines} from '../../Engin/api/api'
import {_fetchSites} from '../../Site/api/api'
import {
  _buildRapport,
  _deleteRapport,
  _fetchEnginesRapport,
  _fetchListRpt,
  _fetchStatusRapport,
  _getListRpt,
} from '../api'
import moment from 'moment'
import {setToastParams} from '../../../store/slices/ui.slice'

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const fetchListRapport = createAsyncThunk(
  `${name}/fetchListRapport`,
  async (_args, {getState, dispatch}) => {
    console.log('rrrr fetchListRapport', _args)
    dispatch(setListSelected([]))
    if (_args === 'engin') {
      let res = await _fetchEnginesRapport()
      console.log('rrrr objects data: ', res)
      if (!res.error) {
        dispatch(setListRapport(res.data))
        dispatch(setLoadingRpt(false))
      }
    } else {
      let res = await _fetchSites({IDCustomer: 0})
      console.log('rrrr objects data: ', res)
      if (!res.error) {
        dispatch(setListRapport(res.data))
        dispatch(setLoadingRpt(false))
      }
    }
  }
)

export const fetchListRpt = createAsyncThunk(
  `${name}/fetchListRpt`,
  async (_args, {getState, dispatch}) => {
    let res = await _fetchListRpt()
    console.log('rrrr _fetchListRpt: ', res)
    if (!res.error) {
      dispatch(setListRpt(res.data))
    }
  }
)

export const fetchListRptById = createAsyncThunk(
  `${name}/fetchListRptById`,
  async (_args, {getState, dispatch}) => {
    console.log('rrrr fetchListRptById', _args)
    let res = await _getListRpt({id: _args})
    console.log('rrrr objects id: ', res)
    if (!res.error) {
      console.log('res data', res)
      dispatch(setDataRapport(res || []))
      return true
    }
  }
)

export const deleteRapport = createAsyncThunk(
  `${name}/deleteRapport`,
  async (_args, {getState, dispatch}) => {
    console.log('rrrr deleteRapport', _args)
    let res = await _deleteRapport({id: _args})
    console.log('rrrr deleteRapport: ', res)
    if (!res.error) {
      dispatch(fetchListRpt())
      return true
    }
  }
)

export const saveRapport = createAsyncThunk(
  `${name}/saveRapport`,
  async (_args, {getState, dispatch}) => {
    const {selectedRapport, listSelected} = getState()[name]
    let begDate = moment(_args.date[0]).format('YYYY-MM-DD')
    let endDate = moment(_args.date[1]).format('YYYY-MM-DD')

    console.log('_args listSelected', listSelected)
    let obj = {
      ref: listSelected,
      object: selectedRapport.decs,
      begDate: begDate,
      endDate: endDate,
      title: _args?.title,
    }

    console.log('rrrr obj', obj)
    const res = await _buildRapport(obj)
    console.log('rrrr saveRapport', res)

    dispatch(
      setToastParams({
        show: true,
        severity: 'success',
        summary: 'SUCCESS',
        detail: 'Rapport bien enregistré',
        position: 'top-right',
      })
    )

    if (!res.error) {
      dispatch(setLoadingRapport(true))
      dispatch(setChoseRapport(false))
      dispatch(setShowSettingRapport(false))
      dispatch(setDataRapport(res?.res || []))

      let loading = true
      let rapportSat = null

      while (loading) {
        rapportSat = await _fetchStatusRapport({id: res?.generatorId})
        if (rapportSat?.data?.[0]?.status === 1) {
          loading = false
          dispatch(setLoadingRapport(false))
          window.open(res?.filepath, '_blank')
        } else {
          await delay(3000) // 3-second delay between requests
        }
      }
    }
  }
)

const initialState = {
  choseRapport: false,
  showSettingRapport: false,
  listRapport: [],
  listSelected: [],
  dataRapport: [],
  listRpt: [],
  loadingRapport: false,
  loading: false,
  selectedRapport: {
    title: '',
    icon: '',
    decs: '',
  },
}

const rapportsSlice = createSlice({
  name,
  initialState,
  reducers: {
    setChoseRapport: (state, {payload}) => {
      state.choseRapport = payload
    },
    setListSelected: (state, {payload}) => {
      state.listSelected = payload
    },
    setShowSettingRapport: (state, {payload}) => {
      state.showSettingRapport = payload
    },
    setListRpt(state, {payload}) {
      state.listRpt = payload
    },
    setSelectedRapport: (state, {payload}) => {
      state.selectedRapport = {
        ...state.selectedRapport,
        ...payload,
      }
    },
    setListRapport: (state, {payload}) => {
      state.listRapport = payload
    },
    setDataRapport: (state, {payload}) => {
      state.dataRapport = payload
    },
    setLoadingRpt: (state, {payload}) => {
      state.loading = payload
    },
    setLoadingRapport: (state, {payload}) => {
      state.loadingRapport = payload
    },
  },
})

export const getChoseRapport = (state) => state[name].choseRapport
export const getShowSettingRapport = (state) => state[name].showSettingRapport
export const getSelectedRapport = (state) => state[name].selectedRapport
export const getListRapport = (state) => state[name].listRapport
export const getLoadingRpt = (state) => state[name].loading
export const getListSelected = (state) => state[name].listSelected
export const getDataRapport = (state) => state[name].dataRapport
export const getLoadingRapport = (state) => state[name].loadingRapport
export const getListRpt = (state) => state[name].listRpt

export const {
  setChoseRapport,
  setShowSettingRapport,
  setSelectedRapport,
  setListRapport,
  setLoadingRapport,
  setDataRapport,
  setLoadingRpt,
  setListSelected,
  setListRpt,
} = rapportsSlice.actions
export default rapportsSlice.reducer
