import {createSlice, createAsyncThunk, createAction} from '@reduxjs/toolkit'
import {COMPANYDEPOSITE as name} from '../../../store/slices/config'
import _ from 'lodash'

export const createOrUpdateCompanyDeposite = createAsyncThunk(
  `${name}/createOrUpdateCompanyDeposite`,
  async (_args, {dispatch, getState}) => {
    try {
      console.log('xx args:', _args, getState()[name])
      let {selectedCompanyDeposite} = getState()[name]
      let data = _.cloneDeep(selectedCompanyDeposite)

      let obj = {}
      console.log('xx company deposit data:', obj)

      // let res = null
      // res = await _saveFamille(obj)
      // console.log('xx res:', res)
      // if (Array.isArray(res.data) && (res.data || [])[0]?.typeMsg === 'success' || (res.data || [])[0]?.typeMsg === 'success') {
      //   console.log('ok', selectedObject)
      //   dispatch(fetchFamilles(selectedObject?.name))
      //   return true
      // } else if (Array.isArray(res.data) && (res.data || [])[0]?.msg === 'already exists') {
      //   console.log('Existe déjà')
      //   dispatch(setExistItem(true))
      // }
      // return false
    } catch (error) {
      console.log('error:', error.message)
      return false
    }
  }
)

export const setselectedCompanyDeposite = createAction(`${name}/setselectedCompanyDeposite`)
export const setExistItem = createAction(`${name}/setExistItem`)
const companyDepositSlice = createSlice({
  name,
  initialState: {
    selectedCompanyDeposite: null,
    existItem: false, 
  },
  reducers: {},
  extraReducers: {
    [setselectedCompanyDeposite]: (state, {payload}) => {
      state.selectedCompanyDeposite = payload
    },
    [setExistItem]: (state, {payload}) => {
      state.existItem = payload
    },
  },
})

export const getSelectedCompanyDeposite = (state) => state[name].selectedCompanyDeposite
export const getExistItem = (state) => state[name].existItem

export default companyDepositSlice.reducer
