import {SplitButton} from 'primereact/splitbutton'
import {DatatableComponent} from '../../../components/shared/DatatableComponent/DataTableComponent'
import {
  fetchEnginListHistory,
  fetchEngines,
  fetchStatusList,
  fetchTypesList,
  getEngines,
  getLastEnginsUpdates,
  getSelectedEngine,
  getTypeList,
  removeEngine,
  saveTagAddress,
  setEditEngine,
  setParamCadHis,
  setSelectedEngine,
  setSelectedHistory,
  setShow,
  setShowHistory,
  setStatusVisible,
  setTypeEdit,
  setTypeFields,
  setUpdatedEngin,
} from '../slice/engin.slice'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import {Chip} from 'primereact/chip'
import {Image} from 'primereact/image'
import {useEffect, useState} from 'react'
import {OlangItem} from '../../shared/Olang/user-interface/OlangItem/OlangItem'
import ButtonComponent from '../../shared/ButtonComponent/ButtonComponent'
import {removeGeofencing} from '../../../store/slices/geofencing.slice'
import TypesEditor from '../EnginEditor/TypesEditor'
import {setAlertParams} from '../../../store/slices/alert.slice'
import EnginMapLocation from './EnginMapLocation'
import GeocodingComponent from '../../shared/GeocodingComponent/GeocodingComponent'
import DynamicInputs from '../EnginEditor/DynamicInputs'
import {API_BASE_URL_IMAGE} from '../../../api/config'
import {Avatar} from 'primereact/avatar'
import {FamilleTagTemplate} from '../../Tag/user-interface/TagList/FamilleTagTemplate'
import {Modal} from 'react-bootstrap'
import {Button} from 'primereact/button'
import {DialogComponent} from '../../shared/DialogComponent/DialogComponent'
import {InputText} from 'primereact/inputtext'
import {useFormik} from 'formik'
import {fetchValidator} from '../../Inventory/slice/inventory.slice'
import {setSelectedTeam} from '../../Teams/slice/team.slice'
import moment from 'moment'
import Loader from '../../shared/Loader/Loader'
import {fetchTagsWithEngin} from '../../Tag/slice/tag.slice'
const test = [
  {
    LocationActif: 8,
    LocationID: 30188,
    LocationObject: 'worksite',
    LocationObjectname: 'JORF OCP',
    NewStatus: 8,
    OldStatus: 7,
    etatIconName: 'fa-solid fa-down-to-bracket',
    etatbgColor: '#D64B70',
    etatengin: 'Entrée',
    etatenginname: 'reception',
    iconReact: 'faUpFromBracket',
    id: 40234,
    last_lat: 33.6928,
    last_lng: -7.37641,
    locationDate: '20/06/2024 14:01',
    statusDate: '20/06/2024 14:01',
    uid: 40234,
  },
]
const EnginList = () => {
  const [visible, setVisible] = useState(false)
  const [dialogVisible, setDialogVisible] = useState(false)
  const [savePosVisible, setSavePosVisible] = useState(false)
  const [isLoadingButton, setIsLoadingButton] = useState(false)
  const [showTag, setShowTag] = useState([])
  const [loading, setLoading] = useState(false)
  const [mouvement, setMouvement] = useState('')

  let engines = useAppSelector(getEngines)
  let enginsLastUpdates = useAppSelector(getLastEnginsUpdates)
  const dispatch = useAppDispatch()

  let actions = [
    {
      label: 'Supprimer',
      icon: 'pi pi-trash text-red-500',
      command: (e) => {
        try {
          dispatch(setSelectedEngine(e.item.data))
          dispatch(
            setAlertParams({
              title: 'Supprimer',
              message: 'Voulez-vous vraiment supprimerce engin?',
              acceptClassName: 'p-button-danger',
              visible: true,
              accept: () => {
                dispatch(removeEngine(e.item.data))
              },
            })
          )
        } catch (error) {
          console.log('error', error)
        }
      },
    },
    {
      label: 'Detail',
      icon: 'pi pi-eye text-blue-500',
      command: (e) => {
        try {
          dispatch(fetchValidator('engin'))
          dispatch(setSelectedEngine(e.item.data))
          dispatch(setShow(false))
          dispatch(fetchTagsWithEngin(e.item.data.id))
        } catch (error) {
          console.log('error', error)
        }
      },
    },
  ]

  const splitAction = (item) => {
    try {
      dispatch(fetchValidator('engin'))
      dispatch(setSelectedEngine(item))
      dispatch(setShow(false))
    } catch (error) {
      console.log('error', error)
    }
  }
  const getPosOfAddress = (data) => {
    try {
      let obj = {
        srcId: data?.id,
        srcObject: 'Engin',
        srcMouvement: 'pos',
      }
      dispatch(setParamCadHis({title: 'Positions', showList: true}))
      console.log('obj in getPosOfAddress', obj)
      dispatch(fetchEnginListHistory(obj)).then(() => {
        dispatch(setShowHistory(true))
        dispatch(setSelectedEngine(data))
        setDialogVisible(true)
      })
      // dispatch(setSelectedEngine(data))
      // dispatch(setShowHistory(true))
      // setMouvement('pos')
      // setDialogVisible(true)
    } catch (error) {
      console.log('error', error)
    }
  }

  const imageTemplate = (rowData) => (
    <>
      <Image
        src={`${API_BASE_URL_IMAGE}${rowData?.image}`}
        alt='EngineImage'
        width='60'
        height='60'
        preview
        imageStyle={{objectFit: 'cover', borderRadius: '10px'}}
      />
    </>
  )

  const addresseeTemplate = (rowData) => {
    return (
      <>
        {
          <div>
            {rowData?.enginAddress ? (
              <Chip
                label={rowData?.enginAddress}
                className='w-11rem m-1 flex justify-content-center align-items-center cursor-pointer'
                onClick={() => getPosOfAddress(rowData)}
              />
            ) : (
              'No address found.'
            )}
          </div>
        }
      </>
    )
  }

  const handleClickType = (rowData) => {
    try {
      dispatch(setSelectedEngine(rowData))
      setVisible(true)
      //dispatch(setTypeEdit(true))
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleType = (e) => {
    try {
      setVisible(true)
      dispatch(setSelectedEngine(e))
    } catch (error) {
      console.log('error', error)
    }
  }

  const typeTemplate = (rowData) => {
    let typesArray
    try {
      // if(rowData.type)
      typesArray = JSON.parse(rowData.types)
    } catch (error) {
      console.error('Error parsing JSON data:', error)

      typesArray = []
    }
    return (
      <>
        {rowData.types === '' ? (
          <ButtonComponent
            label={<OlangItem olang='ADD.Type' />}
            onClick={() => handleClickType(rowData)}
          />
        ) : (
          <div className='flex'>
            {typesArray?.slice(0, 2).map((o, index) => {
              return (
                <div>
                  <Chip key={index} label={`${o?.type}`} className='ml-2' />
                </div>
              )
            })}
            {typesArray?.length >= 3 ? <Chip label='...' className='ml-2' /> : null}
            <i
              className='ml-2 pi pi-window-maximize cursor-pointer hover:text-700'
              onClick={() => handleType(rowData)}
            ></i>
          </div>
        )}
      </>
    )
  }

  const statusTemplate = (rowData) => {
    if (rowData?.iconName) {
      return (
        <i
          title={rowData?.statuslabel}
          className={`${rowData?.iconName} text-2xl rounded p-2 cursor-pointer`}
          style={{color: `${rowData.statusbgColor}`}}
          onClick={() => dispatch(setStatusVisible(true))}
        ></i>
      )
    }
    return (
      <Chip
        label={rowData?.statuslabel}
        style={
          {
            // background: `${rowData.statusbgColor}`,
            // color: rowData.color ?? '',
          }
        }
        title={`${rowData?.statusDate}`}
      />
    )
  }

  //etatengin
  const iconTemplate = (rowData) => {
    let icon = ''
    let color = ''
    if (rowData?.etatenginname == 'exit') {
      icon = 'fa-solid fa-up-from-bracket'
      color = '#D64B70'
    } else if (rowData?.etatenginname == 'reception') {
      icon = 'fa-solid fa-down-to-bracket'
      color = 'green'
    } else if (rowData?.etatenginname == 'nonactive') {
      icon = 'fa-solid fa-octagon-exclamation'
      color = 'red'
    }
    return (
      <div>
        <i
          style={{color}}
          className={`${icon} text-2xl rounded p-2 cursor-pointer`}
          title={`${rowData?.etatengin} ${rowData?.locationDate ?? 'No Date'}`}
          alt={`${rowData?.etatengin} ${rowData?.locationDate ?? 'No Date'}`}
          onClick={() => handleShowMap(rowData, null)}
        ></i>
      </div>
    )
  }

  const handleShowMap = (rowData, srcMouv = '') => {
    console.log('handleShowMap', srcMouv)
    try {
      let obj = {
        srcId: rowData?.uid,
        srcObject: 'Engin',
      }
      dispatch(setParamCadHis({title: 'Enter_Exit', showList: true}))
      dispatch(fetchEnginListHistory(obj)).then(() => {
        dispatch(setShowHistory(true))
        dispatch(setSelectedEngine(rowData))
        setDialogVisible(true)
      })
    } catch (error) {
      console.log('error', error)
    }
  }

  const familleTagTemplate = (rowData) => {
    return (
      <Chip
        label={rowData.familleTag}
        title={rowData.tagId != 0 ? `Tagged  ${rowData?.tagDate}` : 'No Tag'}
        alt={rowData.tagId != 0 ? `Tagged  ${rowData?.tagDate}` : 'No Tag'}
        icon={rowData.familleIconTag}
        style={{background: rowData.familleTagIconBgcolor, color: rowData.familleTagIconColor}}
        className='cursor-pointer'
        onClick={() => showTagById(rowData)}
      />
    )
  }

  const showTagById = (rowData) => {
    console.log('showTagById', rowData)
    if (showTag?.includes(rowData?.uid)) {
      let update = showTag?.filter((x) => x !== rowData?.uid)
      setShowTag(update)
      return
    }
    setShowTag([...showTag, rowData?.uid])
  }

  const tagTemplate = (rowData) => {
    return (
      <div className='flex flex-column'>
        <div className='flex justify-content-center'>
          {rowData.tagId ? (
            familleTagTemplate(rowData)
          ) : (
            <Chip label='Untagged' className='cursor-pointer' />
          )}
        </div>
        {showTag.includes(rowData?.uid) ? (
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Chip
              label={
                rowData?.labeltag === null ||
                rowData?.labeltag === '' ||
                rowData?.labeltag == undefined
                  ? rowData?.tagname
                  : rowData?.labeltag
              }
              className='m-2'
              style={{background: rowData?.familleTagIconBgcolor || '#D64B70', color: 'white'}}
            />
          </div>
        ) : null}
      </div>
    )
  }

  const BatteryStatus = ({batteries, locationDate}) => {
    let batteryIcon
    let textColor
    let alt
    if (batteries === '' || batteries === null || batteries === undefined) {
      batteryIcon = 'fas fa-battery-empty'
      textColor = 'text-700'
      alt = 'No data'
    } else {
      const batteryValue = parseInt(batteries, 10)
      alt = locationDate ?? 'No date'
      if (batteryValue >= 80) {
        batteryIcon = 'fas fa-battery-full'
        textColor = 'text-success'
      } else if (batteryValue >= 50) {
        batteryIcon = 'fas fa-battery-three-quarters'
        textColor = 'text-success'
      } else if (batteryValue >= 20) {
        batteryIcon = 'fas fa-battery-half'
        textColor = 'text-warning'
      } else if (batteryValue > 0) {
        batteryIcon = 'fas fa-battery-quarter'
        textColor = 'text-danger'
      } else {
        batteryIcon = 'fas fa-battery-empty'
        textColor = 'text-danger'
      }
    }

    return (
      <div className='flex items-center justify-center'>
        <div className='p-4 rounded-lg text-center'>
          <i title={alt} alt={alt} className={`text-4xl ${batteryIcon} ${textColor}`}></i>
          <span className={`block mt-2 font-bold text-lg ${textColor}`}>
            {batteries > 100 ? '100%' : batteries + '%'}
          </span>
        </div>
      </div>
    )
  }

  const tagIdTemplate = ({tagId}) => {
    return tagId == null || tagId === '' || tagId === undefined || tagId === 0 ? 'No Tag' : tagId
  }

  const familleTemplate = ({famille, familleIcon, familleBgcolor, familleColor}) => {
    return (
      <Chip
        label={famille}
        icon={familleIcon}
        style={{background: familleBgcolor, color: 'white'}}
      />
    )
  }

  const columns = [
    {
      header: 'Photo',
      field: 'image',
      olang: 'Photo',
      body: imageTemplate,
    },
    {
      header: 'Référence',
      field: 'reference',
      olang: 'Reference',
    },
    {
      header: 'TagId',
      field: 'tagId',
      olang: 'tagId',
      body: tagIdTemplate,
    },
    {
      header: 'Label',
      field: 'label',
      olang: 'label',
    },
    {
      header: 'Vin',
      field: 'vin',
      olang: 'vin',
    },
    {
      header: 'Etat',
      field: 'etatenginname',
      olang: 'Etat',
      body: iconTemplate,
    },
    {
      header: 'Tag',
      field: 'tagname',
      olang: 'Tag',
      body: tagTemplate,
    },
    {
      header: 'Status',
      olang: 'status',
      field: 'statuslabel',
      body: statusTemplate,
    },
    {
      header: 'Dernière vue',
      olang: 'lastSeen',
      field: 'lastSeenAt',
      body: (data) => (data.lastSeenAt ? moment(data.lastSeenAt).format('DD/MM/YYYY HH:mm') : '_'),
    },
    {
      header: 'Battery status',
      olang: 'BatteryStatus',
      field: 'batteries',
      body: BatteryStatus,
    },
    {
      header: 'Famille',
      field: 'famille',
      olang: 'Famille',
      visible: true,
      body: familleTemplate,
    },

    {
      header: 'Marque',
      field: 'brand',
      olang: 'marque',
    },
    {
      header: 'IMMATRICULATION',
      field: 'immatriculation',
      olang: 'IMMATRICULATION',
    },
    {
      header: 'Matricule',
      field: 'model',
      olang: 'Matricule',
    },
    {
      header: 'Type',
      field: 'types',
      olang: 'Type',
      body: typeTemplate,
    },

    {
      header: 'Worksite',
      field: 'LocationObjectname',
      olang: 'Worksite',
    },

    {
      header: 'Addressee',
      olang: 'Addressee',
      field: 'latlng',
      body: addresseeTemplate,
    },
  ]

  const exportFields = [
    {
      label: 'Référence',
      column: 'reference',
    },
    {
      label: 'Marque',
      column: 'brand',
    },
    {
      label: 'TagId',
      column: 'tagId',
    },
    {
      label: 'Label',
      column: 'label',
    },
    {
      label: 'Vin',
      column: 'vin',
    },
    {
      label: 'Etat',
      column: 'etatenginname',
    },
    {
      label: 'Tag',
      column: 'tagname',
    },
    {
      label: 'Status',
      column: 'statuslabel',
    },
    {
      label: 'Battery status',
      column: 'batteries',
    },
    {
      label: 'Famille',
      column: 'famille',
    },
    {
      label: 'IMMATRICULATION',
      column: 'immatriculation',
    },
    {
      label: 'Matricule',
      column: 'model',
    },
    {
      label: 'Worksite',
      column: 'LocationObjectname',
    },
  ]

  const rowGroupTemplates = {
    reference: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.reference} />
    ),
    tagId: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.tagId} />
    ),
    field: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.field} />
    ),
    label: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.label} />
    ),
    vin: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.vin} />
    ),
    etatenginname: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.etatenginname} />
    ),
    tagname: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.tagname} />
    ),
    statuslabel: (rowData) => (
      <Chip
        style={{backgroundColor: rowData.statusbgColor, color: 'white'}}
        label={rowData?.statuslabel}
      />
    ),
    batteries: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.batteries} />
    ),
    famille: (rowData) => familleTemplate(rowData),
    brand: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.brand} />
    ),
    immatriculation: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.immatriculation} />
    ),
    model: (rowData) => (
      <Chip style={{backgroundColor: '#D64B70', color: 'white'}} label={rowData?.model} />
    ),
    LocationObjectname: (rowData) => (
      <Chip
        style={{backgroundColor: '#D64B70', color: 'white'}}
        label={rowData?.LocationObjectname}
      />
    ),
    Addresse: (rowData) => (
      <Chip
        style={{backgroundColor: '#D64B70', color: 'white'}}
        label={addresseeTemplate(rowData)}
      />
    ),
  }

  const allowedGroupFields = [
    'famille',
    'statuslabel',
    'LocationObjectname',
    'etatenginname',
    'tagname',
    'batteries',
  ]

  const formik = useFormik({
    initialValues: {
      locationId: '',
      latitude: '',
      longitude: '',
      macAddress: '',
    },
    onSubmit: (data) => {
      dispatch(saveTagAddress(data))
      console.log('yyy dataSave', data)
    },
  })

  const savePosFooter = (
    <div>
      <Button
        label='Save'
        icon='pi pi-check'
        onClick={() => {
          formik.handleSubmit()
        }}
      />
    </div>
  )

  const hideShowDialog = () => {
    dispatch(setSelectedHistory(null))
    setMouvement(null)
    setDialogVisible((prev) => !prev)
  }
  let create = () => {
    setIsLoadingButton(true)
    dispatch(fetchValidator('engin'))
      .then(() => {
        dispatch(setEditEngine(true))
        dispatch(setSelectedEngine(null))
        dispatch(setTypeFields([]))
      })
      .finally(() => setIsLoadingButton(false))
  }

  useEffect(() => {
    setLoading(true)
    dispatch(fetchEngines({})).then(() => setLoading(false))
    dispatch(fetchTypesList())
    dispatch(fetchStatusList())
    // dispatch(removeGeofencing())
  }, [])

  const onHide = () => {
    setVisible(false)
    setSelectedEngine(null)
  }

  return (
    <>
      <DialogComponent
        visible={savePosVisible}
        header={'Save Pos'}
        onHide={() => setSavePosVisible(false)}
        className='w-11 md:w-6'
        footer={savePosFooter}
      >
        <InputText
          placeholder='Enter LocationId'
          id='locationId'
          name='locationId'
          value={formik.values.locationId}
          onChange={(e) => {
            formik.setFieldValue('locationId', e.target.value)
          }}
        />
        <InputText
          placeholder='Enter latitude'
          id='latitude'
          name='latitude'
          value={formik.values.latitude}
          onChange={(e) => {
            formik.setFieldValue('latitude', e.target.value)
          }}
        />
        <InputText
          placeholder='Enter longitude'
          id='longitude'
          name='longitude'
          value={formik.values.longitude}
          onChange={(e) => {
            formik.setFieldValue('longitude', e.target.value)
          }}
        />
        <InputText
          placeholder='Enter MacAddress'
          id='macAddress'
          name='macAddress'
          value={formik.values.macAddress}
          onChange={(e) => {
            formik.setFieldValue('macAddress', e.target.value)
          }}
        />
      </DialogComponent>
      <DynamicInputs visible={visible} setVisible={setVisible} onHide={onHide} />
      {/* <TypesEditor visible={visible} setVisible={setVisible} /> */}
      <div
        // style={{backgroundColor: '#53408C'}}
        className='py-3 flex flex-row align-items-center'
      >
        <h1 className='text-700'>
          <OlangItem olang={'engin.list'} />
        </h1>
        {/* <Button className='ml-2' onClick={() => dispatch(setUpdatedEngin(test))}>
          Save Pos
        </Button> */}
      </div>
      <EnginMapLocation
        dialogVisible={dialogVisible}
        setDialogVisible={hideShowDialog}
        // historySrc={{
        //   srcId: selectedEngin?.id,
        //   srcObject: 'engin',
        //   srcMovement: mouvement,
        // }}
      />
      {loading ? (
        <Loader />
      ) : (
        <DatatableComponent
          tableId='engin-table'
          data={engines}
          splitAction={splitAction}
          columns={columns}
          onNew={create}
          exportFields={exportFields}
          rowGroupTemplates={rowGroupTemplates}
          allowedGroupFields={allowedGroupFields}
          onSelections={(r) => console.log('data:', r)}
          rowActions={actions}
          sortField={'id'}
          sortOrder={-1}
          isLoading={isLoadingButton}
        />
      )}
    </>
  )
}

export default EnginList
